import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { useToast } from "@/hooks/use-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import WallElementEditor from './WallElementEditor';
import { convertToInches, formatValue } from '@/utils/utils';
import { drawRoom, getWallCoordinates } from '@/utils/roomDrawingFunctions';
import {MousePointerClick} from 'lucide-react'

const defaultFloorPlan = {
  roomType: 'u-shaped',
  unit: 'in',
  walls: {
    left: 110,
    back: 122,
    right: 110,
    front: 122,
    openFront: 32,
    lShortSide: 20,
    lLongSide: 140,
    lFrontR: 100,
    lFrontL: 22,
    lRight: 80,
  },
  roomHeight: 96,
};

const MIN_CANVAS_WIDTH = 320; // Minimum width in pixels, adjust as needed

const ManualFloorPlanEditor = ({ onSave, initialFloorPlan }) => {
  const [roomType, setRoomType] = useState(initialFloorPlan?.roomType || defaultFloorPlan.roomType);
  const [unit, setUnit] = useState(initialFloorPlan?.unit || defaultFloorPlan.unit);
  const [walls, setWalls] = useState(initialFloorPlan?.walls || defaultFloorPlan.walls);
  const [roomHeight, setRoomHeight] = useState(initialFloorPlan?.roomHeight || defaultFloorPlan.roomHeight);
  const [inputValues, setInputValues] = useState({});
  const [wallElements, setWallElements] = useState(initialFloorPlan?.wallElements || {});
  const [isWallDialogOpen, setIsWallDialogOpen] = useState(false);
  const [activeWall, setActiveWall] = useState(null);
  const [wallCoordinates, setWallCoordinates] = useState(null)
  const canvasRef = useRef(null);
  const { toast } = useToast();
  const [ startX, setStartX] = useState(0);
  const [ startY, setStartY] = useState(0);
  const [scale, setScale] = useState(1);
  const [FPWidth, setFPWidth] = useState(122)
  const [FPDepth, setFPDepth] = useState(110)

  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 400, height: 300 });

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = window.innerHeight * 0.6; // 60% of viewport height
        const aspectRatio = 4 / 3; // Maintain a 4:3 aspect ratio

        // console.log("containerHeight", containerHeight, "containerWidth", containerWidth)
        let newWidth, newHeight;

        newWidth = Math.max(MIN_CANVAS_WIDTH, Math.min(containerWidth, 800));
        newHeight = newWidth / aspectRatio;

        // If the height exceeds the container, adjust both dimensions
        if (newHeight > containerHeight) {
          newHeight = Math.min(containerHeight, 600);
          newWidth = newHeight * aspectRatio;
        }

        setCanvasSize({ width: newWidth, height: newHeight });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    drawRoomPlan();
  }, [walls, roomType, unit, wallElements, roomHeight, canvasSize]);

  useEffect(() => {
    const newInputValues = {
      roomHeight: formatValue(roomHeight, unit),
    };
    Object.entries(walls).forEach(([wall, value]) => {
      newInputValues[wall] = formatValue(value, unit);
    });
    setInputValues(newInputValues);
  }, [unit, walls, roomType, roomHeight]);

  const handleRoomTypeChange = (newRoomType) => {
    setRoomType(newRoomType);    
  };

  function safeMax(...values) {
    const validNumbers = values.filter(v => typeof v === 'number' && !isNaN(v));
    if (validNumbers.length === 0) return 0; // or null, or throw an error
    return Math.max(...validNumbers);
  }

  const handleRoomHeightChange = (value) => {
    setInputValues(prev => ({ ...prev, roomHeight: value }));
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      setRoomHeight(convertToInches(numValue, unit));
    }
  };

  const handleWallChange = (wall, value) => {
    setInputValues(prev => ({ ...prev, [wall]: value }));
    const numValue = parseFloat(value);
    const MIN_WALL_LENGTH = 10; // Minimum wall length in inches

    if (convertToInches(numValue, unit) < MIN_WALL_LENGTH) {
      toast({
        title: "Invalid wall length",
        description: `Wall length must be at least ${formatValue(MIN_WALL_LENGTH, unit)} ${unit}.`,
        variant: "destructive",
      });
      return;
    }
    if (!isNaN(numValue)) {
      setWalls(prevWalls => {
        const newWalls = { ...prevWalls, [wall]: convertToInches(numValue, unit) };
        
        // Logic for rectangular room
        if (roomType === 'rectangular') {
          if (wall === 'back' || wall === 'front') {
            newWalls.back = newWalls.front = newWalls[wall];
          } else if (wall === 'left' || wall === 'right') {
            newWalls.left = newWalls.right = newWalls[wall];
          }
        }
        
        // Logic for L-shaped room
        else if (roomType === 'l-shaped') {
          if (wall === 'back') {
            // Ensure lFrontR + lFrontL = back
            const difference = newWalls.back - (newWalls.lFrontR + newWalls.lFrontL);
            newWalls.lFrontR += difference / 2;
            newWalls.lFrontL += difference / 2;
          } else if (wall === 'lFrontR' || wall === 'lFrontL') {
            // Adjust the other front wall to maintain total back wall length
            const otherWall = wall === 'lFrontR' ? 'lFrontL' : 'lFrontR';
            newWalls[otherWall] = newWalls.back - newWalls[wall];
          } else if (wall === 'left') {
            // Ensure left = lRight + lShortSide
            const difference = newWalls.left - (newWalls.lRight + newWalls.lShortSide);
            newWalls.lRight += difference / 2;
            newWalls.lShortSide += difference / 2;
          } else if (wall === 'lRight' || wall === 'lShortSide') {
            // Adjust the other side wall to maintain total left wall length
            const otherWall = wall === 'lRight' ? 'lShortSide' : 'lRight';
            newWalls[otherWall] = newWalls.left - newWalls[wall];
          }
        }
        
        return newWalls;
      });
    }
  };

  const calculateRequiredWidth = () => {
    return walls.back ; // 80 is padding
  };

  const calculateRequiredHeight = () => {
    return safeMax(walls.left, walls.right); // 80 is padding
  };

  const calculateScale = () => {
    const requiredWidth = calculateRequiredWidth();
    const requiredHeight = calculateRequiredHeight();
    const maxDimension = Math.max(requiredWidth, requiredHeight);
    return Math.min((canvasSize.width - 80) / maxDimension, (canvasSize.height - 80) / maxDimension);
  };

  const drawRoomPlan = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    const scale = calculateScale();
    setScale(scale);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;

    const requiredWidth = calculateRequiredWidth();
    const requiredHeight = calculateRequiredHeight();
    const startX = (canvas.width - requiredWidth * scale) / 2 ;
    const startY = (canvas.height - requiredHeight * scale) / 2;
    setStartX(startX);
    setStartY(startY);

    // Draw grid
    ctx.strokeStyle = '#E5E5E5'; // Light gray color
    ctx.lineWidth = 0.5;
    
    // Calculate grid size (1 inch in scaled pixels)
    const gridSize = 12 * scale;
    
    // Calculate grid offset based on startX and startY
    const offsetX = (startX * scale) % gridSize;
    const offsetY = (startY * scale) % gridSize;
    
    // Draw vertical lines
    for (let x = offsetX; x < canvas.width; x += gridSize) {
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.stroke();
    }
    
    // Draw horizontal lines
    for (let y = offsetY; y < canvas.height; y += gridSize) {
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(canvas.width, y);
      ctx.stroke();
    }

    // Reset line width and style for room drawing
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';

    const wallCoordinates = getWallCoordinates(walls, roomType);
    setWallCoordinates(wallCoordinates);

    // Wait for room drawing to complete
    await drawRoom(ctx, walls, wallCoordinates, wallElements, startX, startY, scale, unit);
  };

  const handleCanvasClick = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    // const scaleX = canvasSize.width / rect.width;
    // const scaleY = canvasSize.height / rect.height;
    // const x = (event.clientX - rect.left) * scaleX/ scale;
    // const y = (event.clientY - rect.top) * scaleY/ scale;
    const x = (event.clientX - rect.left) 
    const y = (event.clientY - rect.top)
  
    const clickedWall = findClickedWall(x, y);
    if (clickedWall) {
      setActiveWall(clickedWall);
      setIsWallDialogOpen(true);
    }
  };

  const findClickedWall = (x, y) => {
    const wallCoordinates = getWallCoordinates(walls, roomType);
    setWallCoordinates(wallCoordinates);
  
    for (const [wall, coords] of Object.entries(wallCoordinates)) {
      if (isPointNearLine(x, y, (coords.x1 * scale + startX), (coords.y1 * scale+ startY), (coords.x2 * scale + startX) , (coords.y2 * scale + startY))) {
        return wall;
      }
    }
    return null;
  };

  const isPointNearLine = (px, py, x1, y1, x2, y2, threshold = 10) => {
    const A = px - x1;
    const B = py - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = (len_sq !== 0) ? dot / len_sq : -1;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    const dx = px - xx;
    const dy = py - yy;

    return Math.sqrt(dx * dx + dy * dy) <= threshold;
  };

  const handleWallElementsChange = (newElements) => {
    setWallElements(prev => {
      const updated = { ...prev, [activeWall]: newElements };
      setTimeout(() => drawRoomPlan(), 0);
      return updated;
    });
  };

  useEffect(() => {
    const calculateMaxWidth = (coordinates) => {
      if (!coordinates) return 0;
      
      let maxX = 0;
      let minX = 0;
      try {
        Object.values(coordinates).forEach(wall => {
          maxX = Math.max(maxX, wall.x1, wall.x2); 
          minX = Math.min(minX, wall.x1, wall.x2);
        });
      } catch (error) {
        console.error('Error calculating max width:', error);
      }
      return maxX - minX;
    };
  
    const calculateMaxDepth = (coordinates) => {
      if (!coordinates) return 0;
      
      let maxY = 0;
      let minY = 0;
      try {
        Object.values(coordinates).forEach(wall => {
          maxY = Math.max(maxY, wall.y1, wall.y2);
          minY = Math.min(minY, wall.y1, wall.y2);
        });
      } catch (error) {
        console.error('Error calculating max depth:', error);
      }
      return maxY - minY;
    };
    setFPWidth(calculateMaxWidth(wallCoordinates));
    setFPDepth(calculateMaxDepth(wallCoordinates));
    const newFloorPlanData = {
      roomType,
      unit,
      walls,
      dimensions: {
        width: calculateMaxWidth(wallCoordinates),
        depth: calculateMaxDepth(wallCoordinates),
        height: roomHeight,
      },
      wallElements,
      roomHeight,
      wallCoordinates
    };

    onSave(newFloorPlanData);
  }, [roomType, unit, walls, wallElements, roomHeight, wallCoordinates]);

  const formatWallName = (wall) => {
    switch (wall) {
      case 'lShortSide': return 'Short wall';
      case 'lRight': return 'Right wall';
      case 'lFrontR': return 'Front (R) wall';
      case 'lFrontL': return 'Front (L) wall';
      case 'openFront': return 'Front wall';
      default: return wall;
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto" ref={containerRef}>
      {Object.keys(wallElements).length === 0 && (
          <div className=" text-center mb-4 p-2 bg-green-100 rounded-md"
            style={{ width: `${canvasSize.width}px`, maxWidth: '100%' }}>
            <p className="text-blue-800 font-semibold"> <MousePointerClick /> </p>
            <p className="text-blue-800 font-semibold"> Click on a wall to modify </p>
          </div>
        )}
      <ScrollArea className="h-[calc(100vh-200px)]">
        <div className="p-4">
          <Card>
            <CardContent>
              <div className="flex flex-wrap items-center gap-4 mb-4">
                <div className="flex items-center space-x-2">
                  <Label htmlFor="unit-select">Units:</Label>
                  <Select value={unit} onValueChange={setUnit}>
                    <SelectTrigger id="unit-select" className="w-[120px]">
                      <SelectValue placeholder="Select unit" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="in">Inches</SelectItem>
                      <SelectItem value="ft">Feet</SelectItem>
                      <SelectItem value="m">Meters</SelectItem>
                      <SelectItem value="cm">Centimeters</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center space-x-2">
                  <Label htmlFor="room-type-select">Room type:</Label>
                  <Select value={roomType} onValueChange={handleRoomTypeChange}>
                    <SelectTrigger id="room-type-select" className="w-[180px]">
                      <SelectValue placeholder="Select room type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="u-shaped">U-shaped</SelectItem>
                      <SelectItem value="l-shaped">L-shaped</SelectItem>
                      <SelectItem value="rectangular">Rectangular</SelectItem>
                      <SelectItem value="rightOpenRectangular">Right Open Rectangular</SelectItem>
                      <SelectItem value="leftOpenRectangular">Left Open Rectangular</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center space-x-2">
                  <Label htmlFor="room-height">Room Height:</Label>
                  <Input
                    id="room-height"
                    type="number"
                    value={inputValues.roomHeight || ''}
                    onChange={(e) => handleRoomHeightChange(e.target.value)}
                    onBlur={() => {
                      const numValue = parseFloat(inputValues.roomHeight);
                      if (isNaN(numValue)) {
                        setInputValues(prev => ({ ...prev, roomHeight: formatValue(roomHeight, unit) }));
                      } else {
                        setInputValues(prev => ({ ...prev, roomHeight: formatValue(convertToInches(numValue, unit), unit) }));
                      }
                    }}
                    className="w-24"
                  />
                  <span>{unit}</span>
                </div>
              </div>
              <div className="flex justify-center mb-4">
                <canvas 
                  ref={canvasRef} 
                  className="border cursor-pointer" 
                  style={{ width: '100%', height: 'auto', maxWidth: `${canvasSize.width}px`, maxHeight: `${canvasSize.height}px` }}
                  onClick={handleCanvasClick}
                ></canvas>
              </div>
            </CardContent>
          </Card>
        </div>
      </ScrollArea>

      <Dialog open={isWallDialogOpen} onOpenChange={setIsWallDialogOpen}>
        <DialogContent className="max-w-[800px] w-full  max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>Edit Wall: {formatWallName(activeWall)}</DialogTitle>
          </DialogHeader>
          <div className="flex items-center gap-2 mb-1">
            <Label htmlFor="wall-width">Wall Width:</Label>
            <Input
              id="wall-width"
              type="number"
              value={inputValues[activeWall] || ''}
              onChange={(e) => handleWallChange(activeWall, e.target.value)}
              onBlur={() => {
                const numValue = parseFloat(inputValues[activeWall]);
                if (isNaN(numValue)) {
                  setInputValues(prev => ({ ...prev, [activeWall]: formatValue(walls[activeWall], unit) }));
                } else {
                  setInputValues(prev => ({ ...prev, [activeWall]: formatValue(convertToInches(numValue, unit), unit) }));
                }
              }}
              className="w-24"
            />
            <Label htmlFor="room-height">Room Height:</Label>
                  <Input
                    id="room-height"
                    type="number"
                    value={inputValues.roomHeight || ''}
                    onChange={(e) => handleRoomHeightChange(e.target.value)}
                    onBlur={() => {
                      const numValue = parseFloat(inputValues.roomHeight);
                      if (isNaN(numValue)) {
                        setInputValues(prev => ({ ...prev, roomHeight: formatValue(roomHeight, unit) }));
                      } else {
                        setInputValues(prev => ({ ...prev, roomHeight: formatValue(convertToInches(numValue, unit), unit) }));
                      }
                    }}
                    className="w-24"
                  />
            <span>{unit}</span>
          </div>
          {activeWall && (
            <WallElementEditor
              wallName={formatWallName(activeWall)}
              wallWidth={walls[activeWall]}
              wallHeight={roomHeight}
              unit={unit}
              elements={wallElements[activeWall] || []}
              onElementsChange={handleWallElementsChange}
              onClose={() => setIsWallDialogOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManualFloorPlanEditor;