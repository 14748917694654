import React, { useRef, useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { formatValue } from './utils';

export const highlightWall = (ctx, wallCoords, startX, startY, scale) => {
    const { x1, y1, x2, y2 } = wallCoords;
    
    ctx.save();
    ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Semi-transparent blue
    ctx.lineWidth = 10; // Thicker line for highlight
    
    ctx.beginPath();
    ctx.moveTo(x1 * scale + startX , y1 * scale + startY);
    ctx.lineTo(x2 * scale + startX, y2 * scale + startY);
    ctx.stroke();
    
    ctx.restore();
  };

export const drawRoom = async (ctx, walls, wallCoordinates, wallElements, startX, startY, scale, unit) => {
    ctx.beginPath();
    
    for (const [wall, coords] of Object.entries(wallCoordinates)) {
      const { x1, y1, x2, y2, thickness = 5 } = coords;
      // Apply scale and offset at the beginning
      const x1s = (x1 * scale + startX);
      const y1s = (y1 * scale + startY);
      const x2s = (x2 * scale + startX);
      const y2s = (y2 * scale + startY);
      

      await drawWall(ctx, x1s, x2s, y1s, y2s, thickness, scale);

      const elements = wallElements[wall] || [];
      await drawWallElements(ctx, elements, x1s, x2s, y1s, y2s, thickness, scale);

      await drawWallLegend(ctx, wall, x1s, y1s, x2s, y2s, walls[wall], unit);
    }
  };
  
  const drawWall = async (ctx, x1, x2, y1, y2, thickness, scale) => {
    const isHorizontal = Math.abs(y2 - y1) < Math.abs(x2 - x1);
    const direction = isHorizontal ? Math.sign(x2 - x1) : Math.sign(y2 - y1);
    const wallThickness = thickness * scale;
    // const elementPosition = wallThickness * scale;
    
    let width, height;
    if (isHorizontal) {
      width = x2-x1 
      height = (direction > 0 ? -wallThickness : wallThickness);
    } else {
      height = y2 - y1;
      width = (direction > 0 ? wallThickness : -wallThickness);
    }

    ctx.fillStyle = 'lightgray';
    ctx.fillRect(x1, y1, width, height);
    ctx.stroke();

    // await drawElement(ctx, "wall" , x1, y1, width, height, isHorizontal, direction);
  };

  
  const drawWallElements = async (ctx, elements, x1, x2, y1, y2, wallThickness, scale) => {
    for (const element of elements) {
      const { type, x, width, height } = element;
      // const { x1, y1, x2, y2 } = wallCoords;
      
      const isHorizontal = Math.abs(y2 - y1) < Math.abs(x2 - x1);
      const direction = isHorizontal ? Math.sign(x2 - x1) : Math.sign(y2 - y1);
      const elementPosition = x * scale;
      
      let drawX, drawY;
      if (isHorizontal) {
        drawX = x1 + (direction > 0 ? elementPosition : -elementPosition);
        drawY = y1;
      } else {
        drawX = x1;
        drawY = y1 + (direction > 0 ? elementPosition : -elementPosition);
      }
  
      await drawElement(ctx, type, drawX, drawY, width * scale, height * scale, wallThickness * scale, isHorizontal, direction);
    }
  };

  const drawWallLegend = async (ctx, wallName, x1, y1, x2, y2, wallLength, unit) => {
    return new Promise(resolve => {
      ctx.save();
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      const centerX = (x1 + x2) / 2;
      const centerY = (y1 + y2) / 2;
      const isVertical = Math.abs(x2 - x1) < Math.abs(y2 - y1);
      
      const formattedValue = formatValue(wallLength, unit);
      const text = `${wallName}: ${formattedValue} ${unit}`;

      ctx.fillStyle = 'black';
      if (isVertical) {
        ctx.translate(centerX, centerY);
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(text, 0, -15); // Offset by 15 pixels
      } else {
        ctx.fillText(text, centerX, centerY - 15); // Offset by 15 pixels
      }
      
      ctx.restore();
      resolve();
    });
  };
  
  const drawElement = async (ctx, type, x, y, width, height, wallThickness, isHorizontal, direction) => {
    return new Promise(resolve => {
      ctx.save();
      switch (type) {
        case 'window':
          drawWindow(ctx, x, y, width, height, wallThickness, isHorizontal, direction);
          break;
        case 'door':
          drawDoor(ctx, x, y, width, height, wallThickness, isHorizontal, direction);
          break;
        case 'electricity':
          drawElectricity(ctx, x, y, width, height);
          break;
        case 'water':
          drawWater(ctx, x, y, width, height);
          break;
        case 'gas':
          drawGas(ctx, x, y, width, height);
          break;
        case 'opening':
          drawOpening(ctx, x, y, width, height, wallThickness, isHorizontal, direction);
          break;
        default:
          console.error(`Unexpected element type: ${type}`);
      }
      ctx.restore();
      ctx.stroke();
      resolve();
    });
  };
  
  const drawWindow = (ctx, x, y, width, height, wallThickness, isHorizontal, direction) => {
    ctx.fillStyle = 'lightblue';
    ctx.fillRect(x, y, isHorizontal ? width * direction : wallThickness * direction, isHorizontal ? -wallThickness * direction : width* direction);
    ctx.stroke();
  };

  const drawOpening = (ctx, x, y, width, height, wallThickness, isHorizontal, direction) => {
    ctx.fillStyle = 'white';
    ctx.fillRect(x, y, isHorizontal ? width * direction : wallThickness * direction, isHorizontal ? -wallThickness * direction : width* direction);
    ctx.stroke();
  };
  
  const drawDoor = (ctx, x, y, width, height, wallThickness, isHorizontal, direction) => {
    ctx.fillStyle = 'brown';
    ctx.fillRect(x, y, isHorizontal ? width * direction : wallThickness * direction, isHorizontal ? -wallThickness * direction : width * direction);
    
    // Draw an arc to represent the door swing
    ctx.beginPath();
    if (isHorizontal) {
      ctx.arc(x, y, width, direction<0 ? Math.PI : 0 , Math.PI * 0.5 * direction);
      ctx.lineTo(x, y)
    } else {
      ctx.arc(x, y, width, Math.PI * (1 - 0.5 * direction), Math.PI * (0.5 + 0.5 * direction));
      ctx.lineTo(x, y)
    }
    ctx.stroke();
  };
  
  const drawElectricity = (ctx, x, y, width, height) => {
    ctx.fillStyle = 'yellow';
    ctx.beginPath();
    ctx.arc(x, y, Math.min(width, width) / 2, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
  };
  
  const drawWater = (ctx, x, y, width, height) => {
    ctx.fillStyle = 'blue';
    ctx.beginPath();
    ctx.arc(x, y, Math.min(width, width) / 2, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
  };
  
  const drawGas = (ctx, x, y, width, height) => {
    ctx.fillStyle = 'red';
    ctx.beginPath();
    ctx.arc(x, y, Math.min(width, width) / 2, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
  };

export const getWallCoordinates = (walls, roomType, thickness = 5, roomHeight = 96) => {
  const coordinates = {};

  switch (roomType) {
    case 'u-shaped':
      coordinates.back = { x1: 0, y1: 0, z1: 0, x2:  walls.back, y2: 0, thickness: thickness, height: roomHeight};
      coordinates.right = { x1:  walls.back, y1: 0, z1: 0, x2:  walls.back, y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.left = { x2: 0, y2: 0, z1: 0, x1: 0, y1: 0 + walls.left, thickness: thickness, height: roomHeight };
      break;
    case 'l-shaped':
      coordinates.back = { x1: 0, y1: 0, z1: 0, x2: walls.back, y2: 0, thickness: thickness, height: roomHeight };
      coordinates.lRight = { x1: 0 + walls.back, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0 + walls.lRight, thickness: thickness, height: roomHeight };
      coordinates.lFrontR = { x1: 0 + walls.back, y1: 0 + walls.lRight, z1: 0, x2: 0 + (walls.back - walls.lFrontR), y2: 0 + walls.lRight, thickness: thickness, height: roomHeight };
      coordinates.lShortSide = { x1: 0 + (walls.back - walls.lFrontR), y1: 0 + walls.lRight, z1: 0, x2: 0 + (walls.back - walls.lFrontR), y2: 0 + (walls.lRight + walls.lShortSide), thickness: thickness, height: roomHeight };
      coordinates.lFrontL = { x1: 0 + (walls.back - walls.lFrontR), y1: 0 + (walls.lRight + walls.lShortSide), z1: 0, x2: 0, y2: 0 + (walls.lRight + walls.lShortSide), thickness: thickness, height: roomHeight };
      coordinates.left = { x1: 0, y1: 0 + (walls.lRight + walls.lShortSide), z1: 0, x2: 0, y2: 0, thickness: thickness, height: roomHeight };
      break;
    case 'rectangular':
      coordinates.back = { x1: 0, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0, thickness: thickness, height: roomHeight };
      coordinates.right = { x1: 0 + walls.back, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.front = { x1: 0 + walls.back, y1: 0 + walls.right, z1: 0, x2: 0, y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.left = { x1: 0, y1: 0 + walls.right, z1: 0, x2: 0, y2: 0, thickness: thickness, height: roomHeight };
      break;
    case 'rightOpenRectangular':
      coordinates.back = { x1: 0, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0, thickness: thickness, height: roomHeight };
      coordinates.right = { x1: 0 + walls.back, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.openFront = { x1: 0 + walls.back, y1: 0 + walls.right, z1: 0, x2: 0 + (walls.back - walls.openFront), y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.left = { x2: 0, y2: 0, x1: 0, y1: 0 + walls.left, z1: 0, thickness: thickness, height: roomHeight };
      break;
    case 'leftOpenRectangular':
      coordinates.back = { x1: 0, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0, thickness: thickness, height: roomHeight };
      coordinates.right = { x1: 0 + walls.back, y1: 0, z1: 0, x2: 0 + walls.back, y2: 0 + walls.right, thickness: thickness, height: roomHeight };
      coordinates.left = { x2: 0, y2: 0, x1: 0, y1: 0 + walls.left, z1: 0, thickness: thickness, height: roomHeight };
      coordinates.openFront = { x1: 0, y1: 0 + walls.left, z1: 0, x2: 0 + walls.openFront, y2: 0 + walls.left , thickness: thickness, height: roomHeight };
      break;
    default:
      console.error(`Unexpected room type: ${roomType}`);
  }
  
  return coordinates;
};

export const drawFloorPlan = async(floorPlanData, kitchenLayout, canvasWidth = 1200, canvasHeight = 800) => {
  // Create canvas with fixed size
  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const ctx = canvas.getContext('2d');

  // Clear canvas
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  const { walls, wallElements, wallCoordinates, unit } = floorPlanData;
  // const wallCoordinates = floorPlanData.wallCoordinates;

  console.log("floorPlanData:", floorPlanData, "wall coord:",wallCoordinates, "walls:", walls, "kitchenLayout:", kitchenLayout)

  // Calculate max dimensions
  const maxWidth = Math.max(...Object.values(wallCoordinates).map(coord => Math.max(coord.x1, coord.x2)));
  const maxHeight = Math.max(...Object.values(wallCoordinates).map(coord => Math.max(coord.y1, coord.y2)));

  // Add padding and calculate scale
  const padding = 40;
  const scaleX = (canvasWidth - padding * 2) / maxWidth;
  const scaleY = (canvasHeight - padding * 2) / maxHeight;
  const scale = Math.min(scaleX, scaleY);

  // Calculate starting position to center the drawing
  const startX = (canvasWidth - maxWidth * scale) / 2;
  const startY = (canvasHeight - maxHeight * scale) / 2;

  // Draw grid
  ctx.strokeStyle = '#E5E5E5';
  ctx.lineWidth = 0.5;
  const gridSize = 12 * scale;

  // Draw vertical lines
  for (let x = 0; x < canvasWidth; x += gridSize) {
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, canvasHeight);
    ctx.stroke();
  }

  // Draw horizontal lines
  for (let y = 0; y < canvasHeight; y += gridSize) {
    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(canvasWidth, y);
    ctx.stroke();
  }

  await drawRoom(ctx, walls, wallCoordinates, wallElements, startX, startY, scale, unit);

  await drawKitchenItem(ctx, kitchenLayout, startX, startY, scale);

};

export const drawKitchenItem = async(ctx, kitchenLayout, startX, startY, scale, selectedItems = [], currentSelectedIndex = 0) => {
  ctx.save();
  // Draw each kitchen item
  kitchenLayout.forEach((item, index) => {
    if (item.type === "baseCabinets") {
      ctx.fillStyle = selectedItems.includes(index) 
        ? (index === selectedItems[currentSelectedIndex] ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 165, 0, 0.5)')
        : 'rgba(139, 69, 19, 0.5)';
      drawAngledRectangle(ctx, (item.x1 * scale + startX), (item.y1 * scale + startY), 
                        item.width * scale, item.depth * scale, item.angle);
      
      if (selectedItems.includes(index)) {
        drawDragHandles(ctx, item, startX, startY, scale);
      }
    }
    else if (item.type === "island") {
      ctx.fillStyle = selectedItems.includes(index) 
        ? (index === selectedItems[currentSelectedIndex] ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 165, 0, 0.5)')
        : 'rgba(0, 128, 0, 0.5)';
      drawAngledRectangle(ctx, (item.x1* scale + startX) , (item.y1 * scale + startY) , 
                        item.width * scale, item.depth * scale, item.angle);

      if (selectedItems.includes(index)) {
        drawDragHandles(ctx, item, startX, startY, scale, true);
      }
      // Draw island label
      ctx.save();
      ctx.translate(
        (Math.min(item.x1, item.x2, item.x3, item.x4) + 
        (Math.max(item.x1, item.x2, item.x3, item.x4) - Math.min(item.x1, item.x2, item.x3, item.x4)) /2 ) * scale + startX,
        (Math.min(item.y1, item.y2, item.y3, item.y4) + 
        (Math.max(item.y1, item.y2, item.y3, item.y4) - Math.min(item.y1, item.y2, item.y3, item.y4))/2 ) * scale + startY
      );
      ctx.rotate(item.angle);
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      // ctx.fillText(`Island ${item.width}"×${item.depth}"`, 0, 0);
      
      // Draw "Island" text
      ctx.fillText("Island", 0, -8);
      
      // Draw dimensions with one decimal place
      const formattedWidth = item.width.toFixed(1);
      const formattedDepth = item.depth.toFixed(1);
      ctx.fillText(`${formattedWidth}"×${formattedDepth}"`, 0, 8);
      ctx.restore();
    }
    else if (item.type === "appliance") {
      ctx.fillStyle = selectedItems.includes(index) 
        ? (index === selectedItems[currentSelectedIndex] ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 165, 0, 0.5)')
        : 'rgba(64, 224, 208, 0.5)'; // Turquoise color for appliances
      drawAngledRectangle(ctx, (item.x1 * scale  + startX), (item.y1 * scale + startY) , 
                        item.width * scale, item.depth * scale, item.angle);
      
      // Draw appliance icon or label
      ctx.save();
      ctx.translate((Math.min(item.x1, item.x2, item.x3, item.x4) + (Math.max(item.x1, item.x2, item.x3, item.x4) - Math.min(item.x1, item.x2, item.x3, item.x4))/2 ) * scale + startX, 
      (Math.min(item.y1, item.y2, item.y3, item.y4) + (Math.max(item.y1, item.y2, item.y3, item.y4) - Math.min(item.y1, item.y2, item.y3, item.y4))/2 ) * scale + startY);
      ctx.rotate(item.angle);
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(item.name, 0, -8);
      // Draw dimensions with one decimal place
      ctx.fillText(`${parseFloat(item.width).toFixed(1)}"×${parseFloat(item.depth).toFixed(1)}"`, 0, 8);
      ctx.restore();
    }
  });
}

function drawAngledRectangle(ctx, x, y, width, depth, angle) {
  ctx.save(); // Save the current state of the context
  // Translate to the starting point of the rectangle
  ctx.translate(x, y);
  // Rotate the context
  ctx.rotate(angle);
  // Draw the rectangle
  ctx.beginPath();
  ctx.fillRect(0, 0, width, depth);
  ctx.restore(); // Restore the context to its original state
}

// Add this function to draw drag handles
const drawDragHandles = (ctx, item,startX, startY, scale, changeDepth = false) => {
  const handleSize = 10;
  ctx.fillStyle = 'white';
  ctx.strokeStyle = 'black';

  // Draw start handle
  ctx.beginPath();
  ctx.arc(((item.x1 +item.x4) * scale/2 + startX), ((item.y1 +item.y4)* scale /2 + startY) , handleSize / 2, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();

  // Draw end handle
  ctx.beginPath();
  ctx.arc(((item.x2 + item.x3)* scale /2 + startX) , ((item.y2 + item.y3) * scale/2 + startY), handleSize / 2, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
  
  if (changeDepth) {

  // Draw top handle
  ctx.beginPath();
  ctx.arc(((item.x1 +item.x2)* scale/2 + startX) , ((item.y1 +item.y2)* scale /2 + startY) , handleSize / 2, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();

  // Draw bottom handle
  ctx.beginPath();
  ctx.arc(((item.x4 + item.x3)* scale /2 + startX) , ((item.y4 + item.y3)* scale/2 + startY) , handleSize / 2, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
  }
};

const KitchenLayoutViewer = ({ floorPlanData, kitchenLayout }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 400, height: 300 });
  const [error, setError] = useState(null);
  const MIN_CANVAS_WIDTH = 320;

  const parseData = (data) => {
    if (typeof data === 'string') {
      try {
        return JSON.parse(data);
      } catch (err) {
        console.error('Error parsing data:', err);
        setError('Invalid data format');
        return null;
      }
    }
    return data;
  };

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = 300;
        const aspectRatio = 4 / 3;

        let newWidth = Math.max(MIN_CANVAS_WIDTH, Math.min(containerWidth, 800));
        let newHeight = newWidth / aspectRatio;

        if (newHeight > containerHeight) {
          newHeight = Math.min(containerHeight, 600);
          newWidth = newHeight * aspectRatio;
        }

        setCanvasSize({ width: newWidth, height: newHeight });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      setError('Could not get canvas context');
      return;
    }

    try {
      const parsedFloorPlan = parseData(floorPlanData);
      const parsedKitchenLayout = parseData(kitchenLayout) || [];

      if (!parsedFloorPlan || !parsedFloorPlan.walls) {
        setError('Invalid or missing floor plan data');
        return;
      }

      // Clear canvas
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

      // Calculate max dimensions from wall coordinates
      const coords = parsedFloorPlan.wallCoordinates;
      const maxX = Math.max(...Object.values(coords).map(coord => Math.max(coord.x1, coord.x2)));
      const maxY = Math.max(...Object.values(coords).map(coord => Math.max(coord.y1, coord.y2)));

      // Calculate scale and padding
      const padding = 40;
      const scaleX = (canvasSize.width - padding * 2) / maxX;
      const scaleY = (canvasSize.height - padding * 2) / maxY;
      const scale = Math.min(scaleX, scaleY);

      // Calculate starting position to center the drawing
      const startX = (canvasSize.width - maxX * scale) / 2;
      const startY = (canvasSize.height - maxY * scale) / 2;

      // Draw grid
      ctx.strokeStyle = '#E5E5E5';
      ctx.lineWidth = 0.5;
      const gridSize = 12 * scale;

      // Draw vertical lines
      for (let x = 0; x < canvasSize.width; x += gridSize) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvasSize.height);
        ctx.stroke();
      }

      // Draw horizontal lines
      for (let y = 0; y < canvasSize.height; y += gridSize) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(canvasSize.width, y);
        ctx.stroke();
      }

      drawRoom(ctx, parsedFloorPlan.walls, coords, parsedFloorPlan.wallElements, startX, startY, scale, parsedFloorPlan.unit);

      drawKitchenItem(ctx, parsedKitchenLayout, startX, startY, scale);

      
    } catch (err) {
      console.error('Error in KitchenLayoutViewer:', err);
      setError(`Drawing error: ${err.message}`);
    }
  }, [floorPlanData, kitchenLayout, canvasSize]);

  return (
    <div className="w-full" ref={containerRef}>
      <Card className="p-4">
        {error && (
          <div className="text-red-500 mb-2 text-sm">{error}</div>
        )}
        <canvas
          ref={canvasRef}
          width={canvasSize.width}
          height={canvasSize.height}
          className="border w-full h-auto"
          style={{
            maxWidth: `${canvasSize.width}px`,
            maxHeight: `${canvasSize.height}px`
          }}
        />
      </Card>
    </div>
  );
};

export default KitchenLayoutViewer;