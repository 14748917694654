import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ProjectService } from '@/services/ProjectService';
import { useAuthState } from '@/hooks/AuthContext';

const ProjectInitiationScreen = () => {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [projectType, setProjectType] = useState('kitchen');
  const [otherProjectType, setOtherProjectType] = useState('');
  const [projectFor, setProjectFor] = useState('self');
  const [userData, setUserData] = useState("");
  const userId = user?.id || 'defaultUserId';
  const userName = user?.given_name + " " + user.family_name || '';
  const websiteUrl = typeof window !== 'undefined' ? window.location.origin : '';

  useEffect(() => {
    async function fetchProjects() {
      try {
        const response = await ProjectService.getUser(userId);
        console.log("response:", response);
        if (response.length === 0) {
          await ProjectService.createUser(userId, user);
        }
        if (response) { setUserData(response); }
      } catch (error) {
        console.error("Error fetching or creating user:", error);
      }
    }
    fetchProjects();
  }, [userId, user]);

  const [projectDetails, setProjectDetails] = useState({
    name: '',
    email: '',
    phone: '',
    zipCode: ''
  });

  const handleProjectDetailsChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedProjectType = projectType === 'other' ? otherProjectType : projectType;
    
    // Determine the correct contact details based on projectFor
    const contactDetails = projectFor === 'self' ? {
      contactName: userName,
      contactEmail: user?.email,
      contactPhoneNumber: userData?.phone || projectDetails.phone,
      zipCode: projectDetails.zipCode,
      customerDetails: {
        name: userName,
        email: user?.email,
        phone: userData?.phone || projectDetails.phone,
        zipCode: projectDetails.zipCode
      }
    } : {
      contactName: projectDetails.name,
      contactEmail: projectDetails.email,
      contactPhoneNumber: projectDetails.phone,
      zipCode: projectDetails.zipCode,
      customerDetails: projectDetails
    };

    try {
      const newProject = await ProjectService.createProject(user.id);
      await ProjectService.updateProject(newProject.id, {
        projectType: selectedProjectType,
        projectFor: projectFor,
        websiteUrl: websiteUrl,
        ...contactDetails
      });

      // If project is for self, update user information
      if (projectFor === 'self') {
        const userUpdates = {
          name: userName,
          email: user?.email,
          phone: projectDetails.phone,
          zipCode: projectDetails.zipCode
        };
        await ProjectService.updateUser(userId, userUpdates);
      }

      navigate(`/new-kitchen-project/${newProject.id}`);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const isFormValid = () => {
    if (projectType === 'other' && !otherProjectType) return false;
    if (projectFor === 'self') {
      return projectDetails.zipCode && userName && user?.email;
    } else {
      return projectDetails.name && 
             projectDetails.email && 
             projectDetails.zipCode;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Start a New Project</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <Label className="text-base font-semibold">Who is this project for?</Label>
              <RadioGroup value={projectFor} onValueChange={setProjectFor} className="space-y-2">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="self" id="self" />
                  <Label htmlFor="self">Myself</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="other" id="other" />
                  <Label htmlFor="other">Someone else</Label>
                </div>
              </RadioGroup>
            </div>

            <div className="space-y-4">
              <Label className="text-base font-semibold">
                {projectFor === 'self' ? 'Your Details' : 'Contact Details'}
              </Label>
              {projectFor === 'other' && (
                <>
                  <Input
                    name="name"
                    placeholder="Name"
                    value={projectDetails.name}
                    onChange={handleProjectDetailsChange}
                    required
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={projectDetails.email}
                    onChange={handleProjectDetailsChange}
                    required
                  />
                </>
              )}
              {projectFor === 'self' && (
                <>
                  <Input
                    name="name"
                    placeholder="Name"
                    value={userName}
                    required
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={user?.email}
                    required
                  />
                </>
              )}
              <Input
                name="phone"
                type="tel"
                placeholder="Phone Number (Optional)"
                value={userData?.phone || projectDetails.phone}
                onChange={handleProjectDetailsChange}
              />
              <Input
                name="zipCode"
                placeholder="Project Zip Code"
                value={userData?.zipCode || projectDetails.zipCode}
                onChange={handleProjectDetailsChange}
                required
              />
            </div>

            <div className="space-y-4">
              <Label className="text-base font-semibold">What are you planning?</Label>
              <RadioGroup value={projectType} onValueChange={setProjectType} className="space-y-2">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="kitchen" id="kitchen" />
                  <Label htmlFor="kitchen">Kitchen</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="bathroom" id="bathroom" />
                  <Label htmlFor="bathroom">Bathroom</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="wardrobe" id="wardrobe" />
                  <Label htmlFor="wardrobe">Wardrobe</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="other" id="other" />
                  <Label htmlFor="other">Other</Label>
                </div>
              </RadioGroup>
              {projectType === 'other' && (
                <Input
                  placeholder="Specify project type"
                  value={otherProjectType}
                  onChange={(e) => setOtherProjectType(e.target.value)}
                  required
                />
              )}
            </div>

            <Button 
              type="submit" 
              className="w-full" 
              disabled={!isFormValid()}
            >
              Start Project
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProjectInitiationScreen;