import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';

const S3_BASE_URL = "https://gni-public-bucket.s3.amazonaws.com/";

const UserInspirationScreen = () => {
  const navigate = useNavigate();
  const {projectId} = useParams();
  const [selectedStyle, setSelectedStyle] = useState(null);

  useEffect(() => {
    async function fetchProject(params) {
      const currentProject = await ProjectService.getProjectById(projectId)
      
      if (currentProject) {
        setSelectedStyle(currentProject.selectedStyle || null);
      }
    }
    fetchProject();
  }, [projectId]);

  const handleStyleSelection = (styleName) => {
    async function fetchProjectStyle() {
      setSelectedStyle(styleName);
      await ProjectService.updateProject(projectId, {selectedStyle: styleName})
    }
    fetchProjectStyle();
  };

  const handleNext = () => {
    if (selectedStyle) {
      navigate(`/new-kitchen-project/${projectId}/moodboard`);
    } else {
      handleStyleSelection('Traditional');
      navigate(`/new-kitchen-project/${projectId}/moodboard`);
    }
  };

  const handleBack = () => {
    navigate(`/new-kitchen-project/${projectId}`);
  };

  const styles = [
    { name: 'Traditional', image: `${S3_BASE_URL}customers_demo_versions/traditional_kitchen.jpg` },
    { name: 'Transitional', image: `${S3_BASE_URL}customers_demo_versions/transiotional_kitchen.jpg` },
    { name: 'Rustic', image: `${S3_BASE_URL}customers_demo_versions/rustic_kitchen.jpg` },
    { name: 'Modern', image: `${S3_BASE_URL}customers_demo_versions/modern_kitchen.jpg` },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <ProjectHeader showBackButton = {true}/>
      <main className="pt-20 px-4">
        <Card className="max-w-4xl mx-auto">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">Choose Your Kitchen Style</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4 mb-6">
              {styles.map((style) => (
                <div
                  key={style.name}
                  className={`cursor-pointer border p-4 rounded-lg hover:bg-gray-50 transition-all ${
                    selectedStyle === style.name ? 'ring-2 ring-blue-500' : ''
                  }`}
                  onClick={() => handleStyleSelection(style.name)}
                >
                  <img src={style.image} alt={style.name} className="w-full h-48 object-cover mb-2 rounded" />
                  <p className="text-center font-semibold">{style.name}</p>
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-6">
              <Button variant="outline" onClick={handleBack}>
                Back to All Sections
              </Button>
              <Button onClick={handleNext}>
                Next
              </Button>
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default UserInspirationScreen;