import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';


// const DraggableComponent = ({ component, position, onDrag, selected }) => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  
//   const handleMouseDown = (e) => {
//     setIsDragging(true);
//     const rect = e.target.getBoundingClientRect();
//     setDragOffset({
//       x: e.clientX - rect.left,
//       y: e.clientY - rect.top
//     });
//   };

//   const handleMouseMove = (e) => {
//     if (isDragging) {
//       const newX = e.clientX - dragOffset.x;
//       const newY = e.clientY - dragOffset.y;
//       onDrag(component.type, { x: newX, y: newY });
//     }
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//   };

//   useEffect(() => {
//     if (isDragging) {
//       window.addEventListener('mousemove', handleMouseMove);
//       window.addEventListener('mouseup', handleMouseUp);
//     }
//     return () => {
//       window.removeEventListener('mousemove', handleMouseMove);
//       window.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [isDragging]);

//   const getComponentStyle = () => {
//     if (component.type === 'doorFront') {
//       return {
//         backgroundImage: `url(${S3_BASE_URL}${component.image})`,
//         backgroundSize: 'cover',
//         width: '100px',
//         height: '150px'
//       };
//     } else {
//       return {
//         backgroundColor: component.color,
//         width: getComponentWidth(component.type),
//         height: getComponentHeight(component.type)
//       };
//     }
//   };

//   const getComponentWidth = (type) => {
//     switch (type) {
//       case 'countertop': return '200px';
//       case 'appliances': return '120px';
//       case 'faucet': return '40px';
//       case 'handle': return '20px';
//       case 'tiles': return '80px';
//       default: return '100px';
//     }
//   };

//   const getComponentHeight = (type) => {
//     switch (type) {
//       case 'countertop': return '60px';
//       case 'appliances': return '150px';
//       case 'faucet': return '60px';
//       case 'handle': return '80px';
//       case 'tiles': return '80px';
//       default: return '100px';
//     }
//   };

//   return (
//     <div
//       className={`absolute cursor-move rounded-lg border-2 ${
//         selected ? 'border-blue-500' : 'border-transparent'
//       }`}
//       style={{
//         ...getComponentStyle(),
//         left: position.x,
//         top: position.y,
//         transition: isDragging ? 'none' : 'all 0.2s',
//         zIndex: isDragging ? 1000 : 1
//       }}
//       onMouseDown={handleMouseDown}
//     >
//       <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 text-center">
//         {component.name}
//       </div>
//     </div>
//   );
// };

// const InteractiveKitchenCanvas = ({ selections, selectedComponent, onComponentSelect }) => {
//   const [componentPositions, setComponentPositions] = useState({});
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     // Initialize default positions for new components
//     const newPositions = {};
//     Object.entries(selections).forEach(([type, component], index) => {
//       if (!componentPositions[type]) {
//         newPositions[type] = {
//           x: 50 + (index * 120),
//           y: 50 + (index * 50)
//         };
//       }
//     });
//     setComponentPositions(prev => ({ ...prev, ...newPositions }));
//   }, [selections]);

//   const handleDrag = (type, position) => {
//     setComponentPositions(prev => ({
//       ...prev,
//       [type]: position
//     }));
//   };

//   return (
//     <div 
//       ref={canvasRef}
//       className="relative bg-gray-100 rounded-lg w-full h-96 overflow-hidden"
//       onClick={() => onComponentSelect(null)}
//     >
//       <div className="absolute top-4 left-4 text-sm text-gray-500">
//         Drag components to arrange them
//       </div>
//       {Object.entries(selections).map(([type, component]) => (
//         <DraggableComponent
//           key={type}
//           component={{ ...component, type }}
//           position={componentPositions[type] || { x: 0, y: 0 }}
//           onDrag={handleDrag}
//           selected={selectedComponent === type}
//           onClick={(e) => {
//             e.stopPropagation();
//             onComponentSelect(type);
//           }}
//         />
//       ))}
//     </div>
//   );
// };

const DraggableComponent = ({ component, position, onDrag, selected }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  
  const handleMouseDown = (e) => {
    setIsDragging(true);
    const rect = e.target.getBoundingClientRect();
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      onDrag(component.type, { x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const getComponentStyle = () => {
    if (component.type === 'doorFront') {
      return {
        backgroundImage: `url(${S3_BASE_URL}${component.image})`,
        backgroundSize: 'cover',
        width: '100px',
        height: '150px'
      };
    } else {
      return {
        backgroundColor: component.color,
        width: getComponentWidth(component.type),
        height: getComponentHeight(component.type)
      };
    }
  };

  return (
    <div
      className={`absolute cursor-move rounded-lg border-2 ${
        selected ? 'border-blue-500' : 'border-transparent'
      }`}
      style={{
        ...getComponentStyle(),
        left: position.x,
        top: position.y,
        transition: isDragging ? 'none' : 'all 0.2s',
        zIndex: isDragging ? 1000 : 1
      }}
      onMouseDown={handleMouseDown}
    >
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 text-center">
        {component.name}
      </div>
    </div>
  );
};

const getComponentWidth = (type) => {
  switch (type) {
    case 'countertop': return '200px';
    case 'appliances': return '120px';
    case 'faucet': return '40px';
    case 'handle': return '20px';
    case 'tiles': return '80px';
    default: return '100px';
  }
};

const getComponentHeight = (type) => {
  switch (type) {
    case 'countertop': return '60px';
    case 'appliances': return '150px';
    case 'faucet': return '60px';
    case 'handle': return '80px';
    case 'tiles': return '80px';
    default: return '100px';
  }
};

const InteractiveKitchenCanvas = ({ selections = {}, selectedComponent, onComponentSelect }) => {
  const [componentPositions, setComponentPositions] = useState({});
  const canvasRef = useRef(null);

  useEffect(() => {
    if (selections && Object.keys(selections).length > 0) {
      // Initialize default positions for new components
      const newPositions = {};
      Object.entries(selections).forEach(([type, component], index) => {
        if (!componentPositions[type]) {
          newPositions[type] = {
            x: 50 + (index * 120),
            y: 50 + (index * 50)
          };
        }
      });
      setComponentPositions(prev => ({ ...prev, ...newPositions }));
    }
  }, [selections]);

  const handleDrag = (type, position) => {
    setComponentPositions(prev => ({
      ...prev,
      [type]: position
    }));
  };

  const handleCanvasClick = (e) => {
    if (e.target === canvasRef.current) {
      onComponentSelect(null);
    }
  };

  if (!selections) {
    return (
      <div className="relative bg-gray-100 rounded-lg w-full h-96 flex items-center justify-center">
        <p className="text-gray-500">Loading components...</p>
      </div>
    );
  }

  return (
    <div 
      ref={canvasRef}
      className="relative bg-gray-100 rounded-lg w-full h-96 overflow-hidden"
      onClick={handleCanvasClick}
    >
      <div className="absolute top-4 left-4 text-sm text-gray-500">
        Drag components to arrange them
      </div>
      {Object.entries(selections).map(([type, component]) => (
        component && (
          <DraggableComponent
            key={type}
            component={{ ...component, type }}
            position={componentPositions[type] || { x: 0, y: 0 }}
            onDrag={handleDrag}
            selected={selectedComponent === type}
            onClick={(e) => {
              e.stopPropagation();
              onComponentSelect(type);
            }}
          />
        )
      ))}
    </div>
  );
};


const S3_BASE_URL = "https://gni-public-bucket.s3.amazonaws.com/customers_demo_versions/";

const CubeIcon = () => (
  <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
    <line x1="12" y1="22.08" x2="12" y2="12"></line>
  </svg>
);

const ComponentOption = ({ name, image, onClick, isSelected }) => (
  <div 
    onClick={onClick}
    className={`cursor-pointer p-2 rounded-lg flex items-center space-x-2 ${
      isSelected ? 'bg-blue-100 ring-2 ring-blue-500' : 'hover:bg-gray-100'
    }`}
  >
    <img src={`${S3_BASE_URL}${image}`} alt={name} className="w-12 h-12 object-cover rounded" />
    <span>{name}</span>
  </div>
);

const User3DViewerScreen = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedMoodboard, setSelectedMoodboard] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [currentView, setCurrentView] = useState('full');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [selections, setSelections] = useState({});

  const componentOptions = {
    doorFront: [
      { name: 'Antique White', image: 'shaker-antique-white_1.jpg' },
      { name: 'Natural Oak', image: 'halifax-natural-oak_37.jpg' },
      { name: 'Colonial Blue', image: 'shaker-matt-colonial-blue-new_1.jpg' },
      { name: 'Sage Green', image: 'shaker-matt-sage-green-new_1.jpg' },
    ],
    handle: [
      { name: 'Silver', color: '#C0C0C0' },
      { name: 'Gold', color: '#FFD700' },
      { name: 'Black', color: '#000000' },
    ],
    tiles: [
      { name: 'Beige', color: '#F5F5DC' },
      { name: 'Gray', color: '#808080' },
      { name: 'Terracotta', color: '#E2725B' },
    ],
    countertop: [
      { name: 'Marble', color: '#F2F2F2' },
      { name: 'Granite', color: '#808080' },
      { name: 'Butcher Block', color: '#DEB887' },
    ],
    faucet: [
      { name: 'Stainless Steel', color: '#C0C0C0' },
      { name: 'Black', color: '#000000' },
      { name: 'Bronze', color: '#CD7F32' },
    ],
    appliances: [
      { name: 'Black', color: '#000000' },
      { name: 'White', color: '#FFFFFF' },
      { name: 'Silver', color: '#C0C0C0' },
    ],
  };

   // Default selections
   const defaultSelections = {
    doorFront: componentOptions.doorFront[0],
    handle: componentOptions.handle[0],
    tiles: componentOptions.tiles[0],
    countertop: componentOptions.countertop[0],
    faucet: componentOptions.faucet[0],
    appliances: componentOptions.appliances[0],
  };

  useEffect(() => {
    // Load project data from Storage
    async function fetchProject() {
      const currentProject = await ProjectService.getProjectById(projectId)
      if (currentProject) {
        setSelectedStyle(currentProject.selectedStyle || null);
        setSelectedMoodboard(currentProject.selectedMoodboard || null);
        setSelections(currentProject.selections || {});
      }
      else {
        setSelections(defaultSelections);
      }
    }
    fetchProject();
  }, [projectId]);

  const handleComponentClick = (component) => {
    setSelectedComponent(component);
  };

  const handleOptionSelect = (option) => {
    async function fetchUpdateProject() {
      const newSelections = {
      ...selections,
      [selectedComponent]: option
    };
    setSelections(newSelections);
    await ProjectService.updateProject(projectId, { selections: newSelections })
    console.log(`Selected ${option.name} for ${selectedComponent}`);
  }
  fetchUpdateProject();
  };

  const handleBack = () => {
    navigate(`/new-kitchen-project/${projectId}/moodboard`);
  };

  const handleComplete = () => {
    async function fetchCompletedFlow() {
      await ProjectService.addCompletedFlow(projectId, 'colors')
      navigate(`/new-kitchen-project/${projectId}`);
    }
    fetchCompletedFlow();
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <ProjectHeader showBackButton={true}/>
      <main className="pt-20 px-4">
      <Card className="max-w-7xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Customize Your Kitchen</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-6">

            {/* 3D Viewer Placeholder */}
            {/* <div className="col-span-2 bg-gray-200 rounded-lg h-96 flex items-center justify-center">
              <div className="text-center">
                <CubeIcon />
                <p className="mt-4">3D viewer would be displayed here</p>
              </div>
            </div> */}

            <InteractiveKitchenCanvas
              selections={selections}
              selectedComponent={selectedComponent}
              onComponentSelect={handleComponentClick}
            />

            {/* Right Panel */}
            <div className="space-y-6">
              {/* Interactive Moodboard */}
              <div className="relative">
                <img 
                  // src={`${S3_BASE_URL}${selectedMoodboard?.toLowerCase().replace(' ', '_') || 'light_blue'}_moodboard.jpg`}
                  alt={selectedMoodboard || "Default Moodboard"} 
                  className="w-full h-48 object-cover rounded-lg"
                />
                <div className="absolute inset-0 grid grid-cols-3 grid-rows-3 gap-2 p-2">
                  {Object.keys(componentOptions).map((component, index) => (
                    <div 
                      key={component}
                      className={`cursor-pointer rounded-lg border-2 ${
                        selectedComponent === component ? 'border-blue-500' : 'border-white'
                      } flex items-center justify-center relative overflow-hidden`}
                      onClick={() => handleComponentClick(component)}
                    >
                      {component === 'doorFront' ? (
                        <img 
                          src={`${S3_BASE_URL}${selections[component]?.image}`} 
                          alt={selections[component]?.name}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div 
                          className="w-full h-full"
                          style={{
                            backgroundColor: selections[component]?.color || 'transparent',
                            opacity: 0.7
                          }}
                        ></div>
                      )}
                      <span className="text-xs font-bold text-white bg-black bg-opacity-50 p-1 rounded absolute bottom-0 left-0 right-0 text-center">
                        {component.charAt(0).toUpperCase() + component.slice(1)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Component Options */}
              {selectedComponent && (
                <div>
                  <h3 className="font-semibold mb-2">{selectedComponent} Options</h3>
                  <div className="space-y-2">
                    {componentOptions[selectedComponent].map((option) => (
                      <ComponentOption
                        key={option.name}
                        name={option.name}
                        image={option.image}
                        color={option.color}
                        onClick={() => handleOptionSelect(option)}
                        isSelected={selections[selectedComponent]?.name === option.name}
                      />
                    ))}
                  </div>
                </div>
              )}

            {/* Advanced Options */}
              <Collapsible>
                <CollapsibleTrigger asChild>
                  <Button variant="outline" className="w-full">
                    {showAdvancedOptions ? 'Hide' : 'Show'} Advanced Options
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent className="mt-2">
                  <p>Advanced customization options would go here.</p>
                </CollapsibleContent>
              </Collapsible>
            </div>
          </div>

          {/* View Controls */}
          <div className="flex justify-center mt-6 space-x-4">
            <Button variant={currentView === 'full' ? 'default' : 'outline'} onClick={() => setCurrentView('full')}>
              Full View
            </Button>
            <Button variant={currentView === 'top' ? 'default' : 'outline'} onClick={() => setCurrentView('top')}>
              Top View
            </Button>
          </div>

          {/* Navigation */}
          <div className="flex justify-between mt-6">
            <Button variant="outline" onClick={handleBack}>
              Back
            </Button>
            <Button onClick={handleComplete}>
              Complete This Section
            </Button>
          </div>
        </CardContent>
      </Card>
      </main>
    </div>
  );
};

export default User3DViewerScreen;




