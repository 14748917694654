import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Button } from "../components/ui/button";

const Banner = () => {
  const navigate = useNavigate();

  const handleStartDesigning = () => {
    navigate('/welcome');
  };

  return (
    <div className="bg-blue-600 text-white p-8 text-center">
      <h1 className="text-4xl font-bold mb-4">Transform Your Kitchen Dreams into Reality</h1>
      <p className="text-xl mb-6">Start your journey to a perfect kitchen today!</p>
      <Button onClick={handleStartDesigning} size="lg">
        Let's Go
        <ArrowRight className="ml-2" size={20} />
      </Button>
    </div>
  );
};

export default Banner;