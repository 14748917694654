const Soon = () => {
    return (
        <>
            <style>
                {`
                    body {
                        margin: 0;
                        padding: 0;
                        min-height: 100vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Arial, sans-serif;
                        background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
                        text-align: center;
                    }
                    .container {
                        padding: 2rem;
                        background: white;
                        border-radius: 10px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        max-width: 600px;
                        margin: 20px;
                        animation: fadeIn 1s ease-in;
                    }
                    .logo {
                        font-size: 3rem;
                        font-weight: bold;
                        color: #2c3e50;
                        margin-bottom: 1rem;
                        animation: slideIn 1s ease-out;
                    }
                    .message {
                        font-size: 1.5rem;
                        color: #34495e;
                        margin-bottom: 2rem;
                    }
                    .construction-icon {
                        font-size: 4rem;
                        color: #e67e22;
                        margin-bottom: 1rem;
                        animation: bounce 1.5s infinite;
                    }
                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                    @keyframes slideIn {
                        from {
                            transform: translateY(-50px);
                        }
                        to {
                            transform: translateY(0);
                        }
                    }
                    @keyframes bounce {
                        0%, 100% {
                            transform: translateY(0);
                        }
                        50% {
                            transform: translateY(-20px);
                        }
                    }
                    @media (max-width: 600px) {
                        .container {
                            padding: 1rem;
                        }
                        .logo {
                            font-size: 2.5rem;
                        }
                        .message {
                            font-size: 1.2rem;
                        }
                    }
                `}
            </style>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
            <div className="container">
                <div className="logo">GNI</div>
                <div className="construction-icon">
                    <i className="fas fa-hard-hat"></i>
                </div>
                <div className="message">
                    <p>Our website is currently under construction.</p>
                    <p>We're working hard to bring you something amazing!</p>
                    <p>Please check back soon.</p>
                </div>
            </div>
        </>
    );
};

export default Soon;
