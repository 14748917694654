import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { DxfParser } from 'dxf-parser';
import { Upload, Plus, Trash2, X, Maximize2, ImageOff, Play, Video, Camera, Square, File, FileText, Music, Code, Archive, Cuboid } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';

const CameraCapture = ({ onCapture, onClose }) => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const chunksRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [mode, setMode] = useState('photo'); // 'photo' or 'video'

  useEffect(() => {
    startCamera();
    return () => stopCamera();
  }, []);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
        audio: true
      });
      
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
      onClose();
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
    }
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoRef.current, 0, 0);
      
      canvas.toBlob((blob) => {
        const file = new File([blob], `photo_${Date.now()}.jpg`, { type: 'image/jpeg' });
        onCapture({ target: { files: [file] } });
        onClose();
      }, 'image/jpeg');
    }
  };

  const startRecording = () => {
    chunksRef.current = [];
    const mediaRecorder = new MediaRecorder(streamRef.current);
    
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunksRef.current.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunksRef.current, { type: 'video/webm' });
      const file = new File([blob], `video_${Date.now()}.webm`, { type: 'video/webm' });
      onCapture({ target: { files: [file] } });
      onClose();
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <div className="relative">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-full"
      />
      <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4">
        <div className="space-y-4">
          <div className="flex justify-center gap-4">
            <Button
              variant="outline"
              onClick={() => setMode('photo')}
              className={mode === 'photo' ? 'bg-blue-100' : ''}
            >
              <Camera className="w-4 h-4 mr-2" />
              Photo
            </Button>
            <Button
              variant="outline"
              onClick={() => setMode('video')}
              className={mode === 'video' ? 'bg-blue-100' : ''}
            >
              <Video className="w-4 h-4 mr-2" />
              Video
            </Button>
          </div>
          
          <div className="flex justify-center gap-4">
            {mode === 'photo' ? (
              <Button onClick={capturePhoto}>
                <Camera className="w-4 h-4 mr-2" />
                Take Photo
              </Button>
            ) : (
              <Button
                onClick={isRecording ? stopRecording : startRecording}
                variant={isRecording ? "destructive" : "default"}
              >
                {isRecording ? (
                  <>
                    <Square className="w-4 h-4 mr-2" />
                    Stop Recording
                  </>
                ) : (
                  <>
                    <Video className="w-4 h-4 mr-2" />
                    Start Recording
                  </>
                )}
              </Button>
            )}
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThreeModelViewer = ({ url, fileType }) => {
  const mountRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!mountRef.current) return;

    let animate;
    const cleanupFns = [];

    const init = async () => {
      // Scene setup
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xf0f0f0);

      // Camera setup
      const camera = new THREE.PerspectiveCamera(
        75,
        mountRef.current.clientWidth / mountRef.current.clientHeight,
        0.1,
        1000
      );
      camera.position.z = 5;

      // Renderer setup
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
      mountRef.current.appendChild(renderer.domElement);
      cleanupFns.push(() => {
        renderer.dispose();
        mountRef.current?.removeChild(renderer.domElement);
      });

      // Lighting
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(1, 1, 1);
      scene.add(directionalLight);

      // Controls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      cleanupFns.push(() => controls.dispose());

      // Load model based on file type
      try {
        let loader;
        if (fileType.toLowerCase().endsWith('.glb') || fileType.toLowerCase().endsWith('.gltf')) {
          loader = new GLTFLoader();
        } else if (fileType.toLowerCase().endsWith('.obj')) {
          loader = new OBJLoader();
        } else if (fileType.toLowerCase().endsWith('.fbx')) {
          loader = new FBXLoader();
        } else {
          throw new Error('Unsupported file format');
        }

        const model = await new Promise((resolve, reject) => {
          loader.load(
            url,
            resolve,
            undefined,
            reject
          );
        });

        // Handle different model formats
        const object = model.scene || model;
        scene.add(object);

        // Center and scale model
        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        
        const maxDim = Math.max(size.x, size.y, size.z);
        const scale = 2 / maxDim;
        object.scale.multiplyScalar(scale);
        
        object.position.sub(center.multiplyScalar(scale));
        camera.position.z = 5;
      } catch (err) {
        console.error('Error loading 3D model:', err);
        setError('Failed to load 3D model');
        return;
      }

      // Animation loop
      animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      // Handle resize
      const handleResize = () => {
        if (!mountRef.current) return;
        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      };
      window.addEventListener('resize', handleResize);
      cleanupFns.push(() => window.removeEventListener('resize', handleResize));
    };

    init();

    return () => {
      if (animate) {
        cancelAnimationFrame(animate);
      }
      cleanupFns.forEach(fn => fn());
    };
  }, [url, fileType]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return <div ref={mountRef} className="w-full h-[400px]" />;
};

const DxfViewer = ({ url }) => {
  const mountRef = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!mountRef.current) return;

    let animate;
    const cleanupFns = [];

    const createLine = (start, end) => {
      const points = [
        new THREE.Vector3(start.x, start.y, start.z || 0),
        new THREE.Vector3(end.x, end.y, end.z || 0)
      ];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      return new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    };

    const createArc = (center, radius, startAngle, endAngle) => {
      const curve = new THREE.EllipseCurve(
        center.x, center.y,
        radius, radius,
        startAngle, endAngle,
        false,
        0
      );
      const points = curve.getPoints(50);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      return new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    };

    const createCircle = (center, radius) => {
      const curve = new THREE.EllipseCurve(
        center.x, center.y,
        radius, radius,
        0, 2 * Math.PI,
        false,
        0
      );
      const points = curve.getPoints(50);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      return new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    };

    const createPolyline = (vertices) => {
      const points = vertices.map(v => new THREE.Vector3(v.x, v.y, v.z || 0));
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      return new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    };

    const init = async () => {
      try {
        // Fetch DXF file content
        const response = await fetch(url);
        const dxfContent = await response.text();

        // Parse DXF
        const parser = new DxfParser();
        const dxf = parser.parseSync(dxfContent);

        // Scene setup
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xf0f0f0);

        // Camera setup
        const camera = new THREE.PerspectiveCamera(
          45,
          mountRef.current.clientWidth / mountRef.current.clientHeight,
          0.1,
          1000
        );

        // Renderer setup
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        mountRef.current.appendChild(renderer.domElement);
        cleanupFns.push(() => {
          renderer.dispose();
          mountRef.current?.removeChild(renderer.domElement);
        });

        // Create parent group for all entities
        const entityGroup = new THREE.Group();
        scene.add(entityGroup);

        // Process entities
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        dxf.entities.forEach(entity => {
          let object = null;

          switch (entity.type) {
            case 'LINE':
              object = createLine(entity.start, entity.end);
              minX = Math.min(minX, entity.start.x, entity.end.x);
              minY = Math.min(minY, entity.start.y, entity.end.y);
              maxX = Math.max(maxX, entity.start.x, entity.end.x);
              maxY = Math.max(maxY, entity.start.y, entity.end.y);
              break;

            case 'CIRCLE':
              object = createCircle(entity.center, entity.radius);
              minX = Math.min(minX, entity.center.x - entity.radius);
              minY = Math.min(minY, entity.center.y - entity.radius);
              maxX = Math.max(maxX, entity.center.x + entity.radius);
              maxY = Math.max(maxY, entity.center.y + entity.radius);
              break;

            case 'ARC':
              object = createArc(
                entity.center,
                entity.radius,
                entity.startAngle * Math.PI / 180,
                entity.endAngle * Math.PI / 180
              );
              minX = Math.min(minX, entity.center.x - entity.radius);
              minY = Math.min(minY, entity.center.y - entity.radius);
              maxX = Math.max(maxX, entity.center.x + entity.radius);
              maxY = Math.max(maxY, entity.center.y + entity.radius);
              break;

            case 'LWPOLYLINE':
            case 'POLYLINE':
              if (entity.vertices?.length > 0) {
                object = createPolyline(entity.vertices);
                entity.vertices.forEach(vertex => {
                  minX = Math.min(minX, vertex.x);
                  minY = Math.min(minY, vertex.y);
                  maxX = Math.max(maxX, vertex.x);
                  maxY = Math.max(maxY, vertex.y);
                });
              }
              break;
          }

          if (object) {
            entityGroup.add(object);
          }
        });

        // Center and scale the drawing
        if (minX !== Infinity && minY !== Infinity) {
          const width = maxX - minX;
          const height = maxY - minY;
          const centerX = (minX + maxX) / 2;
          const centerY = (minY + maxY) / 2;

          // Move to center
          entityGroup.position.set(-centerX, -centerY, 0);

          // Scale to fit view
          const scale = Math.min(
            8 / width,
            8 / height
          );
          entityGroup.scale.set(scale, scale, scale);
        }

        // Add grid
        const gridHelper = new THREE.GridHelper(20, 20);
        gridHelper.rotation.x = Math.PI / 2;
        scene.add(gridHelper);

        // Position camera
        camera.position.set(0, 0, 10);

        // Add controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.screenSpacePanning = true;
        cleanupFns.push(() => controls.dispose());

        // Animation loop
        animate = () => {
          requestAnimationFrame(animate);
          controls.update();
          renderer.render(scene, camera);
        };
        animate();

        // Handle resize
        const handleResize = () => {
          if (!mountRef.current) return;
          const width = mountRef.current.clientWidth;
          const height = mountRef.current.clientHeight;
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
          renderer.setSize(width, height);
        };
        window.addEventListener('resize', handleResize);
        cleanupFns.push(() => window.removeEventListener('resize', handleResize));

        setLoading(false);
      } catch (err) {
        console.error('Error loading DXF:', err);
        setError('Failed to load DXF file');
        setLoading(false);
      }
    };

    init();

    return () => {
      if (animate) {
        cancelAnimationFrame(animate);
      }
      cleanupFns.forEach(fn => fn());
    };
  }, [url]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return <div ref={mountRef} className="w-full h-[400px]" />;
};

const getFileIcon = (mimeType, extension) => {
    // Check file extension for 3D models (since MIME types aren't always reliable)
    const modelExtensions = ['.glb', '.gltf', '.obj', '.fbx', '.stl', '.3ds'];
    if (MODEL_FILE_TYPES.includes(mimeType) || 
        modelExtensions.some(ext => extension?.toLowerCase().endsWith(ext))) {
      return <Cuboid />;
    }
    
    if (mimeType.startsWith('image/')) return <ImageOff />;
    if (mimeType.startsWith('video/')) return <Video />;
    if (mimeType.startsWith('audio/')) return <Music />;
    if (mimeType.startsWith('text/')) return <FileText />;
    if (mimeType.includes('pdf')) return <FileText />;
    if (mimeType.includes('word') || mimeType.includes('document')) return <FileText />;
    if (mimeType.includes('spreadsheet') || mimeType.includes('excel')) return <FileText />;
    if (mimeType.includes('presentation') || mimeType.includes('powerpoint')) return <FileText />;
    if (mimeType.includes('compressed') || mimeType.includes('zip') || mimeType.includes('rar')) return <Archive />;
    if (mimeType.includes('code') || mimeType.includes('javascript') || mimeType.includes('json')) return <Code />;
    return <File />;
  };

  const MODEL_FILE_TYPES = [
      'model/gltf-binary',
      'model/gltf+json',
      'model/obj',
      'model/fbx',
      'application/octet-stream',
      'application/dxf',
      'application/x-dxf',
      'image/vnd.dxf',
    ];

const FilePreview = ({ file }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    const loadPreview = async () => {
      if (!file) return;
      
      setIsLoading(true);
      setError(null);
      
      console.log(file)
      try {
        // If we have a blob URL from local upload, use it
        if (file.url?.startsWith('blob:')) {
          setPreviewUrl(file.url);
        }
        // Otherwise, download from S3
        else if (file.s3Key) {
          const downloadedFile = await ProjectService.downloadFileFromS3(file.s3Key);
          if (mounted) {
            const newUrl = URL.createObjectURL(downloadedFile);
            setPreviewUrl(newUrl);
          }
        } else {
          throw new Error('No valid source for file preview');
        }
      } catch (err) {
        console.error('Error loading preview:', err);
        if (mounted) {
          setError('Failed to load preview');
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    loadPreview();

    return () => {
      mounted = false;
      // Cleanup blob URL if we created one
      if (previewUrl?.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [file]);

  if (!file) return null;

  // If we have a thumbnail but no URL yet (during loading), show the thumbnail
  if (file.thumbnail && !file.url) {
    return (
      <div className="flex items-center justify-center">
        <img 
          src={file.thumbnail} 
          alt={file.name}
          className="max-w-full max-h-[80vh] object-contain"
        />
      </div>
    );
  }

  // Rest of FilePreview component remains the same...
  if (!file) return null;
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  const getPreviewContent = () => {
    const modelExtensions = ['.glb', '.gltf', '.obj', '.fbx', '.stl', '.3ds'];
    const isModelFile = modelExtensions.some(ext => 
      file.name?.toLowerCase().endsWith(ext) || 
      MODEL_FILE_TYPES.includes(file.type)
    );

    if (isModelFile) {
      return (
        <ThreeModelViewer 
          url={previewUrl} 
          fileType={file.name.substring(file.name.lastIndexOf('.'))}
        />
      );
    }

    if (file.name?.toLowerCase().endsWith('.dxf')) {
      return <DxfViewer url={previewUrl} />;
    }

    if (file.type.startsWith('image/')) {
      return (
        <img 
          src={previewUrl} 
          alt={file.name}
          className="max-w-full max-h-[80vh] object-contain"
        />
      );
    }
    
    if (file.type.startsWith('video/')) {
      return (
        <video 
          src={previewUrl}
          className="max-w-full max-h-[80vh]"
          controls
          autoPlay
          playsInline
        />
      );
    }
    
    if (file.type.startsWith('audio/')) {
      return (
        <audio 
          src={previewUrl}
          className="w-full"
          controls
        />
      );
    }

    if (file.type === 'application/pdf') {
      return (
        <iframe
          src={previewUrl}
          className="w-full h-[80vh]"
          title="PDF Preview"
        />
      );
    }

    // For other file types, show a download button
    return (
      <div className="text-center p-8">
        <div className="mb-4">
          {getFileIcon(file.type, file.name?.substring(file.name.lastIndexOf('.')))}
        </div>
        <p className="mb-4">{file.name}</p>
        <Button asChild>
          <a href={previewUrl} download={file.name}>Download File</a>
        </Button>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center">
      {getPreviewContent()}
    </div>
  );
};

const FileUploadArea = ({ onFileSelect }) => {
  const [showCamera, setShowCamera] = useState(false);
  const fileInputRef = useRef(null);

  return (
    <div className="w-full space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div 
          className="border-2 border-dashed rounded-lg p-4 bg-gray-50 hover:bg-gray-100 cursor-pointer"
          onClick={() => fileInputRef.current?.click()}
        >
          <div className="flex flex-col items-center justify-center">
            <Upload className="w-8 h-8 mb-2 text-gray-500" />
            <p className="text-sm text-gray-500 text-center">Upload files</p>
            <p className="text-xs text-gray-400 text-center mt-1">
              Supports images, videos, 3D models docs and more
            </p>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              multiple
              onChange={onFileSelect}
            />
          </div>
        </div>

        <div 
          className="border-2 border-dashed rounded-lg p-4 bg-gray-50 hover:bg-gray-100 cursor-pointer"
          onClick={() => setShowCamera(true)}
        >
          <div className="flex flex-col items-center justify-center">
            <Camera className="w-8 h-8 mb-2 text-gray-500" />
            <p className="text-sm text-gray-500 text-center">Take photo/video</p>
          </div>
        </div>
      </div>

      <Dialog open={showCamera} onOpenChange={setShowCamera}>
        <DialogContent className="max-w-4xl w-full p-0">
          <CameraCapture 
            onCapture={onFileSelect}
            onClose={() => setShowCamera(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const FileUploadScreen = ({ flowId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [fileItems, setFileItems] = useState([]);
  const [linkItems, setLinkItems] = useState([]);
  const [currentLink, setCurrentLink] = useState('');
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [localPreviews, setLocalPreviews] = useState({});
  const [editingNotes, setEditingNotes] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(new Set());
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  useEffect(() => {
    if (!projectId) return;

    const loadProject = async () => {
      setIsLoading(true);
      try {
        const currentProject = await ProjectService.getProjectById(projectId);
        if (currentProject.fileItems) {
          const parsedFileItems = JSON.parse(currentProject.fileItems).map(item => ({
            ...item,
            isUploading: false // Ensure existing files are marked as not uploading
          }));
          setFileItems(parsedFileItems);
        }
        if (currentProject.linkItems) {
          setLinkItems(JSON.parse(currentProject.linkItems));
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProject();
  }, [projectId]);

  useEffect(() => {
    const saveChanges = async () => {
      if (!projectId || isLoading || !unsavedChanges) return;
      
      try {
        const fileItemsToSave = fileItems.map(({ isUploading, ...item}) => ({
          ...item,
          id: item.id,
          name: item.name,
          type: item.type,
          size: item.size,
          thumbnail: item.thumbnail,
          s3Key: item.s3Key,
          s3Url: item.s3Url,
          notes: item.notes,
          section: item.section,
          createdAt: item.createdAt
        }));
        await ProjectService.updateProject(projectId, {
          fileItems: JSON.stringify(fileItemsToSave),
          linkItems: JSON.stringify(linkItems)
        });
        setUnsavedChanges(false);
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    };
  
  //   // Debounce the save operation to prevent too many DB calls
  //   const timeoutId = setTimeout(saveChanges, 1000);
  //   return () => clearTimeout(timeoutId);
  // }, [fileItems.length, linkItems.length, projectId, isLoading]);

    if (unsavedChanges) {
      saveChanges();
    }
  }, [projectId, isLoading, fileItems, linkItems, unsavedChanges]);


  const debouncedSaveChanges = useCallback(
    debounce(async () => {
      if (!projectId || isLoading || !unsavedChanges) return;
      
      try {
        const fileItemsToSave = fileItems.map(({ isUploading, ...rest }) => rest);
        await ProjectService.updateProject(projectId, {
          fileItems: JSON.stringify(fileItemsToSave),
          linkItems: JSON.stringify(linkItems)
        });
        setUnsavedChanges(false);
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    }, 2000),
    [projectId, isLoading, fileItems, linkItems]
  );

  useEffect(() => {
    if (unsavedChanges) {
      debouncedSaveChanges();
    }
    return () => {
      debouncedSaveChanges.cancel();
      if (unsavedChanges) {
        // Save changes immediately when component unmounts
        const fileItemsToSave = fileItems.map(({ isUploading, ...rest }) => rest);
        ProjectService.updateProject(projectId, {
          fileItems: JSON.stringify(fileItemsToSave),
          linkItems: JSON.stringify(linkItems)
        }).catch(console.error);
      }
    };
  }, [unsavedChanges, debouncedSaveChanges]);

  const generateThumbnail = async (file) => {
    return new Promise((resolve) => {
      // Helper to get file extension
      const getFileExtension = (filename) => {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
      };
  
      // Check if it's a 3D model or DXF file
      const modelExtensions = ['glb', 'gltf', 'obj', 'fbx', 'stl', '3ds', 'dxf'];
      const fileExtension = getFileExtension(file.name);
      if (modelExtensions.includes(fileExtension)) {
        // Create a canvas with an icon for 3D/DXF files
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 150;
        canvas.height = 150;
        
        // Draw background
        ctx.fillStyle = '#f3f4f6';
        ctx.fillRect(0, 0, 150, 150);
        
        // Draw file type text
        ctx.fillStyle = '#4b5563';
        ctx.font = '14px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(fileExtension.toUpperCase(), 75, 85);
        
        resolve(canvas.toDataURL('image/png'));
        return;
      }
  
      if (file.type.startsWith('image/')) {
        // Image thumbnail generation
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            const maxSize = 150;
            let width = img.width;
            let height = img.height;
            
            if (width > height) {
              if (width > maxSize) {
                height = height * (maxSize / width);
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width = width * (maxSize / height);
                height = maxSize;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL(file.type, 0.7));
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      } else if (file.type.startsWith('video/')) {
        // Video thumbnail generation
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        video.autoplay = false;
        video.muted = true;
        video.playsInline = true;

        const handleVideoLoad = () => {
          return new Promise((resolveLoad) => {
            video.addEventListener('loadeddata', () => resolveLoad(), { once: true });
            video.addEventListener('error', () => resolveLoad(), { once: true });
          });
        };

        video.onloadedmetadata = async () => {
          try {
            await handleVideoLoad();
            
            const maxSize = 150;
            let width = video.videoWidth;
            let height = video.videoHeight;
            
            if (width > height) {
              if (width > maxSize) {
                height = height * (maxSize / width);
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width = width * (maxSize / height);
                height = maxSize;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            
            video.currentTime = Math.min(1, video.duration);
            
            await new Promise(resolve => {
              video.onseeked = () => {
                ctx.drawImage(video, 0, 0, width, height);
                resolve();
              };
            });
            
            resolve(canvas.toDataURL('image/jpeg', 0.7));
          } finally {
            video.remove();
            canvas.remove();
          }
        };

        const reader = new FileReader();
        reader.onloadend = () => {
          video.src = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        // For other file types
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 150;
        canvas.height = 150;
        
        // Draw background
        ctx.fillStyle = '#f3f4f6';
        ctx.fillRect(0, 0, 150, 150);
        
        // Draw file type text
        ctx.fillStyle = '#4b5563';
        ctx.font = '14px Arial';
        ctx.textAlign = 'center';
        
        // Safely get the file type
        let fileType;
        if (file.type) {
          fileType = file.type.split('/')[1]?.toUpperCase() || 'FILE';
        } else {
          fileType = getFileExtension(file.name).toUpperCase() || 'FILE';
        }
        
        ctx.fillText(fileType, 75, 85);
        
        resolve(canvas.toDataURL('image/png'));
      }
    });
  };

  // const handleFileUpload = async (e) => {
  //   const newFiles = Array.from(e.target.files);
    
  //   for (const file of newFiles) {
  //     const fileId = Date.now() + Math.random();
      
  //     // Immediately add file item with loading state
  //     setFileItems(prev => [...prev, {
  //       id: fileId,
  //       name: file.name,
  //       type: file.type,
  //       size: file.size,
  //       isUploading: true,
  //       notes: '',
  //       section: 'general',
  //       createdAt: new Date().toISOString(),
  //     }]);
      
  //     try {
  //       const thumbnail = await generateThumbnail(file);
        
  //       // Upload to S3
  //       const { s3Key, s3Url } = await ProjectService.uploadFileToS3(
  //         file, 
  //         projectId,
  //         () => {}
  //       );
        
  //       // Update file item with complete data
  //       setFileItems(prev => prev.map(item => 
  //         item.id === fileId ? {
  //           ...item,
  //           isUploading: false,
  //           thumbnail,
  //           s3Key,
  //           s3Url
  //         } : item
  //       ));
        
  //       // Create preview URL
  //       const previewUrl = URL.createObjectURL(file);
  //       setLocalPreviews(prev => ({
  //         ...prev,
  //         [fileId]: {
  //           url: previewUrl,
  //           type: file.type
  //         }
  //       }));

  //     } catch (error) {
  //       console.error('Error processing file:', error);
  //       // Remove failed upload
  //       setFileItems(prev => prev.filter(item => item.id !== fileId));
  //     }
  //   }
  // };

  const handleFileUpload = async (e) => {
    const newFiles = Array.from(e.target.files);
    
    for (const file of newFiles) {
      const fileId = Date.now() + Math.random();
      
      try {
        // Generate thumbnail first
        const thumbnail = await generateThumbnail(file);
        
        // Add file item with loading state and thumbnail
        setFileItems(prev => [...prev, {
          id: fileId,
          name: file.name,
          type: file.type,
          size: file.size,
          isUploading: true,
          thumbnail, // Store thumbnail immediately
          notes: '',
          section: 'general',
          createdAt: new Date().toISOString(),
        }]);
        
        // Upload to S3
        const { s3Key, s3Url } = await ProjectService.uploadFileToS3(
          file, 
          projectId,
          () => {}
        );

        console.log("s3 key:", s3Key)
        
        // Update file item with complete data
        setFileItems(prev =>{
          const updatedItems = prev.map(item => 
          item.id === fileId ? {
            ...item,
            isUploading: false,
            thumbnail, // Ensure thumbnail is included
            s3Key,
            s3Url
          } : item
        )
        setUnsavedChanges(true); // Trigger save after updating s3Key
        return updatedItems; 
      });
        
        // Create preview URL for immediate use
        const previewUrl = URL.createObjectURL(file);
        setLocalPreviews(prev => ({
          ...prev,
          [fileId]: {
            url: previewUrl,
            type: file.type
          }
        }));

      } catch (error) {
        console.error('Error processing file:', error);
        // Remove failed upload
        setFileItems(prev => prev.filter(item => item.id !== fileId));
      }
    }
  };

  const removeFile = async (id) => {
    try {
      const fileItem = fileItems.find(item => item.id === id);
      
      if (fileItem?.s3Key) {
        await ProjectService.deleteFileFromS3(fileItem.s3Key);
      }
  
      if (localPreviews[id]?.url) {
        URL.revokeObjectURL(localPreviews[id].url);
        setLocalPreviews(prev => {
          const updated = { ...prev };
          delete updated[id];
          return updated;
        });
      }
      
      setFileItems(prev => prev.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  const getFileForPreview = async (fileItem) => {
    try {
      if (fileItem.s3Key) {
        return await ProjectService.downloadFileFromS3(fileItem.s3Key);
      }
      return null;
    } catch (error) {
      console.error('Error getting file for preview:', error);
      return null;
    }
  };

  const handleAddLink = () => {
    if (!currentLink.trim()) return;
    
    const newLinkItem = {
      id: Date.now() + Math.random(),
      url: currentLink,
      notes: '',
      section: 'general',
      createdAt: new Date().toISOString(),
    };

    setLinkItems(prev => [...prev, newLinkItem]);
    setCurrentLink('');
  };

  const removeLink = (id) => {
    setLinkItems(prev => prev.filter(item => item.id !== id));
  };

  const handleNoteEdit = (id, note, isLink = false) => {
    setEditingNotes(prev => ({
      ...prev,
      [id]: note
    }));
  };

  const handleNoteSave = (id, isLink = false) => {
    const note = editingNotes[id];
    if (note === undefined) return;

    const updateItems = isLink ? setLinkItems : setFileItems;
    updateItems(prev => prev.map(item => 
      item.id === id ? { ...item, notes: note } : item
    ));
    setUnsavedChanges(true);
    
    // Clear editing state
    setEditingNotes(prev => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
  };

  const handleNoteCancel = (id) => {
    setEditingNotes(prev => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
  };

  const handleComplete = async () => {
    try {
      // Just mark the flow as completed
      await ProjectService.addCompletedFlow(projectId, flowId);
      navigate(`/new-kitchen-project/${projectId}`);
    } catch (error) {
      console.error("Error completing section:", error);
    }
  };

  // const openPreview = async (fileItem) => {
  //   let previewUrl = localPreviews[fileItem.id]?.url;
    
  //   if (!previewUrl) {
  //     setIsPreviewLoading(true);
  //     setSelectedPreview({
  //       type: fileItem.type,
  //       name: fileItem.name,
  //       s3Key: fileItem.s3Key
  //     });
  //     setIsPreviewOpen(true);

  //     try {
  //       const file = await getFileForPreview(fileItem);
  //       if (file) {
  //         previewUrl = URL.createObjectURL(file);
  //         setLocalPreviews(prev => ({
  //           ...prev,
  //           [fileItem.id]: {
  //             url: previewUrl,
  //             type: file.type
  //           }
  //         }));
  //         setSelectedPreview(prev => ({
  //           ...prev,
  //           url: previewUrl
  //         }));
  //       }
  //     } catch (error) {
  //       console.error('Error loading preview:', error);
  //     } finally {
  //       setIsPreviewLoading(false);
  //     }
  //   } else {
  //     setSelectedPreview({ 
  //       type: fileItem.type,
  //       url: previewUrl,
  //       name: fileItem.name,
  //       s3Key: fileItem.s3Key 
  //     });
  //     setIsPreviewOpen(true);
  //   }
  // };
  const openPreview = async (fileItem) => {
    let previewUrl = localPreviews[fileItem.id]?.url;
    
    if (!previewUrl) {
      setIsPreviewLoading(true);
      setSelectedPreview({
        type: fileItem.type,
        name: fileItem.name,
        s3Key: fileItem.s3Key,
        thumbnail: fileItem.thumbnail // Include thumbnail in preview data
      });
      setIsPreviewOpen(true);

      try {
        const file = await getFileForPreview(fileItem);
        if (file) {
          previewUrl = URL.createObjectURL(file);
          setLocalPreviews(prev => ({
            ...prev,
            [fileItem.id]: {
              url: previewUrl,
              type: file.type
            }
          }));
          setSelectedPreview(prev => ({
            ...prev,
            url: previewUrl
          }));
        }
      } catch (error) {
        console.error('Error loading preview:', error);
      } finally {
        setIsPreviewLoading(false);
      }
    } else {
      setSelectedPreview({ 
        type: fileItem.type,
        url: previewUrl,
        name: fileItem.name,
        s3Key: fileItem.s3Key,
        thumbnail: fileItem.thumbnail // Include thumbnail in preview data
      });
      setIsPreviewOpen(true);
    }
  };

  const renderFilePreview = (fileItem) => {
    if (fileItem.isUploading) {
      return (
        <div className="w-24 h-24 flex-shrink-0 rounded-lg overflow-hidden bg-gray-100 flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600"></div>
          <span className="text-sm text-gray-600 mt-2">Uploading...</span>
        </div>
      );
    }

    return (
      <div 
        className="w-24 h-24 flex-shrink-0 rounded-lg overflow-hidden bg-gray-100 group relative cursor-pointer"
        onClick={() => openPreview(fileItem)}
      >
        {fileItem.thumbnail ? (
          <>
            <img 
              src={fileItem.thumbnail} 
              alt={fileItem.name} 
              className="w-full h-full object-cover"
            />
            {fileItem.type.startsWith('video/') && (
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <Play className="w-8 h-8 text-white" />
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            {/* {getFileIcon(fileItem.type)} */}
            {getFileIcon(fileItem.type, fileItem.name?.substring(fileItem.name.lastIndexOf('.')))}
          </div>
        )}
        
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center">
          <Maximize2 className="text-white opacity-0 group-hover:opacity-100 w-6 h-6" />
        </div>
      </div>
    );
  };

  const renderFileItem = (item) => (
    <div key={item.id} className="p-4 border rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <span className="font-medium">{item.name}</span>
        {!item.isUploading && (
          <Button
            variant="destructive"
            size="sm"
            onClick={() => removeFile(item.id)}
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        )}
      </div>
      <div className="flex gap-4">
        {renderFilePreview(item)}
        <div className="flex-1">
          {!item.isUploading ? (
            <>
              <Textarea
                placeholder="Add notes or description..."
                value={editingNotes[item.id] !== undefined ? editingNotes[item.id] : item.notes}
                onChange={(e) => handleNoteEdit(item.id, e.target.value)}
                className="flex-1 mb-2"
              />
              {editingNotes[item.id] !== undefined && (
                <div className="flex justify-end gap-2">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => handleNoteCancel(item.id)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => handleNoteSave(item.id)}
                  >
                    Save Notes
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="h-[120px] flex items-center justify-center text-gray-500">
              Processing file...
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderLinkItem = (item) => (
    <div key={item.id} className="p-4 border rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <div className="flex-1 break-all">
          <a 
            href={item.url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 hover:underline"
          >
            {item.url}
          </a>
        </div>
        <div className="flex gap-2">
          <Button
            size="sm"
            variant="outline"
            onClick={() => window.open(item.url, '_blank')}
          >
            Open
          </Button>
          <Button
            variant="destructive"
            size="sm"
            onClick={() => removeLink(item.id)}
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
      </div>
      <div>
        <Textarea
          placeholder="Add notes or description..."
          value={editingNotes[item.id] !== undefined ? editingNotes[item.id] : item.notes}
          onChange={(e) => handleNoteEdit(item.id, e.target.value, true)}
          className="mb-2"
        />
        {editingNotes[item.id] !== undefined && (
          <div className="flex justify-end gap-2">
            <Button
              size="sm"
              variant="outline"
              onClick={() => handleNoteCancel(item.id)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={() => handleNoteSave(item.id, true)}
            >
              Save Notes
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const renderUploadArea = () => {
    return <FileUploadArea onFileSelect={handleFileUpload} />;
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <ProjectHeader showBackButton={true} />
      <main className="pt-20 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card className="h-full">
            <CardHeader>
              <CardTitle>Floor plans, walkthroughs, inspirations</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {renderUploadArea()}
              {fileItems.map(renderFileItem)}
            </CardContent>
          </Card>
  
          <Card className="h-full">
            <CardHeader>
              <CardTitle>Links</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex gap-2">
                <Input 
                  type="url" 
                  placeholder="Enter URL"
                  value={currentLink}
                  onChange={(e) => setCurrentLink(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddLink()}
                />
                <Button onClick={handleAddLink}>
                  <Plus className="w-4 h-4" />
                </Button>
              </div>
              {linkItems.map(renderLinkItem)}
            </CardContent>
          </Card>
        </div>
  
        <div className="text-center">
          <Button onClick={handleComplete}>Complete This Section</Button>
        </div>

        <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
        <DialogContent className="max-w-4xl w-full p-0">
        <DialogHeader>
            <DialogTitle className="sr-only">File Preview</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="p-6">
            {isPreviewLoading ? (
              <div className="flex flex-col items-center justify-center h-[400px]">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                <span className="mt-2">Loading preview...</span>
              </div>
            ) : (
              <FilePreview file={selectedPreview} />
            )}
          </div>
        </DialogContent>
      </Dialog>
      </main>
    </div>
  );
};

export default FileUploadScreen;