import React, { useState, useEffect, useRef } from 'react';
import { drawRoom, getWallCoordinates, highlightWall, drawKitchenItem } from '@/utils/roomDrawingFunctions';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Card } from '@/components/ui/card';
import { Button } from "@/components/ui/button";
import { Trash2, ChevronRight, Plus, RotateCw, MousePointerClick } from 'lucide-react';
import { ScrollArea } from "@/components/ui/scroll-area";
import {findEmptySpace, findInnerSpace} from '@/utils/FloorPlanUtils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { applianceData } from '@/utils/appliance-data';

const BASE_CABINET_DEPTH = 24; // inches
const BASE_CABINET_HEIGHT = 36; //inches
// const WALL_CABINET_DEPTH = 12; // inches
const MIN_CANVAS_WIDTH = 320; // Minimum width in pixels, adjust as needed
// const MIN_ISLAND_DISTANCE = 42; // Minimum distance from island to other items (in inches)

const applianceIcons = Object.fromEntries(
  applianceData.map(({ id, icon }) => [id, icon])
);

const KitchenLayoutEditor = ({onSave, initialKitchenLayout, floorPlanData, initialAppliancesData}) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 400, height: 300 });
  const [scale, setScale] = useState(1);
  const [selectedWall, setSelectedWall] = useState(null);
  const [wallCoordinates, setWallCoordinates] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [kitchenItems, setKitchenItems] = useState(initialKitchenLayout || []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const [ startX, setStartX] = useState(1);
  const [ startY, setStartY] = useState(1);
  const [draggedHandle, setDraggedHandle] = useState(null);
  const [isIslandDialogOpen, setIsIslandDialogOpen] = useState(false);
  const [isDraggingItem, setisDraggingItem] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [appliances, setAppliances] = useState([]);

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = window.innerHeight * 0.6; // 60% of viewport height
        const aspectRatio = 4 / 3; // Maintain a 4:3 aspect ratio

        let newWidth, newHeight;
        newWidth = Math.max(MIN_CANVAS_WIDTH, Math.min(containerWidth, 800));
        newHeight = newWidth / aspectRatio;

        // If the height exceeds the container, adjust both dimensions
        if (newHeight > containerHeight) {
          newHeight = Math.min(containerHeight, 600);
          newWidth = newHeight * aspectRatio;
        }

        setCanvasSize({ width: newWidth, height: newHeight });

        // Calculate scale based on floor plan dimensions
        if (floorPlanData && floorPlanData.dimensions) {
          const scaleX = (newWidth - 80) / floorPlanData.dimensions.width; // 40px padding on each side
          const scaleY = (newHeight - 80) / floorPlanData.dimensions.depth; // 40px padding on each side
          setScale(Math.min(scaleX, scaleY));
        }
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [floorPlanData]);

  useEffect(() => {
    if (initialAppliancesData) {
      setAppliances(initialAppliancesData);
      console.log("initialAppliancesData:", initialAppliancesData)
    }
    else{
      console.log("no initialAppliancesData")
    }
  }, [initialAppliancesData]);

  useEffect(() => {
    drawFloorPlan();
  }, [floorPlanData, kitchenItems, scale, canvasSize, selectedWall, selectedItems, currentSelectedIndex]);

  const drawFloorPlan = async () => {
    const canvas = canvasRef.current;
    if (!canvas || !floorPlanData) return;
  
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    const { roomType, walls, wallElements, unit } = floorPlanData;
    const startX = (canvas.width - floorPlanData.dimensions.width * scale) / 2;
    const startY = (canvas.height - floorPlanData.dimensions.depth * scale) / 2 ;
    setStartX(startX);
    setStartY(startY);

    // Draw grid
    ctx.strokeStyle = '#E5E5E5'; // Light gray color
    ctx.lineWidth = 0.5;
    
    // Calculate grid size (1 inch in scaled pixels)
    const gridSize = 12 * scale;
    
    // Calculate grid offset based on startX and startY
    const offsetX = (startX * scale) % gridSize;
    const offsetY = (startY * scale) % gridSize;
    
    // Draw vertical lines
    for (let x = offsetX; x < canvas.width; x += gridSize) {
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.stroke();
    }
    
    // Draw horizontal lines
    for (let y = offsetY; y < canvas.height; y += gridSize) {
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(canvas.width, y);
      ctx.stroke();
    }

    // Reset line width and style for room drawing
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';

    const coordinates = getWallCoordinates(walls, roomType);
    setWallCoordinates(coordinates);

    await drawRoom(ctx, walls, coordinates, wallElements, startX, startY, scale, unit);

    if (selectedWall && coordinates[selectedWall]) {
      highlightWall(ctx, coordinates[selectedWall], startX, startY, scale);
    }  

    await drawKitchenItem(ctx, kitchenItems, startX, startY, scale, selectedItems, currentSelectedIndex)
  };

  const rotateSelectedItem = () => {
    if (selectedItems.length > 0) {
      const itemIndex = selectedItems[currentSelectedIndex];
      const item = kitchenItems[itemIndex];
      
      if (item.type === "island" || item.type === "appliance") {
        setKitchenItems(prevItems => {
          const newItems = [...prevItems];
          const centerX = (item.x1 + item.x3) / 2;
          const centerY = (item.y1 + item.y3) / 2;
          
          // Rotate by 45 degrees (π/4 radians)
          const newAngle = item.angle + Math.PI / 4;
          
          // Update direction based on new angle
          const newDirX = Math.cos(newAngle);
          const newDirY = Math.sin(newAngle);
          
          // Calculate new corner positions
          const halfWidth = item.width / 2;
          const halfDepth = item.depth / 2;
          
          newItems[itemIndex] = {
            ...item,
            x1: centerX - halfWidth * newDirX + halfDepth * newDirY,
            y1: centerY - halfWidth * newDirY - halfDepth * newDirX,
            x2: centerX + halfWidth * newDirX + halfDepth * newDirY,
            y2: centerY + halfWidth * newDirY - halfDepth * newDirX,
            x3: centerX + halfWidth * newDirX - halfDepth * newDirY,
            y3: centerY + halfWidth * newDirY + halfDepth * newDirX,
            x4: centerX - halfWidth * newDirX - halfDepth * newDirY,
            y4: centerY - halfWidth * newDirY + halfDepth * newDirX,
            angle: newAngle,
            direction: { x: newDirX, y: newDirY }
          };
          
          return newItems;
        });
      }
    }
  };

  const findClickedItems = (x, y) => {
    return kitchenItems.reduce((acc, item, index) => {
      const minX = Math.min(item.x1, item.x2, item.x3, item.x4);
      const maxX = Math.max(item.x1, item.x2, item.x3, item.x4);
      const minY = Math.min(item.y1, item.y2, item.y3, item.y4);
      const maxY = Math.max(item.y1, item.y2, item.y3, item.y4);
      
      if (x >= (minX* scale + startX) && x <= (maxX * scale + startX) && y >= (minY * scale + startY) && y <= (maxY * scale + startY)) {
        acc.push(index);
      }
      return acc;
    }, []);
  };

  const cycleSelectedElement = () => {
    if (selectedItems.length > 1) {
      setCurrentSelectedIndex((prevIndex) => (prevIndex + 1) % selectedItems.length);
    }
  };

  const deleteSelectedElement = () => {
    if (selectedItems.length > 0) {
      const indexToDelete = selectedItems[currentSelectedIndex];
      setKitchenItems(prev => prev.filter((_, index) => index !== indexToDelete));
      setSelectedItems(prev => prev.filter(index => index !== indexToDelete).map(index => index > indexToDelete ? index - 1 : index));
      if (selectedItems.length === 1) {
        setSelectedItems([]);
      } else {
        setCurrentSelectedIndex(0);
      }
    }
  };

  const findClickedWall = (x, y) => {
    for (const [wall, coords] of Object.entries(wallCoordinates)) {
      if (isPointNearLine(x, y, coords.x1 * scale + startX, coords.y1 * scale + startY, coords.x2 * scale + startX, coords.y2 * scale + startY)) {
        return wall;
      }
    }
    return null;
  };

  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const x = (clientX - rect.left) ;
    const y = (clientY - rect.top) ;

    const clickedItems = findClickedItems(x, y);
    if (clickedItems.length > 0) {
      setSelectedItems(clickedItems);
      setCurrentSelectedIndex(0);
      setSelectedWall(null);
    } else {
      const clickedWall = findClickedWall(x, y);
      setSelectedWall(clickedWall);
      setSelectedItems([]);
      if (clickedWall) {
        setIsDialogOpen(true);
      }
    }
  };

  const handleCanvasMouseDown = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const x = (clientX - rect.left);
    const y = (clientY - rect.top);
    setDragStart({ x, y });
  
    if (selectedItems.length > 0) {
      const item = kitchenItems[selectedItems[currentSelectedIndex]];
      const handleSize = 15 / scale;
  
      if (item.type === "appliance") {
        if (isPointInsideItem(x, y, item)) {
          setisDraggingItem(true);
          return;
        }
      }

      if (item.type === "island") {
        // Check all handles and set appropriate handle type
        const leftMid = Math.hypot(x - ((item.x1 + item.x4) * scale/2 + startX), y - ((item.y1 + item.y4) * scale/2 + startY));
        const rightMid = Math.hypot(x - ((item.x2 + item.x3) * scale/2 + startX), y - ((item.y2 + item.y3) * scale/2 + startY));
        const topMid = Math.hypot(x - ((item.x1 + item.x2) * scale/2 + startX), y - ((item.y1 + item.y2) * scale/2 + startY));
        const bottomMid = Math.hypot(x - ((item.x4 + item.x3) * scale/2 + startX), y - ((item.y4 + item.y3) * scale/2 + startY));

        if (leftMid < handleSize) {
            setDraggedHandle('start');
        } else if (rightMid < handleSize) {
            setDraggedHandle('end');
        } else if (topMid < handleSize) {
            setDraggedHandle('top');
        } else if (bottomMid < handleSize) {
            setDraggedHandle('bottom');
        } else if (isPointInsideItem(x, y, item)) {
            setisDraggingItem(true);
        }
      } else {
        // Handle other item types (base cabinets, etc.)
        if (Math.hypot(x - ((item.x1 + item.x4) * scale /2  + startX), y - ((item.y1 + item.y4) * scale/2  + startY)) < handleSize) {
          setDraggedHandle('start');
        } else if (Math.hypot(x - ((item.x2 + item.x3) * scale/2  + startX), y - ((item.y2 + item.y3) * scale/2  + startY)) < handleSize) {
          setDraggedHandle('end');
        }
      }
    }
  };

  const handleCanvasMouseMove = (event) => {
    if (selectedItems.length === 0) return;
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const x = (clientX - rect.left);
    const y = (clientY - rect.top);
    
    setKitchenItems(prevItems => {
      const newItems = [...prevItems];
      const elementIndex = selectedItems[currentSelectedIndex];
      const item = newItems[elementIndex];
  
      if (isDraggingItem) {
        // Move the entire island
        const dx = (x - dragStart.x) / scale;
        const dy = (y - dragStart.y) / scale;
        item.x1 += dx;
        item.y1 += dy;
        item.x2 += dx;
        item.y2 += dy;
        item.x3 += dx;
        item.y3 += dy;
        item.x4 += dx;
        item.y4 += dy;
        setDragStart({ x, y });
        
      } else {
        // Handle resizing
        if (item.type === "island") {
          handleIslandResize(item, x, y);
        } else {
          handleBaseCabinetResize(item, x, y);
        }
      }
      return newItems;
    });
  
    drawFloorPlan();
  };

  const handleClickTouchEnd = () => {
    setisDraggingItem(false);
    setDraggedHandle(null);
  };

  const isPointInsideItem = (x, y, item) => {
    const minX = Math.min(item.x1  , item.x2  , item.x3  , item.x4  );
    const maxX = Math.max(item.x1  , item.x2  , item.x3  , item.x4  );
    const minY = Math.min(item.y1  , item.y2  , item.y3  , item.y4  );
    const maxY = Math.max(item.y1  , item.y2  , item.y3  , item.y4  );
    return x >= (minX * scale + startX) && x <= (maxX * scale + startX) && y >= (minY * scale + startY) && y <= (maxY * scale + startY);
  };

  const isPointNearLine = (px, py, x1, y1, x2, y2, threshold = 10) => {
    const A = px - x1;
    const B = py - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = (len_sq !== 0) ? dot / len_sq : -1;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    const dx = px - xx;
    const dy = py - yy;

    return Math.sqrt(dx * dx + dy * dy) <= threshold;
  };

  const projectPointOnLine = (px, py, x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const t = ((px - x1) * dx + (py - y1 ) * dy) / (dx * dx + dy * dy);
    return {
      x: x1 + t * dx,
      y: y1 + t * dy
    };
  };


  const handleIslandResize = (item, px, py) => {
    const x = (px - startX) / scale;
    const y = (py - startY) / scale;

    switch (draggedHandle) {
      case 'start':
        const newStart = projectPointOnLine(x, y, item.x1, item.y1, item.x2, item.y2);
        item.x1 = newStart.x;
        item.y1 = newStart.y;
        item.x4 = newStart.x - item.direction.y * item.depth;
        item.y4 = newStart.y + item.direction.x * item.depth;
        break;
      case 'end':
        const newEnd = projectPointOnLine(x, y, item.x1, item.y1, item.x2, item.y2);
        item.x2 = newEnd.x;
        item.y2 = newEnd.y;
        item.x3 = newEnd.x - item.direction.y * item.depth;
        item.y3 = newEnd.y + item.direction.x * item.depth;
        break;
      case 'top':
        const newTop = projectPointOnLine(x, y, item.x1, item.y1, item.x4, item.y4);
        item.x1 = newTop.x;
        item.y1 = newTop.y;
        item.x2 = newTop.x + item.direction.x * item.width;
        item.y2 = newTop.y - item.direction.y * item.width;
        break;
      case 'bottom':
        const newBottom = projectPointOnLine(x, y, item.x4, item.y4, item.x1, item.y1);
        item.x3 = newBottom.x + item.direction.x * item.width;
        item.y3 = newBottom.y + item.direction.y * item.width;
        item.x4 = newBottom.x;
        item.y4 = newBottom.y;
        break;
    }
    item.width = Math.hypot(item.x2 - item.x1, item.y2 - item.y1);
    item.depth = Math.hypot(item.x3 - item.x2, item.y3 - item.y2);
  };

  const handleBaseCabinetResize = (item, px, py) => {
    const x = (px - startX) / scale;
    const y = (py - startY) / scale;
    if (draggedHandle === 'start') {
      const newStart = projectPointOnLine(x, y, item.x1, item.y1, item.x2, item.y2);
      item.x1 = newStart.x;
      item.y1 = newStart.y;
      item.x4 = newStart.x - item.direction.y * item.depth;
      item.y4 = newStart.y + item.direction.x * item.depth;
    } else if (draggedHandle === 'end') {
      const newEnd = projectPointOnLine(x, y, item.x1, item.y1, item.x2, item.y2);
      item.x2 = newEnd.x;
      item.y2 = newEnd.y;
      item.x3 = newEnd.x - item.direction.y * item.depth;
      item.y3 = newEnd.y + item.direction.x * item.depth;
    }
    item.width = Math.hypot(item.x2 - item.x1, item.y2 - item.y1);
  };

  const addBaseCabinets = () => {
    if (!selectedWall || !wallCoordinates[selectedWall]) return;

    const { x1, y1, x2, y2 } = wallCoordinates[selectedWall];
    const wallElements = floorPlanData.wallElements[selectedWall] || [];
    const doors = wallElements.filter(element => element.type === 'door');
    const openings = wallElements.filter(element => element.type === 'opening');
    
    let cabinetSections = [];

    // Calculate wall direction and length
    const dx = x2 - x1;
    const dy = y2 - y1;
    const wallLength = Math.sqrt(dx * dx + dy * dy);
    const wallAngle = Math.atan2(dy, dx);

    // Normalize direction vector
    const dirX = dx / wallLength;
    const dirY = dy / wallLength;

    // Perpendicular direction for cabinet depth
    const perpX = -dirY;
    const perpY = dirX;

    const doorAreas = [
      ...doors.map(door => ({
        start: door.x,
        end: door.x + door.width,
        type: 'door'
      })),
      ...openings.map(opening => ({
        start: opening.x,
        end: opening.x + opening.width,
        type: 'opening'
      }))
    ];

    cabinetSections = getCabinetSections(0, wallLength, wallLength, doorAreas, true);

    cabinetSections.forEach(section => {
      const sectionStartX = x1 + dirX * section.start;
      const sectionStartY = y1 + dirY * section.start;
      const sectionEndX = x1 + dirX * section.end;
      const sectionEndY = y1 + dirY * section.end;

      const newCabinet = {
        type: "baseCabinets",
        wall: selectedWall,
        x1: sectionStartX,
        y1: sectionStartY,
        z1: 0,
        x2: sectionEndX,
        y2: sectionEndY,
        x3: sectionEndX + perpX * BASE_CABINET_DEPTH,
        y3: sectionEndY + perpY * BASE_CABINET_DEPTH,
        x4: sectionStartX + perpX * BASE_CABINET_DEPTH,
        y4: sectionStartY + perpY * BASE_CABINET_DEPTH,
        depth: BASE_CABINET_DEPTH,
        width: section.end - section.start,
        height: BASE_CABINET_HEIGHT,
        direction: { x: dirX, y: dirY },
        angle: wallAngle
      };
      setKitchenItems(prev => [...prev, newCabinet]);
    });

    setIsDialogOpen(false);
  };

  const addIsland = () => {
    const centerX = (canvasSize.width / 2 - startX) / scale;
    const centerY = (canvasSize.height / 2 - startY) / scale;
    const islandWidth = 60; // Default width in inches
    const islandDepth = 30; // Default depth in inches
    const emptySpace = findEmptySpace(floorPlanData, kitchenItems);
    console.log("Largest empty space:", emptySpace);
    const innerSpace = findInnerSpace(floorPlanData, kitchenItems);
    console.log("Largest inner space (42 inches away from base cabinets):", innerSpace);

    const x1 = centerX - islandWidth / 2;
    const y1 = centerY - islandDepth / 2;
    const x2 = centerX + islandWidth / 2;
    const y2 = centerY - islandDepth / 2;

    // Calculate direction and length
    const dx = x2 - x1;
    const dy = y2 - y1;
    const Length = Math.sqrt(dx * dx + dy * dy);
    const Angle = Math.atan2(dy, dx);

    // Normalize direction vector
    const dirX = dx / Length;
    const dirY = dy / Length;

    const newIsland = {
      type: "island",
      wall: "None",
      x1: x1,
      y1: y1,
      z1: 0,
      x2: x2,
      y2: y2,
      x3: centerX + islandWidth / 2,
      y3: centerY + islandDepth / 2,
      x4: centerX - islandWidth / 2,
      y4: centerY + islandDepth / 2,
      width: islandWidth,
      depth: islandDepth,
      height: BASE_CABINET_HEIGHT,
      direction: { x: dirX, y: dirY },
      angle: Angle
    };

    setKitchenItems(prev => [...prev, newIsland]);
    setIsIslandDialogOpen(false);
  };

  const addAppliance = (appliance) => {
    const centerX = (canvasSize.width / 2 - startX) / scale;
    const centerY = (canvasSize.height / 2 - startY) / scale;
    
    // Calculate initial position and angle
    const width = appliance.dimensions.width;
    const depth = appliance.dimensions.depth;
    const x1 = centerX - width / 2;
    const y1 = centerY - depth / 2;
    const x2 = centerX + width / 2;
    const y2 = centerY - depth / 2;
  
    // Calculate direction and length
    const dx = x2 - x1;
    const dy = y2 - y1;
    const length = Math.sqrt(dx * dx + dy * dy);
    const angle = Math.atan2(dy, dx);
  
    // Normalize direction vector
    const dirX = dx / length;
    const dirY = dy / length;
  
    const newAppliance = {
      type: "appliance",
      applianceType: appliance.id,
      name: appliance.name,
      x1: x1,
      y1: y1,
      z1: 0,
      x2: x2,
      y2: y2,
      x3: centerX + width / 2,
      y3: centerY + depth / 2,
      x4: centerX - width / 2,
      y4: centerY + depth / 2,
      width: width,
      depth: depth,
      height: appliance.dimensions.height,
      direction: { x: dirX, y: dirY },
      angle: angle,
      dimensions: appliance.dimensions
    };
  
    setKitchenItems(prev => [...prev, newAppliance]);
  };

  const getCabinetSections = (wallStart, wallEnd, wallLength, doorAreas, direction) => {
    let sections = [];
    let currentPos = wallStart;

    if (direction) {doorAreas.sort((a, b) => a.start - b.start)}
    else{doorAreas.sort((a, b) => b.start - a.start)};

    doorAreas.forEach(door => {
      if (direction? door.start > currentPos : door.start < currentPos) {
        sections.push({
          start: currentPos,
          end: door.start
        });
      }
      currentPos = door.end;
    });

    if (direction? currentPos < wallEnd : currentPos > wallEnd) {
      sections.push({
        start: currentPos,
        end: wallEnd
      });
    }

    return sections;
  };

  useEffect(() => {
    onSave(kitchenItems);
  }, [kitchenItems]);

  return (
    <div className="w-full flex flex-col items-center" ref={containerRef}>
      {/* Toolbar - always on top */}
       <div className="w-full mb-4">
        <div className="flex flex-row gap-2 p-2 overflow-x-auto justify-center">
          <TooltipProvider>
            {appliances.map((appliance, index) => {
              const Icon = applianceIcons[appliance.id];
              return (
                <Tooltip key={`${appliance.id}-${index}`}>
                  <TooltipTrigger asChild>
                    <Button
                      variant="default"
                      size="icon"
                      onClick={() => addAppliance(appliance)}
                      className="w-14 h-14 shrink-0 rounded-lg bg-white border-2 border-green-500 hover:bg-green-50 text-green-600 shadow-lg hover:shadow-xl transition-all duration-200 ease-in-out transform hover:scale-105 flex flex-col items-center justify-center gap-1 p-1"
                    >
                      {Icon ? (
                        <Icon className="w-6 h-6" />
                      ) : (
                        appliance.name.slice(0, 3)
                      )}
                      <span className="text-[10px] font-medium leading-none">
                        {appliance.name.split(' ')[0]}
                      </span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Click to add {appliance.name}</p>
                    <p className="text-xs text-gray-500">
                      {`W: ${appliance.dimensions.width}" D: ${appliance.dimensions.depth}" H: ${appliance.dimensions.height}"`}
                    </p>
                  </TooltipContent>
                </Tooltip>
              );
            })}
            
            {/* Island button */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="default"
                  size="icon"
                  onClick={() => setIsIslandDialogOpen(true)}
                  className="w-14 h-14 shrink-0 rounded-lg bg-white border-2 border-blue-500 hover:bg-blue-50 text-blue-600 shadow-lg hover:shadow-xl transition-all duration-200 ease-in-out transform hover:scale-105 flex flex-col items-center justify-center gap-1 p-1"
                >
                  <Plus className="h-6 w-6" />
                  <span className="text-[10px] font-medium leading-none">
                    Island
                  </span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Click to add a kitchen island</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
  
      {/* Main canvas area */}
      <div className="w-full flex flex-col items-center">
        {kitchenItems.length === 0 && (
          <div className="text-center mb-4 p-2 bg-green-100 rounded-md"
            style={{ width: `${canvasSize.width}px`, maxWidth: '100%' }}>
            <p className="text-blue-800 font-semibold"> <MousePointerClick /> Click on a each wall to set cabinet area </p>
          </div>
        )}
        <ScrollArea className="h-[calc(100vh-250px)]">
          <div className="relative">
            <Card>
              <canvas
                ref={canvasRef}
                width={canvasSize.width}
                height={canvasSize.height}
                className="border cursor-pointer"
                onClick={handleCanvasClick}
                onMouseDown={handleCanvasMouseDown}
                onMouseMove={handleCanvasMouseMove}
                onMouseUp={handleClickTouchEnd}
                onMouseLeave={handleClickTouchEnd}
                onTouchStart={handleCanvasMouseDown}
                onTouchMove={handleCanvasMouseMove}
                onTouchEnd={handleClickTouchEnd}
                style={{ 
                  width: '100%', 
                  height: 'auto',
                  maxWidth: `${canvasSize.width}px`, 
                  maxHeight: `${canvasSize.height}px`,
                  touchAction: 'none'
                }}
              />
              {selectedItems.length > 0 && (
                <div className="absolute top-2 right-2 flex flex-col gap-2">
                  <Button
                    onClick={deleteSelectedElement}
                    className="bg-red-500 hover:bg-red-600 text-white p-2 rounded"
                  >
                    <Trash2 size={20} />
                  </Button>
                  {selectedItems.length > 1 && (
                    <Button
                      onClick={cycleSelectedElement}
                      className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
                    >
                      <ChevronRight size={20} />
                    </Button>
                  )}
                  {(kitchenItems[selectedItems[currentSelectedIndex]]?.type === "island" || 
                    kitchenItems[selectedItems[currentSelectedIndex]]?.type === "appliance") && (
                    <Button
                      onClick={rotateSelectedItem}
                      className="bg-green-500 hover:bg-green-600 text-white p-2 rounded"
                    >
                      <RotateCw size={20} />
                    </Button>
                  )}
                </div>
              )}
            </Card>
          </div>
        </ScrollArea>
      </div>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add</DialogTitle>
            </DialogHeader>
            <Button onClick={addBaseCabinets}>Base Area Cabinets</Button>
          </DialogContent>
        </Dialog>
        <Dialog open={isIslandDialogOpen} onOpenChange={setIsIslandDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add</DialogTitle>
          </DialogHeader>
          <Button onClick={addIsland}>Add Island</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default KitchenLayoutEditor;