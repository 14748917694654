import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAuthState } from '@/hooks/AuthContext';

const WelcomePage = () => {
    const navigate = useNavigate();
    const { login, register } = useKindeAuth();
    const { isAuthenticated, isLoading } = useAuthState(); // Use the centralized auth state

    // Function to get the base URL based on the environment
    const getBaseUrl = () => {
        const hostname = window.location.hostname;
        switch (true) {
            case hostname === 'localhost' || hostname === '127.0.0.1':
                return 'http://localhost:3000';
            case hostname.includes('amplifyapp.com'):
                return 'https://main.d35u21yvpilhs2.amplifyapp.com';
            case hostname.includes('gnibox.com'):
                return 'https://gnibox.com';
            case hostname.includes('dev.gnibox.com'):
                return 'https://dev.gnibox.com';
            default:
                console.warn('Unknown environment, using localhost');
                return 'http://localhost:3000';
        }
    };

    // Construct the Kinde configuration
    const baseUrl = getBaseUrl();

    // Debug component to check configuration
    const ConfigChecker = () => {
        React.useEffect(() => {
            console.log('Base URL:', baseUrl);
        }, []);

        return null;
    };

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            navigate('/projects');
        }
    }, [isAuthenticated, isLoading, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Only render the welcome page content if not authenticated
    if (!isAuthenticated) {
        return (
            <div className="min-h-screen flex flex-col">
                {/* Header - Fixed */}
                <header className="fixed top-0 left-0 right-0 p-4 flex justify-between items-center bg-white z-50 shadow-md">
                    <div className="text-2xl font-bold">LOGO</div>
                    <div className="space-x-2 sm:space-x-4">
                        <Button onClick={login} variant="outline" className="text-sm sm:text-base">
                            Log in
                        </Button>
                        <Button
                            onClick={register}
                            className="bg-black text-white hover:bg-gray-800 text-sm sm:text-base"
                        >
                            Get started for free
                        </Button>
                    </div>
                </header>

                {/* Main content */}
                <main className="flex-grow flex flex-col lg:flex-row mt-16">
                    {/* Content */}
                    <div className="w-full lg:w-1/2 p-6 sm:p-12 flex flex-col justify-center bg-white lg:bg-opacity-100 mt-[40vh] lg:mt-0 relative z-10 lg:min-h-screen">
                        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4">
                            Designing for Humans,
                            <br />
                            Not Just Walls
                        </h1>
                        <p className="text-lg sm:text-xl mb-8">Welcome to your personalized kitchen design journey</p>
                        <div className="space-y-4 mb-8">
                            <Button onClick={login} className="w-full bg-blue-500 hover:bg-blue-600 text-white">
                                Log In
                            </Button>
                            <Button onClick={register} className="w-full bg-green-500 hover:bg-green-600 text-white">
                                Sign Up
                            </Button>
                        </div>
                        <div className="bg-gray-100 p-4 sm:p-6 rounded-lg">
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4">Here's what to expect:</h2>
                            <ul className="space-y-2 text-sm sm:text-base">
                                <li>✓ We'll get to know you and your lifestyle</li>
                                <li>✓ Explore your design preferences and needs</li>
                                <li>✓ Create a custom kitchen plan tailored just for you</li>
                                <li>✓ Visualize your dream kitchen with 3D modeling</li>
                                <li>✓ Get expert advice every step of the way</li>
                            </ul>
                        </div>
                    </div>

                    {/* Image container */}
                    <div className="w-full lg:w-1/2 h-[50vh] lg:h-auto lg:min-h-screen fixed lg:static top-16 left-0 right-0 z-0 p-4 lg:p-8">
                        {/* Image with padding and rounded corners */}
                        <div
                            className="w-full h-full rounded-2xl bg-cover bg-center"
                            style={{
                                backgroundImage:
                                    "url('https://gni-public-bucket.s3.amazonaws.com/dashboard_images/happy_family.png')",
                            }}
                        ></div>
                    </div>
                </main>
            </div>
        );
    }

    // If authenticated, this will not be reached due to the redirect in useEffect
    return null;
};

export default WelcomePage;
