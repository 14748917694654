import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';
import ManualFloorPlanEditor from '@/components/ManualFloorPlanEditor'; 
import KitchenLayoutEditor from '@/components/KitchenLayoutEditor';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent } from "@/components/ui/card";
import { ArrowBigRight, ArrowBigLeft } from "lucide-react";

const FloorPlanScreen = ({ flowId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [floorPlanData, setFloorPlanData] = useState(null);
  const [kitchenLayoutData, setKitchenLayoutData] = useState(null);
  const [appliancesData, setAppliancesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeEditor, setActiveEditor] = useState('floorPlan');

  useEffect(() => {
    async function fetchProject() {
      setIsLoading(true);
      try {
        const currentProject = await ProjectService.getProjectById(projectId);
        if (currentProject.floorPlan) {
          const parsedFloorPlan = JSON.parse(currentProject.floorPlan);
          setFloorPlanData(parsedFloorPlan || null);
        }
        if (currentProject.kitchenLayout) {
          const parsedKitchenLayout = JSON.parse(currentProject.kitchenLayout);
          setKitchenLayoutData(parsedKitchenLayout || null);
        }
        if (currentProject.appliances) {
          const parsedAppliances = typeof currentProject.appliances === 'string' 
            ? JSON.parse(currentProject.appliances) 
            : currentProject.appliances;
          setAppliancesData(parsedAppliances || null);
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchProject();
  }, [projectId]);

  const handleComplete = async () => {
    try {
      await saveFloorPlanData(floorPlanData);
      await saveKitchenLayoutData(kitchenLayoutData);
      await ProjectService.addCompletedFlow(projectId, flowId);
      navigate(`/new-kitchen-project/${projectId}`);
    } catch (error) {
      console.error("Error completing section:", error);
    }
  };

  const handleSaveFloorPlan = (data) => {
    setFloorPlanData(data);

  };

  const handleSaveKitchenLayout = (data) => {
    setKitchenLayoutData(data);
  };

  const saveFloorPlanData = async (data) => {
    try {
      const stringifyFloorPlanData = JSON.stringify(data);
      await ProjectService.updateProject(projectId, {floorPlan: stringifyFloorPlanData});
    } catch (error) {
      console.error("Error saving floor plan data:", error);
    }
  };

  const saveKitchenLayoutData = async (data) => {
    try {
      const stringifyKitchenLayoutData = JSON.stringify(data);
      await ProjectService.updateProject(projectId, { kitchenLayout: stringifyKitchenLayoutData});
    } catch (error) {
      console.error("Error saving kitchen layout data:", error);
    }
  };

  const toggleEditor = async() => {
    if (activeEditor === 'floorPlan') {
      await saveFloorPlanData(floorPlanData);
    } else {
      await saveKitchenLayoutData(kitchenLayoutData);
    }
    setActiveEditor(activeEditor === 'floorPlan' ? 'kitchenLayout' : 'floorPlan');
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Card>
          <CardContent className="pt-6">
            <p>Loading floor plan data...</p>
          </CardContent>
        </Card>
      );
    }

    if (activeEditor === 'kitchenLayout') {
      return (
        <KitchenLayoutEditor
          onSave={handleSaveKitchenLayout}
          initialKitchenLayout={kitchenLayoutData}
          floorPlanData={floorPlanData}
          initialAppliancesData={appliancesData}
        />
      );
    }

    return (
      <div className="w-full">
        <Tabs defaultValue="manual" className="w-full">
          <TabsList className="flex items-center space-x-2">
            <TabsTrigger value="manual">Manual Draw</TabsTrigger>
            <TabsTrigger value="scanner">Room Scanner</TabsTrigger>
          </TabsList>
          
          <TabsContent value="manual">
            <ManualFloorPlanEditor
              onSave={handleSaveFloorPlan}
              initialFloorPlan={floorPlanData}
            />
          </TabsContent>
          
          <TabsContent value="scanner">
            <Card>
              <CardContent className="pt-6">
                <h2 className="text-xl font-semibold mb-2">Coming soon...</h2>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 pb-24">
      <ProjectHeader showBackButton={true}/>
      <main className="pt-20 px-4">
        {renderContent()}
      </main>

      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-center items-center space-x-4 shadow-lg">
        {activeEditor === 'floorPlan' ? (
          <Button 
            onClick={toggleEditor}
            className="flex items-center bg-emerald-500 hover:bg-emerald-600 text-white"
            variant="outline"
            // className="flex items-center"
          >
            Set Layout <ArrowBigRight className="ml-1 h-4 w-4" />
          </Button>
        ) : (
          <div className="flex space-x-4">
            <Button 
              onClick={toggleEditor}
              variant="outline"
              // className="flex items-center"
              className="flex items-center bg-emerald-500 hover:bg-emerald-600 text-white"
            >
              <ArrowBigLeft className="mr-1 h-4 w-4" /> Floor Plan
            </Button>
            <Button 
              onClick={handleComplete} 
              disabled={!floorPlanData}
              className="min-w-[160px]"
            >
              Complete Section
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloorPlanScreen;