import React, { useState, useRef, useEffect } from 'react';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { convertToInches, convertFromInches, formatValue } from '@/utils/utils';

const MAX_CONTAINER_SIZE = 600;

const WallElementEditor = ({ wallName, wallWidth, wallHeight, unit, elements, onElementsChange, onClose }) => {

  const [selectedElement, setSelectedElement] = useState(null);
  const [draggedElement, setDraggedElement] = useState(null);
  const [isAddElementOpen, setIsAddElementOpen] = useState(false);
  const wallRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: MAX_CONTAINER_SIZE, height: MAX_CONTAINER_SIZE });
  const aspectRatio = wallWidth / wallHeight;
  const ELEMENT_TYPES = {
    window: { label: 'Window', color: 'lightblue', defaultSize: { width: 20, height: 20 }, resizable: true },
    door: { label: 'Door', color: 'brown', defaultSize: { width: 30, height: 80 }, resizable: true, floorOnly: true },
    electricity: { label: 'Electricity', color: 'yellow', defaultSize: { width: 4, height: 4 } },
    water: { label: 'Water', color: 'blue', defaultSize: { width: 4, height: 4 } },
    gas: { label: 'Gas', color: 'red', defaultSize: { width: 4, height: 4 } },
    opening: {label: 'Opening', color: 'white', defaultSize: { width: wallWidth/2, height: wallHeight }, resizable: true, floorOnly: true }
  };

  useEffect(() => {
    const updateContainerSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const maxWidth = Math.min(screenWidth - 90, MAX_CONTAINER_SIZE);
      const maxHeight = Math.min(screenHeight * 0.4, MAX_CONTAINER_SIZE); // Reduced to 40% of screen height

      let width, height;

      // Calculate initial dimensions based on aspect ratio
      if (aspectRatio > maxWidth / maxHeight) {
        // Width constrained
        width = maxWidth;
        height = width / aspectRatio;
        
        // If height exceeds maxHeight, adjust both dimensions
        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
      } else {
        // Height constrained
        height = maxHeight;
        width = height * aspectRatio;
        
        // If width exceeds maxWidth, adjust both dimensions
        if (width > maxWidth) {
          width = maxWidth;
          height = width / aspectRatio;
        }
      }

      setContainerSize({ width, height });
    };

    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, [aspectRatio]);

  const scaleX = containerSize.width / wallWidth;
  const scaleY = containerSize.height / wallHeight;

  const convertToPixelsX = (value) => value * scaleX;
  const convertToPixelsY = (value) => value * scaleY;
  const convertFromPixelsX = (pixels) => Number((pixels / scaleX).toFixed(2));
  const convertFromPixelsY = (pixels) => Number((pixels / scaleY).toFixed(2));

  const addElement = (elementType) => {
    const defaultSize = ELEMENT_TYPES[elementType].defaultSize;    
    let x = Math.random() * (wallWidth - defaultSize.width);
    let y = Math.random() * (wallHeight - defaultSize.height);
    
    if (ELEMENT_TYPES[elementType].floorOnly) {
      y = 0;
    }
    
    const newElement = {
      type: elementType,
      x,
      y,
      width: defaultSize.width,
      height: defaultSize.height
    };
    console.log("new element:", newElement, "wall width:", wallWidth, "wall height:", wallHeight)
    
    onElementsChange([...elements, newElement]);
    setIsAddElementOpen(false);
  };

  const removeElement = (index) => {
    onElementsChange(elements.filter((_, i) => i !== index));
    setSelectedElement(null);
  };

  const handleElementClick = (e, index) => {
    e.stopPropagation();
    setSelectedElement(index === selectedElement ? null : index);
  };

  const handleElementUpdate = (index, field, value) => {
    const newValue = value === '' ? 0 : parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      const updatedElements = elements.map((element, i) => {
        if (i === index) {
          const updatedElement = { ...element, [field]: newValue };
          if (field === 'width') {
            updatedElement.x = Math.min(updatedElement.x, wallWidth - newValue);
          } else if (field === 'height') {
            updatedElement.y = Math.min(updatedElement.y, wallHeight - newValue);
          } else if (field === 'x') {
            updatedElement.x = Math.min(newValue, wallWidth - element.width);
          } else if (field === 'y') {
            updatedElement.y = Math.min(newValue, wallHeight - element.height);
          }
          return updatedElement;
        }
        return element;
      });
      onElementsChange(updatedElements);
    }
  };

  const handleDragStart = (e, index) => {
    const clientX = e.type.startsWith('touch') ? e.touches[0].clientX : e.clientX;
    const clientY = e.type.startsWith('touch') ? e.touches[0].clientY : e.clientY;
    setDraggedElement({ index, startX: clientX, startY: clientY });
  };

  const handleDrag = (e) => {
    if (draggedElement && wallRef.current) {
      const clientX = e.type.startsWith('touch') ? e.touches[0].clientX : e.clientX;
      const clientY = e.type.startsWith('touch') ? e.touches[0].clientY : e.clientY;
      const rect = wallRef.current.getBoundingClientRect();
      const deltaX = convertFromPixelsX(clientX - draggedElement.startX);
      const deltaY = convertFromPixelsY(draggedElement.startY - clientY);
      
      const updatedElements = elements.map((element, index) => {
        if (index === draggedElement.index) {
          let newX = Math.max(0, Math.min(element.x + deltaX, wallWidth - element.width));
          let newY = Math.max(0, Math.min(element.y + deltaY, wallHeight - element.height));
          
          if (ELEMENT_TYPES[element.type].floorOnly) {
            newY = 0;
          }
          
          return { ...element, x: newX, y: newY };
        }
        return element;
      });
      
      onElementsChange(updatedElements);
      setDraggedElement({ ...draggedElement, startX: clientX, startY: clientY });
    }
  };

  const handleDragEnd = () => {
    setDraggedElement(null);
  };

  useEffect(() => {
    if (draggedElement) {
      const handleMouseMove = (e) => handleDrag(e);
      const handleTouchMove = (e) => {
        e.preventDefault(); // Prevent scrolling while dragging
        handleDrag(e);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchend', handleDragEnd);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('mouseup', handleDragEnd);
        document.removeEventListener('touchend', handleDragEnd);
      };
    }
  }, [draggedElement]);


  const renderArrows = (element, index) => {
    if (selectedElement !== index) return null;

    const xArrowLength = convertToPixelsX(element.x);
    const yArrowLength = convertToPixelsY(element.y);

    return (
      <>
        {/* X-axis arrow */}
        <div
          style={{
            position: 'absolute',
            left: '0',
            bottom: `${convertToPixelsY(element.y)}px`,
            width: `${xArrowLength}px`,
            height: '2px',
            backgroundColor: 'green',
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: `${xArrowLength}px`,
            bottom: `${convertToPixelsY(element.y) - 4}px`,
            width: '0',
            height: '0',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '8px solid red',
            transform: 'rotate(90deg)',
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: `${xArrowLength / 2}px`,
            bottom: `${convertToPixelsY(element.y) + 5}px`,
            color: 'green',
            fontSize: '12px',
            whiteSpace: 'nowrap',
          }}
        >
          {formatValue(element.x, unit)} {unit}
        </div>

        {/* Y-axis arrow */}
        <div
          style={{
            position: 'absolute',
            left: `${convertToPixelsX(element.x)}px`,
            bottom: '0',
            width: '2px',
            height: `${yArrowLength}px`,
            backgroundColor: 'blue',
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: `${convertToPixelsX(element.x) - 4}px`,
            bottom: `${yArrowLength}px`,
            width: '0',
            height: '0',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '8px solid blue',
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: `${convertToPixelsX(element.x)}px`,
            bottom: `${yArrowLength / 2}px`,
            color: 'blue',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            transform: 'rotate(-90deg)',
            transformOrigin: 'left bottom',
          }}
        >
          {formatValue(element.y, unit)} {unit}
        </div>
      </>
    );
  };

  return (
    <ScrollArea className="h-[calc(90vh-120px)] w-full">
      <div className="flex flex-col items-center space-y-4 p-4 w-full">
        {/* <div className="text-sm w-full text-center">Wall dimensions: {formatValue(wallWidth, unit)} x {formatValue(wallHeight, unit)} {unit}</div> */}
        <div
          ref={wallRef}
          style={{
            width: `${containerSize.width}px`,
            height: `${containerSize.height}px`,
            backgroundColor: 'lightgray',
            position: 'relative',
            border: '2px solid black',
            cursor: 'pointer',
            touchAction: 'none', // Disable browser handling of all panning and zooming gestures
          }}
        >
          {elements.map((element, index) => (
            <React.Fragment key={index}>
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left: `${convertToPixelsX(element.x)}px`,
                  bottom: `${convertToPixelsY(element.y)}px`,
                  width: `${convertToPixelsX(element.width)}px`,
                  height: `${convertToPixelsY(element.height)}px`,
                  backgroundColor: ELEMENT_TYPES[element.type].color,
                  border: selectedElement === index ? '2px solid blue' : '1px solid black',
                  boxShadow: selectedElement === index ? '0 0 10px rgba(0, 0, 255, 0.5)' : 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 'clamp(12px, 1.5vw, 24px)',
                  cursor: 'move',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  padding: '2px',
                  touchAction: 'none', // Disable browser handling of all panning and zooming gestures
                }}
                onClick={(e) => handleElementClick(e, index)}
                onMouseDown={(e) => handleDragStart(e, index)}
                onTouchStart={(e) => handleDragStart(e, index)}
              >
                {ELEMENT_TYPES[element.type].label}
              </div>
            {renderArrows(element, index)}
            </React.Fragment>
          ))}
        </div>

        {/* <ScrollArea className="w-full max-h-[30vh]"> */}
          {selectedElement !== null && (
            <div className="mt-4 w-full max-w-[600px]">
              <h3 className="text-lg font-semibold">Edit Element:</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="elementWidth">Width:</Label>
                  <Input
                    id="elementWidth"
                    type="number"
                    value={formatValue(elements[selectedElement].width, unit)}
                    onChange={(e) => handleElementUpdate(selectedElement, 'width', convertToInches(e.target.value, unit))}
                  />
                </div>
                <div>
                  <Label htmlFor="elementHeight">Height:</Label>
                  <Input
                    id="elementHeight"
                    type="number"
                    value={formatValue(elements[selectedElement].height, unit)}
                    onChange={(e) => handleElementUpdate(selectedElement, 'height', convertToInches(e.target.value, unit))}
                  />
                </div>
                <div>
                  <Label htmlFor="elementX">Distance from left:</Label>
                  <Input
                    id="elementX"
                    type="number"
                    value={formatValue(elements[selectedElement].x, unit)}
                    onChange={(e) => handleElementUpdate(selectedElement, 'x', convertToInches(e.target.value, unit))}
                  />
                </div>
                <div>
                  <Label htmlFor="elementY">Distance from floor:</Label>
                  <Input
                    id="elementY"
                    type="number"
                    value={formatValue(elements[selectedElement].y, unit)}
                    onChange={(e) => handleElementUpdate(selectedElement, 'y', convertToInches(e.target.value, unit))}
                  />
                </div>
              </div>
              <Button onClick={() => removeElement(selectedElement)} className="mt-2">Remove Element</Button>
            </div>
          )}
          <div className="flex flex-col items-center w-full max-w-[600px] ">
            <Dialog open={isAddElementOpen} onOpenChange={setIsAddElementOpen}>
              <DialogTrigger asChild>
                <Button className="mb-4">Add Element</Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Add Element</DialogTitle>
                </DialogHeader>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(ELEMENT_TYPES).map(([key, { label }]) => (
                    <Button key={key} onClick={() => addElement(key)}>{label}</Button>
                  ))}
                </div>
              </DialogContent>
            </Dialog>
            <h3 className="text-lg font-semibold self-start mb-2">Element List</h3>
            <ul className="space-y-2 w-full">
              {elements.map((element, index) => (
                <li key={index} className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <span className="mb-2 sm:mb-0">
                    {ELEMENT_TYPES[element.type].label} at ({element.x.toFixed(2)}, {element.y.toFixed(2)}) {unit}, 
                    size: {element.width.toFixed(2)} x {element.height.toFixed(2)} {unit}
                  </span>
                  <div>
                    <Button onClick={() => setSelectedElement(index)} className="mr-2 mb-2 sm:mb-0">Edit</Button>
                    <Button onClick={() => removeElement(index)}>Remove</Button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        

        <Button onClick={onClose} className="mt-4">Done</Button>
      </div>
      </ScrollArea>
  );
};

export default WallElementEditor;