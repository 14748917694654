import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://pcsr0gqldd.execute-api.us-east-1.amazonaws.com/prod';
const STORAGE_KEY = 'kitchenProjects';

const api = axios.create({
  baseURL: API_BASE_URL,
});

const getMimeType = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  const mimeTypes = {
    'png': 'image/png',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'gif': 'image/gif',
    'pdf': 'application/pdf',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'zip': 'application/zip',
    'glb': 'model/gltf-binary',
    'gltf': 'model/gltf+json',
    'txt': 'text/plain',
    'json': 'application/json',
    'mp4': 'video/mp4',
    'mp3': 'audio/mpeg',
    // Add more mime types as needed
  };
  
  return mimeTypes[extension] || 'application/octet-stream'; // Default to binary if type unknown
};

export const ProjectService = {

  async getUser(userId) {
    console.log("get user data:", userId)
    try{
      const response = await api.get(`/users/${userId}`);
      console.log("get user response:", response)
    return response.data;

    }catch (error){
      console.error('Failed to get user from API', error);
    }
  },

  async updateUser(userId,body) {
    try{
      const response = await api.put(`/users/${userId}`,body);
    return response.data;

    }catch (error){
      console.error('Failed to create new user through API', error);
    }
  },

  async createUser(userId,body) {
    try{
      const response = await api.put(`/users/${userId}`,body);
    return response.data;

    }catch (error){
      console.error('Failed to create new user through API', error);
    }
  },


  async getAllProjects() {
    try {
      const response = await api.get('/projects');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch projects from API, falling back to local storage', error);
      return JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
    }
  },

  async getUserProjects(userId) {
    try {
      const response = await api.get(`/projects?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user projects from API, falling back to local storage', error);
      const allProjects = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      return allProjects.filter(project => project.userId === userId);
    }
  },

  async getProjectById(projectId) {
    try {
      const response = await api.get(`/projects/${projectId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch project from API, falling back to local storage', error);
      const allProjects = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      return allProjects.find(project => project.id === projectId);
    }
  },

  async createProject(userId, name = null) {
    const newProject = {
      userId,
      name: name || `Kitchen Project ${(await this.getUserProjects(userId)).length + 1}`,
    };

    try {
      const response = await api.post('/projects', newProject);
      return response.data;
    } catch (error) {
      console.error('Failed to create project via API, falling back to local storage', error);
      const createdProject = {
        ...newProject,
        id: Date.now().toString(),
        createdAt: new Date().toISOString(),
        completedFlows: [],
        status: 'in_progress',
      };
      const allProjects = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      localStorage.setItem(STORAGE_KEY, JSON.stringify([...allProjects, createdProject]));
      return createdProject;
    }
  },

  async updateProject(projectId, updates) {
    try {
      const response = await api.put(`/projects/${projectId}`, updates);
      return response.data;
    } catch (error) {
      console.error('Failed to update project via API, falling back to local storage', error);
      const allProjects = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      const updatedProjects = allProjects.map(project => 
        project.id === projectId ? { ...project, ...updates } : project
      );
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedProjects));
      return updatedProjects.find(project => project.id === projectId);
    }
  },

  async deleteProject(projectId) {
    try {
      await api.delete(`/projects/${projectId}`);
    } catch (error) {
      console.error('Failed to delete project via API, falling back to local storage', error);
      const allProjects = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      const updatedProjects = allProjects.filter(project => project.id !== projectId);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedProjects));
    }
  },

  async addCompletedFlow(projectId, flowId) {
    try {
      const response = await api.post(`/projects/${projectId}/flows`, { flowName: flowId });
      return response.data;
    } catch (error) {
      console.error('Failed to add completed flow via API, falling back to local storage', error);
      const project = await this.getProjectById(projectId);
      if (project && !project.completedFlows.includes(flowId)) {
        const updatedCompletedFlows = [...project.completedFlows, flowId];
        return this.updateProject(projectId, { completedFlows: updatedCompletedFlows });
      }
      return project;
    }
  },

  async submitProject(projectId) {
    return this.updateProject(projectId, { status: 'submitted' });
  },

  async updateProjectName(projectId, newName) {
    return this.updateProject(projectId, { name: newName });
  },

  // Add these new methods for S3 operations
  async getPresignedUrl(operation, params) {
    try {
      const response = await api.post('/presigned-url', {
        operation,
        ...params
      });
      return response.data;
    } catch (error) {
      console.error(`Failed to get ${operation} URL:`, error);
      throw error;
    }
  },

  // Method for getting upload URL
  async getUploadUrl(fileType, fileName, projectId) {
    return this.getPresignedUrl('upload', {
      fileType,
      fileName,
      projectId
    });
  },

  // Method for getting download URL
  async getDownloadUrl(fileKey) {
    return this.getPresignedUrl('download', {
      key: fileKey
    });
  },

  // Method for requesting file deletion
  async deleteFile(fileKey) {
    try {
      await api.post('/presigned-url', {
        operation: 'delete',
        key: fileKey
      });
      return true;
    } catch (error) {
      console.error('Failed to delete file:', error);
      throw error;
    }
  },

  async deleteFileFromS3(fileKey) {
    try {
      await api.post('/presigned-url', {
        operation: 'delete',
        key: fileKey
      });
      return true;
    } catch (error) {
      console.error('Failed to delete file from S3:', error);
      throw error;
    }
  },

  
  // S3 File Operations
  async uploadFileToS3(file, projectId, onProgress = null) {
    console.log("file:", file);
    try {
      // Determine content type
      const contentType = file.type || getMimeType(file.name);
      console.log("Using content type:", contentType);            
      // 1. Get presigned URL
      const response = await api.post('/presigned-url', {
        operation: 'upload',
        fileType: contentType,
        fileName: file.name,
        projectId
      });

      const { presignedUrl, fileUrl, key } = response.data;

      // 2. Upload file using presigned URL
      if (onProgress) {
        // Use XMLHttpRequest for progress tracking
        await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          
          xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              const percentComplete = (event.loaded / event.total) * 100;
              onProgress(percentComplete);
            }
          });
          
          xhr.addEventListener('load', () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve();
            } else {
              reject(new Error('Upload failed'));
            }
          });
          
          xhr.addEventListener('error', () => reject(new Error('Upload failed')));
          
          xhr.open('PUT', presignedUrl);
          xhr.setRequestHeader('Content-Type', contentType);
          xhr.send(file);
        });
      } else {
        // Simple upload without progress tracking
        await api.put(presignedUrl, file, {
          headers: { 'Content-Type': contentType }
        });
      }

      // 3. Return the file information
      return {
        s3Key: key,
        s3Url: fileUrl
      };
    } catch (error) {
      console.error('Failed to upload file to S3:', error);
      throw error;
    }
  },

  async downloadFileFromS3(fileKey) {
    try {
      // 1. Get presigned URL for download
      const response = await api.post('/presigned-url', {
        operation: 'download',
        key: fileKey
      });

      const { downloadUrl } = response.data;

      // 2. Download the file
      const fileResponse = await api.get(downloadUrl, {
        responseType: 'blob'
      });

      return fileResponse.data;
    } catch (error) {
      console.error('Failed to download file from S3:', error);
      throw error;
    }
  },


  // Add progress tracking support to the complete file upload process
  async handleCompleteFileUpload(file, projectId, onProgress = null) {
    try {
      // 1. Get upload URL
      const { presignedUrl, fileUrl, key } = await this.getUploadUrl(
        file.type,
        file.name,
        projectId
      );

      // 2. Upload to S3 with optional progress tracking
      await this.uploadFileToS3(presignedUrl, file, onProgress);

      return {
        s3Key: key,
        s3Url: fileUrl
      };
    } catch (error) {
      console.error('Complete file upload process failed:', error);
      throw error;
    }
  },
};

export default ProjectService;