export const findEmptySpace = (roomData, kitchenLayout) => {
  function findEmptySpace(roomData, kitchenLayout) {
    const { dimensions, roomHeight, roomType, unit, wallCoordinates } = roomData;
    
    // Step 1: Create a 2D grid representation of the room
    const gridSize = 1; // 1 inch grid
    const gridWidth = Math.ceil(dimensions.width / gridSize);
    const gridDepth = Math.ceil(dimensions.depth / gridSize);
    const grid = Array(gridDepth).fill().map(() => Array(gridWidth).fill(0));
  
    // Step 2: Mark walls on the grid (without thickness)
    function markWall(x1, y1, x2, y2) {
      const dx = x2 - x1;
      const dy = y2 - y1;
      const length = Math.sqrt(dx * dx + dy * dy);
      const steps = Math.ceil(length / gridSize);
      
      for (let i = 0; i <= steps; i++) {
        const x = Math.round(x1 + (dx * i / steps) / gridSize);
        const y = Math.round(y1 + (dy * i / steps) / gridSize);
        
        if (x >= 0 && x < gridWidth && y >= 0 && y < gridDepth) {
          grid[y][x] = 1;
        }
      }
    }
  
    Object.values(wallCoordinates).forEach(wall => {
      markWall(wall.x1, wall.y1, wall.x2, wall.y2);
    });
  
    // Step 3: Mark kitchen layout elements on the grid
    kitchenLayout.forEach(element => {
      for (let y = Math.floor(element.y1 / gridSize); y <= Math.ceil(element.y3 / gridSize); y++) {
        for (let x = Math.floor(element.x1 / gridSize); x <= Math.ceil(element.x3 / gridSize); x++) {
          if (x >= 0 && x < gridWidth && y >= 0 && y < gridDepth) {
            grid[y][x] = 1;
          }
        }
      }
    });
  
    // Step 4: Find the largest empty rectangle
    function findLargestEmptyRectangle() {
      const heights = Array(gridWidth).fill(0);
      let maxArea = 0;
      let maxRect = { x: 0, y: 0, width: 0, height: 0 };
  
      for (let row = 0; row < gridDepth; row++) {
        for (let col = 0; col < gridWidth; col++) {
          if (grid[row][col] === 0) {
            heights[col]++;
          } else {
            heights[col] = 0;
          }
        }
  
        const stack = [];
        for (let col = 0; col <= gridWidth; col++) {
          let h = (col === gridWidth) ? 0 : heights[col];
          if (stack.length === 0 || h > heights[stack[stack.length - 1]]) {
            stack.push(col);
          } else {
            let height, width;
            while (stack.length > 0 && h < heights[stack[stack.length - 1]]) {
              height = heights[stack.pop()];
              width = stack.length === 0 ? col : col - stack[stack.length - 1] - 1;
              if (height * width > maxArea) {
                maxArea = height * width;
                maxRect = { x: stack.length === 0 ? 0 : stack[stack.length - 1] + 1, y: row - height + 1, width, height };
              }
            }
            stack.push(col);
          }
        }
      }
  
      return maxRect;
    }
  
    const largestEmptySpace = findLargestEmptyRectangle();
  
    // Step 5: Convert grid coordinates back to room coordinates and format as x1, y1, x2, y2, x3, y3, x4, y4
    const emptySpace = {
      x1: largestEmptySpace.x * gridSize,
      y1: largestEmptySpace.y * gridSize,
      x2: (largestEmptySpace.x + largestEmptySpace.width) * gridSize,
      y2: largestEmptySpace.y * gridSize,
      x3: (largestEmptySpace.x + largestEmptySpace.width) * gridSize,
      y3: (largestEmptySpace.y + largestEmptySpace.height) * gridSize,
      x4: largestEmptySpace.x * gridSize,
      y4: (largestEmptySpace.y + largestEmptySpace.height) * gridSize,
      height: roomHeight
    };
  
    return emptySpace;
  }
};

export const findInnerSpace = (roomData, kitchenLayout, clearance = 42) => {
  const { dimensions, roomHeight, roomType, unit, wallCoordinates } = roomData;
  
  // Step 1: Create a 2D grid representation of the room
  const gridSize = 1; // 1 inch grid
  const gridWidth = Math.ceil(dimensions.width / gridSize);
  const gridDepth = Math.ceil(dimensions.depth / gridSize);
  const grid = Array(gridDepth).fill().map(() => Array(gridWidth).fill(0));

  // Step 2: Mark walls and clearance area on the grid
  function markAreaWithClearance(x1, y1, x2, y2, clearance) {
    for (let y = Math.max(0, Math.floor(y1 - clearance)); y <= Math.min(gridDepth - 1, Math.ceil(y2 + clearance)); y++) {
      for (let x = Math.max(0, Math.floor(x1 - clearance)); x <= Math.min(gridWidth - 1, Math.ceil(x2 + clearance)); x++) {
        grid[y][x] = 1;
      }
    }
  }

  Object.values(wallCoordinates).forEach(wall => {
    markAreaWithClearance(wall.x1, wall.y1, wall.x2, wall.y2, wall.thickness + clearance);
  });

  // Step 3: Mark kitchen layout elements and clearance area on the grid
  kitchenLayout.forEach(element => {
    if (element.type === "baseCabinets") {
      markAreaWithClearance(element.x1, element.y1, element.x3, element.y3, clearance);
    }
  });

  // Step 4: Find the largest empty rectangle (same as in findEmptySpace function)
  function findLargestEmptyRectangle() {
    const heights = Array(gridWidth).fill(0);
    let maxArea = 0;
    let maxRect = { x: 0, y: 0, width: 0, height: 0 };

    for (let row = 0; row < gridDepth; row++) {
      for (let col = 0; col < gridWidth; col++) {
        if (grid[row][col] === 0) {
          heights[col]++;
        } else {
          heights[col] = 0;
        }
      }

      const stack = [];
      for (let col = 0; col <= gridWidth; col++) {
        let h = (col === gridWidth) ? 0 : heights[col];
        if (stack.length === 0 || h > heights[stack[stack.length - 1]]) {
          stack.push(col);
        } else {
          let height, width;
          while (stack.length > 0 && h < heights[stack[stack.length - 1]]) {
            height = heights[stack.pop()];
            width = stack.length === 0 ? col : col - stack[stack.length - 1] - 1;
            if (height * width > maxArea) {
              maxArea = height * width;
              maxRect = { x: stack.length === 0 ? 0 : stack[stack.length - 1] + 1, y: row - height + 1, width, height };
            }
          }
          stack.push(col);
        }
      }
    }

    return maxRect;
  }

  const largestInnerSpace = findLargestEmptyRectangle();

  // Step 5: Convert grid coordinates back to room coordinates
  const innerSpace = {
    x1: largestInnerSpace.x * gridSize,
    y1: largestInnerSpace.y * gridSize,
    x2: (largestInnerSpace.x + largestInnerSpace.width) * gridSize,
    y2: largestInnerSpace.y * gridSize,
    x3: (largestInnerSpace.x + largestInnerSpace.width) * gridSize,
    y3: (largestInnerSpace.y + largestInnerSpace.height) * gridSize,
    x4: largestInnerSpace.x * gridSize,
    y4: (largestInnerSpace.y + largestInnerSpace.height) * gridSize,
    height: roomHeight
  };

  return innerSpace;
};


 