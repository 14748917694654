import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Check, X, PencilIcon } from 'lucide-react';
import ProjectHeader from '@/components/header';
import { ProjectService } from '@/services/ProjectService';

const NewKitchenProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();

  const [completedFlows, setCompletedFlows] = useState([]);
  const [cardsExpanded, setCardsExpanded] = useState(true);
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [tempProjectName, setTempProjectName] = useState('');

  const flows = [
    { id: 'colors', title: "Colors, Materials & Inspiration", description: "Choose your style and design preferences", icon: "🎨", path: `style` },
    { id: 'appliances', title: "Appliances", description: "Create your own kitchen appliances list", icon: "🍳", path: `appliances` },
    { id: 'layout', title: "Floorplan & Layout", description: "Set up your kitchen's space and layout", icon: "📐", path: `layout` },
    { id: 'needs', title: "Additional Information", description: "Tell us about your specific requirements", icon: "📝", path: `needs` },
    { id: 'upload', title: "Walktroughs, Links and Files", description: "Upload supporting information and insperations", icon: "-📎-", path: `upload` }
  ];

  useEffect(() => {
    async function fetchProject() {
      try {
        const project = await ProjectService.getProjectById(projectId);
        
        if (project?.status === 'submitted' && location.pathname === `/new-kitchen-project/${projectId}`) {
          navigate('/projects');
          return;
        }

        if (project) {
          setCompletedFlows(project.completedFlows || []);
          setRequestSubmitted(project.status === 'submitted');
          setProjectName(project.name || '');
          setTempProjectName(project.name || '');
        }

        const currentFlow = flows.find(flow => location.pathname.includes(flow.path));
        if (currentFlow && (!project.completedFlows || !project.completedFlows.includes(currentFlow.id))) {
          await ProjectService.addCompletedFlow(projectId, currentFlow.id);
          setCompletedFlows(prevFlows => [...prevFlows, currentFlow.id]);
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    }
    fetchProject();
  }, [location.pathname, projectId, navigate]);

  const handleStartEditName = () => {
    setIsEditingName(true);
    setTempProjectName(projectName);
  };

  const handleSaveName = async () => {
    if (tempProjectName.trim() !== '') {
      try {
        await ProjectService.updateProjectName(projectId, tempProjectName);
        setProjectName(tempProjectName);
        setIsEditingName(false);
      } catch (error) {
        console.error('Error updating project name:', error);
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditingName(false);
    setTempProjectName(projectName);
  };

  const handleFlowSelection = (path) => {
    navigate(`/new-kitchen-project/${projectId}/${path}`);
  };


  const handleRequestSubmit = async () => {
    try {
      navigate(`/new-kitchen-project/${projectId}/summary`);
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const renderFlowOptions = () => (
    <div className={`grid grid-cols-1 gap-4 transition-all duration-300 ${cardsExpanded ? 'w-full' : 'w-1/4'}`}>
      {flows.map((flow) => (
        <Card 
          key={flow.id} 
          className={`hover:shadow-lg transition-shadow cursor-pointer max-w-md mx-auto w-full ${
            completedFlows.includes(flow.id) ? 'bg-green-50' : ''
          }`}
          onClick={() => handleFlowSelection(flow.path)}
        >
          <CardHeader className="py-3">
            <CardTitle className="flex items-center justify-between text-sm sm:text-base md:text-lg">
              <span className="flex items-center">
                <span className="text-xl sm:text-2xl md:text-3xl mr-2">{flow.icon}</span>
                <span>{cardsExpanded ? flow.title : ''}</span>
              </span>
              {completedFlows.includes(flow.id) && <Check className="text-green-500" />}
            </CardTitle>
            {cardsExpanded && <CardDescription>{flow.description}</CardDescription>}
          </CardHeader>
        </Card>
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <ProjectHeader/>
      <main className="pt-14 px-4 text-center">
        <div className="max-w-6xl mx-auto">
          {/* Project Title Section */}
          <div className="mb-8 flex justify-center items-center">
            {isEditingName ? (
              <div className="flex items-center gap-2 max-w-md w-full">
                <Input
                  value={tempProjectName}
                  onChange={(e) => setTempProjectName(e.target.value)}
                  className="text-xl font-semibold"
                  placeholder="Enter project name"
                />
                <Button
                  onClick={handleSaveName}
                  className="bg-green-500 hover:bg-green-400 text-white p-2"
                >
                  <Check size={20} />
                </Button>
                <Button
                  onClick={handleCancelEdit}
                  className="bg-gray-500 hover:bg-gray-400 text-white p-2"
                >
                  <X size={20} />
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <h1 className="text-2xl font-bold">{projectName || 'Untitled Project'}</h1>
                {!requestSubmitted && (
                  <Button
                    onClick={handleStartEditName}
                    // className="ml-2 bg-yellow-500 hover:bg-yellow-400 text-white p-2"
                    className="ml-2 bg-gray-100 hover:bg-gray-300 text-black p-2"
                  >
                    <PencilIcon size={16} />
                  </Button>
                )}
              </div>
            )}
          </div>

          {location.pathname === `/new-kitchen-project/${projectId}` && (
            <>
              <p className="text-xl text-center mb-12 text-gray-600">
                {completedFlows.length === flows.length ? 
                    requestSubmitted ? 
                    "Great! Here's your design and quotes based on your selections."
                    : "You've completed all sections! Click the button below to request your design and quote."
                  : completedFlows.length > 0? 
                    flows.length - completedFlows.length === 1 ? "One last step, let's go!": `Great! you accomplished ${completedFlows.length} out of ${flows.length} steps` : "To provide you with the best quote for your project, we need some information. Lets start?"}
              </p>
              <div className="flex gap-4">
                <div className={`flex-grow transition-all duration-300 ${cardsExpanded ? 'w-full' : 'w-1-4'}`}>
                  {renderFlowOptions()}
                  <Button 
                    onClick={handleRequestSubmit} 
                    className="mt-4 w-xl transition-colors duration-300"
                    disabled={completedFlows.length !== flows.length || requestSubmitted}
                    style={{
                      backgroundColor: completedFlows.length === flows.length && !requestSubmitted ? '#3b82f6' : '#9ca3af',
                      color: 'white',
                      opacity: completedFlows.length === flows.length && !requestSubmitted ? 1 : 0.5,
                    }}
                  >
                    Review & Submit
                  </Button>    
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default NewKitchenProject;