import React, { useState } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { Button } from "@/components/ui/button";
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuthState } from '@/hooks/AuthContext';
import { Home, ChevronLeft, Menu } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const ProjectHeader = ({ showHomeButton = true, showBackButton = false }) => {
    const { logout } = useKindeAuth();
    const { user, setAuthState } = useAuthState();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const userName = user?.given_name || user?.family_name || user?.email || 'User';
    const userInitials = userName.split(' ').map(n => n[0]).join('').toUpperCase();

    const handleLogout = async () => {
        try {
            await logout();
            localStorage.removeItem('user');
            setAuthState({
                isAuthenticated: false,
                isLoading: false,
                user: null
            });
            navigate('/welcome');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleBackToMain = () => {
        navigate(`/new-kitchen-project/${projectId}`);
    };

    const handleBackToProjects = () => {
        navigate('/projects');
    };

    return (
        <header className="fixed top-0 left-0 right-0 h-14 px-4 flex justify-between items-center bg-gray-100 z-50 shadow-md">
            <div className="flex items-center space-x-2 overflow-hidden">
                
                <h1 className="text-xl font-bold truncate">Welcome, {userName}</h1>
            </div>
            <div className="flex items-center space-x-2">
                {showBackButton && (
                    <Button onClick={handleBackToMain} variant="ghost" size="icon" className="shrink-0">
                        <ChevronLeft className="h-5 w-5" />
                    </Button>
                )}
                {showHomeButton && (
                    <Button onClick={handleBackToProjects} variant="ghost" size="icon" className="shrink-0">
                        <Home className="h-5 w-5" />
                    </Button>
                )}
                <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="relative h-10 w-10 rounded-full">
                            <Avatar className="h-8 w-8 bg-blue-500 text-white">
                                <AvatarImage src={user?.picture} alt={userName} />
                                <AvatarFallback className="text-lg font-bold">{userInitials}</AvatarFallback>
                            </Avatar>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-56">
                        <DropdownMenuItem className="flex-col items-start">
                            <div className="font-semibold">{userName}</div>
                            <div className="text-sm text-gray-500">{user?.email}</div>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={handleLogout}>
                            Logout
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header>
    );
};

export default ProjectHeader;
