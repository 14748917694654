import React, { createContext, useContext, useState, useEffect } from 'react';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { isAuthenticated, isLoading, user } = useKindeAuth();
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        isLoading: true,
        user: null
    });

    useEffect(() => {
        const checkAuthState = async () => {
            if (!isLoading) {
                if (isAuthenticated) {
                    try {
                        setAuthState({
                            isAuthenticated: true,
                            isLoading: false,
                            user
                        });
                        localStorage.setItem('user', JSON.stringify(user));
                    } catch (error) {
                        console.error("Error getting token:", error);
                        setAuthState({
                            isAuthenticated: false,
                            isLoading: false,
                            user: null
                        });
                    }
                } else {
                    const storedUser = JSON.parse(localStorage.getItem('user'));
                    if (storedUser) {
                        setAuthState({
                            isAuthenticated: true,
                            isLoading: false,
                            user: storedUser
                        });
                    } else {
                        localStorage.removeItem('user');
                        setAuthState({
                            isAuthenticated: false,
                            isLoading: false,
                            user: null
                        });
                    }
                }
            }
        };

        checkAuthState();
    }, [isAuthenticated, isLoading, user]);

    return (
        <AuthContext.Provider value={{ ...authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthState = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within an AuthProvider');
    }
    return context;
};