import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

const CallbackPage = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated, isLoading, user } = useKindeAuth();
  const [hasAttemptedCallback, setHasAttemptedCallback] = useState(false);

  console.log("isAuthenticated on callback", isAuthenticated)

  useEffect(() => {
    const handleCallback = async () => {
      console.log('Starting handleCallback function');
      console.log("isAuthenticated on project page", isAuthenticated)
      if (hasAttemptedCallback) {
        console.log('Callback already attempted, skipping');
        return;
      }
      setHasAttemptedCallback(true);

      try {
        if (user) {                  
          console.log('Navigating to /welcome');
          navigate('/welcome');
        } else {
          console.log('User object is null or undefined after authentication');
          navigate('/error');
        }
      } catch (error) {
        console.error('Error handling callback:', error);
        console.log('Error details:', JSON.stringify(error, null, 2));
        console.log('Navigating to /error');
        navigate('/error');
      }
    };

    const attemptLogin = async () => {
      try {
        await login();
      } catch (error) {
        console.error('Error during login:', error);
        navigate('/error');
      }
    };

    if (isLoading) {
      console.log('Still loading, waiting for authentication to complete');
    } else if (isAuthenticated && !hasAttemptedCallback) {
      console.log('User authenticated, calling handleCallback');
      handleCallback();
    } else if (!isAuthenticated && !isLoading) {
      console.log('User not authenticated, attempting login');
      attemptLogin();
    }
  }, [login, navigate, isAuthenticated, isLoading, user, hasAttemptedCallback]);

  if (isLoading) {
    console.log('Rendering loading message');
    return <div>Loading...</div>;
  }

  console.log('Rendering null');
  return null;
};

export default CallbackPage;