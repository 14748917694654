import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectHeader from '@/components/header';
import { Card, CardContent, CardTitle, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { ProjectService } from '@/services/ProjectService';
import { FaPlusCircle, FaSearch, FaExternalLinkAlt } from 'react-icons/fa';

import { applianceData, standardSizes } from '@/utils/appliance-data';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_SEARCH_APP_API_BASE_URL || 'https://xppqnn7pn6.execute-api.us-east-1.amazonaws.com/default';
const api = axios.create({
  baseURL: API_BASE_URL,
});

const ApplianceSelector = ({flowId}) => {
  const [selectedAppliances, setSelectedAppliances] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentAppliance, setCurrentAppliance] = useState(null);
  const [sizeType, setSizeType] = useState('standard');
  const [customSize, setCustomSize] = useState({ width: '', depth: '', height: '', additionalInfo: '' });
  const [searchProduct, setSearchProduct] = useState('');
  const [standardSize, setStandardSize] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    async function fetchAppliances() {
      setIsLoading(true);
      try {
        const currentProject = await ProjectService.getProjectById(projectId);
        if (currentProject.appliances) {
          let savedAppliances = currentProject.appliances;
          
          // If the appliances are stored as a string, parse them
          if (typeof currentProject.appliances === 'string') {
            savedAppliances = JSON.parse(currentProject.appliances);
          }
  
          // Map the saved appliances to include the icon component
          const appliancesWithIcons = savedAppliances.map(appliance => {
            const applianceTemplate = applianceData.find(a => a.id === appliance.id);
            return {
              ...appliance,
              icon: applianceTemplate?.icon
            };
          });
  
          setSelectedAppliances(appliancesWithIcons);
        }
      } catch (error) {
        console.error("Error fetching appliances:", error);
        // Optionally add error handling UI here
      } finally {
        setIsLoading(false);
      }
    }
    fetchAppliances();
  }, [projectId]);

  useEffect(() => {
    const saveAppliances = async () => {
      try {
        await ProjectService.updateProject(projectId, {
          appliances: selectedAppliances.map(appliance => ({
            id: appliance.id,
            name: appliance.name,
            sizeInfo: appliance.sizeInfo,
            dimensions: {
              width: appliance.dimensions.width.toString(),
              depth: appliance.dimensions.depth.toString(),
              height: appliance.dimensions.height.toString()
            },
            additionalInfo: appliance.additionalInfo
          }))
        });
      } catch (error) {
        console.error('Failed to save appliances:', error);
        // Optionally, you could add error handling UI here
      }
    };
  
    // Only save if there are any appliances selected
    // if (selectedAppliances.length > 0) {
    //   saveAppliances();
    // }

    saveAppliances();
  }, [selectedAppliances, projectId]);


  const handleComplete = () => {
    async function fetchCompletedFlow() {
      await ProjectService.addCompletedFlow(projectId, flowId)
      navigate(`/new-kitchen-project/${projectId}`);
    }
    fetchCompletedFlow();
  };

  const openDialog = (appliance) => {
    setCurrentAppliance(appliance);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setCurrentAppliance(null);
    setSizeType('standard');
    setCustomSize({ width: '', depth: '', height: '', additionalInfo: '' });
    setSearchProduct('');
    setStandardSize('');
  };


  const handleSaveAppliance = () => {
    let dimensions;
    if (sizeType === 'standard') {
      // standardSize will now be an object with numeric values
      dimensions = JSON.parse(standardSize);
    } else if (sizeType === 'custom') {
      dimensions = {
        width: parseFloat(customSize.width),
        depth: parseFloat(customSize.depth),
        height: parseFloat(customSize.height)
      };
    }
  
    setSelectedAppliances(prev => {
      const exists = prev.find(a => a.id === currentAppliance.id);
      if (exists) {
        return prev.map(a => a.id === currentAppliance.id ? 
          { ...a, dimensions, additionalInfo: customSize.additionalInfo } : a);
      } else {
        return [...prev, { 
          ...currentAppliance, 
          dimensions, 
          additionalInfo: customSize.additionalInfo 
        }];
      }
    });
  
    closeDialog();
  };

  const isCustomSizeValid = () => {
    return customSize.width !== '' && 
           customSize.depth !== '' && 
           customSize.height !== '';
  };

  const isStandardSizeValid = () => {
    return standardSize !== '';
  };

  const handleSearchProduct = async () => {
    if (searchProduct) {
      setIsSearching(true);
      const newSearch = {
        "appliance_type": currentAppliance.name.toLowerCase(),
        "prompt": searchProduct
      }
      try {
        const response = await api.post('/bedrock_invoke_lambda', newSearch);
        console.log("search params:", newSearch, "response:", response.data)

        const data = response.data;
        // Assuming the response contains an 'answer' array with the search results
        if (data.answer && Array.isArray(data.answer)) {
          setSearchResults(data.answer);
        }
      } catch (error) {
        console.error('Search failed:', error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleSelectSearchResult = (result) => {
    const dimensions = {
      width: parseFloat(result.W),
      depth: parseFloat(result.D),
      height: parseFloat(result.H),
      productId: result['product #']
    };
    
    setSelectedAppliances(prev => {
      const exists = prev.find(a => a.id === currentAppliance.id);
      if (exists) {
        return prev.map(a => a.id === currentAppliance.id ? 
          { ...a, dimensions, productId: result['product #'] } : a);
      } else {
        return [...prev, { 
          ...currentAppliance, 
          dimensions, 
          productId: result['product #'] 
        }];
      }
    });
  
    closeDialog();
  };

  const handleRemoveAppliance = (applianceId) => {
    setSelectedAppliances(prev => prev.filter(a => a.id !== applianceId));
  };

  

  const renderSearchResults = () => {
    if (!searchResults.length) return null;
  
    return (
      <div className="mt-4">
        <h3 className="font-semibold mb-2">Search Results</h3>
        <div className="max-h-60 overflow-y-auto">
          {searchResults.map((result, index) => (
            <div key={index} className="flex items-center justify-between p-2 border-b hover:bg-gray-50">
              <div className="flex-1">
                <p className="font-medium">{result.brand} - {result['product #']}</p>
                <p className="text-sm text-gray-600">
                  W: {result.W}" × D: {result.D}" × H: {result.H}"
                </p>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => window.open(result.search_link, '_blank')}
                >
                  <FaExternalLinkAlt className="w-4 h-4" />
                </Button>
                <Button
                  size="sm"
                  onClick={() => handleSelectSearchResult(result)}
                  className="px-6"
                >
                  Select
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Update the product search section in the Dialog
  const renderProductSearch = () => (
    <div className="space-y-4">
      <div>
        <Label htmlFor="searchProduct">Search {currentAppliance?.name || 'Product'}</Label>
        <div className="flex mt-1">
          <Input
            id="searchProduct"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
            className="flex-grow"
            placeholder="Enter Model, Brand or any description"
          />
          <Button 
            onClick={handleSearchProduct} 
            className="ml-2"
            disabled={isSearching}
          >
            <FaSearch className="mr-2" />
            {isSearching ? 'Searching...' : 'Search'}
          </Button>
        </div>
      </div>
      {renderSearchResults()}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8">
    <ProjectHeader showBackButton={true}/>
    <main className="pt-20 px-4 text-center">
      <h1 className="text-3xl font-bold mb-8">Select the appliances to incorporate in your design</h1>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>    
        <Card className="max-w-6xl mx-auto mb-8">
          <CardContent className="pt-4 px-4 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4">
            {applianceData.map(appliance => {
              const Icon = appliance.icon;
              const isSelected = selectedAppliances.some(a => a.id === appliance.id);
              return (
                <button
                  key={appliance.id}
                  onClick={() => openDialog(appliance)}
                  className={`p-4 rounded-lg flex flex-col items-center justify-center transition-colors ${
                    isSelected ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'
                  }`}
                >
                  <Icon size={32} width={32} height={32}/>
                  <span className="mt-2 text-sm">{appliance.name}</span>
                </button>
              );
            })}
          </CardContent>
        </Card>
        <Card className="max-w-6xl mx-auto mb-8">
          <CardHeader>
            <CardTitle>Selected Appliances</CardTitle>
          </CardHeader>
          <CardContent className="pt-4 px-4 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4">

            {selectedAppliances.map(appliance => (
              <div key={appliance.id} className="mb-4 p-4 bg-gray-100 rounded-lg">
                <h3 className="font-semibold">{appliance.name}</h3>
                {appliance.dimensions && (
                  <p className="mt-2">
                    {/* {`Width: ${appliance.dimensions.width}"`}
                    {`Depth: ${appliance.dimensions.depth}"`}
                    {`Height: ${appliance.dimensions.height}"`} */}
                    <p>Width: {appliance.dimensions.width}"</p>
                    <p>Depth: {appliance.dimensions.depth}"</p>
                    <p>Height: {appliance.dimensions.height}"</p>
                    {appliance.additionalInfo && ` - ${appliance.additionalInfo}`}
                    {appliance.productId && ` (Product #: ${appliance.productId})`}
                  </p>
                )}
                <div className="flex items-center justify-between">
                  <Button onClick={() => openDialog(appliance)} variant="secondary">
                    Edit
                  </Button>
                  <Button 
                    onClick={() => handleRemoveAppliance(appliance.id)}
                    variant="secondary"
                    size="icon"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
        <Button onClick={handleComplete}>Complete This Section</Button>
        </>
      )}
      </main>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{currentAppliance?.name} Details</DialogTitle>
          </DialogHeader>
          <RadioGroup value={sizeType} onValueChange={setSizeType}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="standard" id="standard" />
              <Label htmlFor="standard">Standard Size</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="custom" id="custom" />
              <Label htmlFor="custom">Custom Size</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="product" id="product" />
              <Label htmlFor="product">Search Product</Label>
            </div>
          </RadioGroup>

          {sizeType === 'standard' && (
            <div className="space-y-4">
              <Label>Select Standard Size</Label>
              <select
                value={standardSize}
                onChange={(e) => setStandardSize(e.target.value)}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a size</option>
                {standardSizes[currentAppliance?.id]?.map((size, index) => (
                  <option 
                    key={index} 
                    value={JSON.stringify(size)}
                  >
                    {`W: ${size.width}" × D: ${size.depth}" × H: ${size.height}"`}
                    {/* <p>Width: {size.width}" </p>
                    <p>Depth: {size.depth}" </p>
                    <p>Height: {size.height}"</p> */}
                  </option>
                ))}
              </select>
            </div>
          )}


          {sizeType === 'custom' && (
            <div className="space-y-4">
              <div>
                <Label htmlFor="width">Width (inches)</Label>
                <Input
                  id="width"
                  value={customSize.width}
                  onChange={(e) => setCustomSize({...customSize, width: e.target.value})}
                />
              </div>
              <div>
                <Label htmlFor="depth">Depth (inches)</Label>
                <Input
                  id="depth"
                  value={customSize.depth}
                  onChange={(e) => setCustomSize({...customSize, depth: e.target.value})}
                />
              </div>
              <div>
                <Label htmlFor="height">Height (inches)</Label>
                <Input
                  id="height"
                  value={customSize.height}
                  onChange={(e) => setCustomSize({...customSize, height: e.target.value})}
                />
              </div>
              <div>
                <Label htmlFor="additionalInfo">Additional Info</Label>
                <Input
                  id="additionalInfo"
                  value={customSize.additionalInfo}
                  onChange={(e) => setCustomSize({...customSize, additionalInfo: e.target.value})}
                />
              </div>
            </div>
          )}

          {sizeType === 'product' && renderProductSearch()}
          <DialogFooter>
            <Button onClick={closeDialog} variant="secondary">Cancel</Button>
            {sizeType !== 'product' && (
              <Button 
                onClick={handleSaveAppliance}
                disabled={
                  (sizeType === 'custom' && !isCustomSizeValid()) ||
                  (sizeType === 'standard' && !isStandardSizeValid())
                }
              >
                Save
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApplianceSelector;