import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const convertToInches = (value, unit) => {
  switch (unit) {
    case 'ft': return value * 12;
    case 'm': return value * 39.3701;
    case 'cm': return value / 2.54;
    default: return value;
  }
};

export const convertFromInches = (value, unit) => {
  switch (unit) {
    case 'ft': return value / 12;
    case 'm': return value / 39.3701;
    case 'cm': return value * 2.54;
    default: return value;
  }
};

export const formatValue = (value, unit) => {
  const converted = convertFromInches(value, unit);
  if (converted === 0) return '0';
  const rounded = Math.round(converted * 100) / 100;
  return rounded.toString();
};