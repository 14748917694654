import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Trash2, Edit2, Check, X, Loader2, EditIcon, PencilIcon } from 'lucide-react';
import ProjectHeader from '@/components/header';
import { useAuthState } from '@/hooks/AuthContext';
import { ProjectService } from '@/services/ProjectService';

const UserProjects = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editName, setEditName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuthState();
    const userId = user?.id || 'defaultUserId';

    useEffect(() => {
        async function fetchProjects() {
            try {
                setIsLoading(true);
                const userProjects = await ProjectService.getUserProjects(userId);
                // Sort projects by createdAt in descending order (newest first)
                const sortedProjects = userProjects.sort((a, b) => 
                    parseInt(b.createdAt) - parseInt(a.createdAt)
                );
                setProjects(sortedProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchProjects();
    }, [userId]);

    const handleStartNewProject = () => {
        navigate('/new-project');
    };

    const handleDeleteProject = (projectId) => {
        if (window.confirm("Are you sure you want to delete this project?")) {
            async function fetchDeleteProject() {
                await ProjectService.deleteProject(projectId);
                setProjects(projects.filter(project => project.id !== projectId));
            }
            fetchDeleteProject();
        }
    };

    const handleEditName = (project) => {
        setEditingId(project.id);
        setEditName(project.name);
    };

    const handleSaveName = (projectId) => {
        if (editName.trim() !== '') {
            async function fetchUpdateProject() {
                const updatedProject = await ProjectService.updateProjectName(projectId, editName);
                const updatedProjects = projects.map(p => 
                    p.id === projectId ? updatedProject : p
                );
                // Re-sort after updating
                const sortedProjects = updatedProjects.sort((a, b) => 
                    parseInt(b.createdAt) - parseInt(a.createdAt)
                );
                setProjects(sortedProjects);
                setEditingId(null);
            }
            fetchUpdateProject();
        }
    };

    const handleCancelEdit = () => {
        setEditingId(null);
        setEditName('');
    };

//     return (
//         <div className="min-h-screen bg-gray-100 p-6">
//             <ProjectHeader showHomeButton={false} />

//             <main className="pt-24 px-4 text-center">
//                 <h2 className="text-2xl font-semibold mb-4">Your Design Projects</h2>
                
//                 {isLoading ? (
//                     <div className="flex flex-col items-center justify-center py-12">
//                         <Loader2 className="h-8 w-8 animate-spin text-blue-500 mb-4" />
//                         <p className="text-gray-600">Loading your projects...</p>
//                     </div>
//                 ) : projects.length === 0 ? (
//                     <p className="mb-8 text-gray-600">You don't have any projects yet. Start designing your dream kitchen!</p>
//                 ) : (
//                     <div className="mb-8">
//                         <h3 className="text-xl font-semibold mb-4">Existing Projects:</h3>
//                         <ul className="space-y-4">
//                             {projects.map(project => (
//                                 <li key={project.id} className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
//                                     <div className="flex-grow">
//                                         {editingId === project.id ? (
//                                             <div className="flex items-center">
//                                                 <Input
//                                                     value={editName}
//                                                     onChange={(e) => setEditName(e.target.value)}
//                                                     className="mr-2"
//                                                 />
//                                                 <Button
//                                                     onClick={() => handleSaveName(project.id)}
//                                                     className="mr-2 bg-green-500 hover:bg-green-400 text-white p-2"
//                                                 >
//                                                     <Check size={20} />
//                                                 </Button>
//                                                 <Button
//                                                     onClick={handleCancelEdit}
//                                                     className="bg-gray-500 hover:bg-gray-400 text-white p-2"
//                                                 >
//                                                     <X size={20} />
//                                                 </Button>
//                                             </div>
//                                         ) : (
//                                             <>
//                                                 <h4 className="font-semibold">{project.name}</h4>
//                                                 <p className="text-sm text-gray-500">Created: {new Date(parseInt(project.createdAt)).toLocaleDateString('en-US', {
//                                                     year: 'numeric',
//                                                     month: 'long',
//                                                     day: 'numeric'
//                                                 })}</p>
//                                             </>
//                                         )}
//                                     </div>

//                                     <div className="flex items-center">
//                                         {editingId !== project.id && (
//                                             <Button
//                                                 onClick={() => handleEditName(project)}
//                                                 className="mr-2 bg-white-100 hover:bg-gray-400 text-black p-2"
//                                             >
//                                                 <EditIcon size={20} />
//                                             </Button>
//                                         )}
//                                         <Button 
//                                             onClick={() => navigate(`/new-kitchen-project/${project.id}${project.status === 'submitted' ? '/summary' : ''}`)}
//                                             className={`mr-2 ${project.status === 'submitted' 
//                                                 ? 'bg-green-500 hover:bg-green-400' 
//                                                 : 'bg-blue-500 hover:bg-blue-400'} text-white`}
//                                         >
//                                             {project.status === 'submitted' ? 'Submitted' : 'Continue'}
//                                         </Button>
//                                         <Button
//                                             onClick={() => handleDeleteProject(project.id)}
//                                             className="bg-white-500 hover:bg-gray-400 text-black p-2"
//                                         >
//                                             <Trash2 size={20} />
//                                         </Button>
//                                     </div>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 )}

//                 <Button onClick={handleStartNewProject} size="lg" className="bg-blue-900 hover:bg-blue-800 text-white px-6 py-3">
//                     Start New Project
//                 </Button>
//             </main>
//         </div>
//     );
// };


    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <ProjectHeader showHomeButton={false} />

            <main className="pt-14 px-4 text-center">
                <h2 className="text-2xl font-semibold mb-4">Your Design Projects</h2>
                
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center py-12">
                        <Loader2 className="h-8 w-8 animate-spin text-blue-500 mb-4" />
                        <p className="text-gray-600">Loading your projects...</p>
                    </div>
                ) : projects.length === 0 ? (
                    <p className="mb-8 text-gray-600">You don't have any projects yet. Start designing your dream kitchen!</p>
                ) : (
                    <div className="mb-8">
                        <h3 className="text-xl font-semibold mb-4">Existing Projects:</h3>
                        <ul className="space-y-4 max-w-screen-sm mx-auto">
                            {projects.map(project => (
                                <li key={project.id} className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
                                    <div className="flex-grow">
                                        {editingId === project.id ? (
                                            <div className="flex items-center">
                                                <Input
                                                    value={editName}
                                                    onChange={(e) => setEditName(e.target.value)}
                                                    className="mr-2"
                                                />
                                                <Button
                                                    onClick={() => handleSaveName(project.id)}
                                                    className="mr-2 bg-green-500 hover:bg-green-400 text-white p-2"
                                                >
                                                    <Check size={20} />
                                                </Button>
                                                <Button
                                                    onClick={handleCancelEdit}
                                                    className="bg-gray-500 hover:bg-gray-400 text-white p-2"
                                                >
                                                    <X size={20} />
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col">
                                                <div className="flex items-center">
                                                    <h4 className="font-semibold">{project.name}</h4>
                                                    {editingId !== project.id && (
                                                        <Button
                                                            onClick={() => handleEditName(project)}
                                                            className="ml-2 p-0 h-auto bg-transparent hover:bg-transparent"
                                                            variant="ghost"
                                                        >
                                                            <PencilIcon size={16} className="text-gray-500 hover:text-gray-700" />
                                                        </Button>
                                                    )}
                                                </div>
                                                <p className="text-sm text-gray-500 text-left">Created: {new Date(parseInt(project.createdAt)).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                })}</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex items-center">
                                        <Button 
                                            onClick={() => navigate(`/new-kitchen-project/${project.id}${project.status === 'submitted' ? '/summary' : ''}`)}
                                            className={`mr-2 ${project.status === 'submitted' 
                                                ? 'bg-green-500 hover:bg-green-400' 
                                                : 'bg-blue-500 hover:bg-blue-400'} text-white`}
                                        >
                                            {project.status === 'submitted' ? 'Submitted' : 'Continue'}
                                        </Button>
                                        <Button
                                            onClick={() => handleDeleteProject(project.id)}
                                            className="bg-white-500 hover:bg-gray-400 text-red-600 p-2"
                                        >
                                            <Trash2 size={20} />
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <Button onClick={handleStartNewProject} size="lg" className="bg-blue-900 hover:bg-blue-800 text-white px-6 py-3">
                    Start New Project
                </Button>
            </main>
        </div>
    );
};

export default UserProjects;