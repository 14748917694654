import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check, AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction } from "@/components/ui/alert-dialog";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';
import KitchenLayoutViewer from '@/utils/roomDrawingFunctions';
import { Download, ExternalLink } from "lucide-react"


const ProjectSummary = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        const fetchProjectData = async () => {
        try {
            const project = await ProjectService.getProjectById(projectId);
            setProjectData(project);
        } catch (error) {
            console.error('Error fetching project data:', error);
        } finally {
            setLoading(false);
        }
        };

        fetchProjectData();
    }, [projectId]);



    const handleSubmitProject = async () => {
        try {
        await ProjectService.submitProject(projectId);
        setShowConfirmation(true);
        } catch (error) {
        console.error('Error submitting project:', error);
        }
    };

    const handleDone = () => {
        setShowConfirmation(false);
        navigate('/projects');
    };

    const handleEditSection = (path) => {
        navigate(`/new-kitchen-project/${projectId}/${path}`);
    };

    if (loading) {
        return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
        </div>
        );
    }

    const ApplianceSummary = ({ appliances }) => {
        if (!appliances || appliances.length === 0) {
        return <p className="text-gray-500">No appliances selected</p>
        }
    
        return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {appliances.map((appliance, index) => (
            <Card key={index} className="p-4">
                <h3 className="font-semibold mb-2">{appliance.name}</h3>
                {appliance.dimensions && (
                <div className="text-sm text-gray-600">
                    <p>Width: {appliance.dimensions.width}"</p>
                    <p>Depth: {appliance.dimensions.depth}"</p>
                    <p>Height: {appliance.dimensions.height}"</p>
                    {appliance.productId && (
                    <p className="mt-1">Product #: {appliance.productId}</p>
                    )}
                    {appliance.additionalInfo && (
                    <p className="mt-1 italic">{appliance.additionalInfo}</p>
                    )}
                </div>
                )}
            </Card>
            ))}
        </div>
        )
    }

    const AdditionalInfoSummary = ({ details }) => {
        if (!details) return <p className="text-gray-500">No additional information provided</p>;
        
        const parsedDetails = typeof details === 'string' ? JSON.parse(details) : details;
    
        const NotSpecified = () => (
        <span className="text-red-500 font-medium">Not specified</span>
        );
    
        return (
        <div className="space-y-6">
            {/* Project Type and Purpose */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
                <h3 className="font-semibold mb-2">Project Type</h3>
                <p className="text-gray-700">
                {parsedDetails.projectType === 'new' ? 'New Construction' : 
                parsedDetails.projectType === 'remodel' ? 'Renovation/Remodeling' : 
                <NotSpecified />}
                </p>
            </div>
            
            <div>
                <h3 className="font-semibold mb-2">Kitchen Purpose</h3>
                <p className="text-gray-700">
                {parsedDetails.kitchenPurpose ? (
                    parsedDetails.kitchenPurpose === 'other' 
                    ? parsedDetails.otherPurpose || <NotSpecified />
                    : {
                        'daily': 'Daily Family Use',
                        'secondary': 'Secondary Kitchen',
                        'rental': 'Rental Property',
                        'holiday': 'Holiday/Passover Kitchen',
                        'forever': 'Forever Home Kitchen'
                        }[parsedDetails.kitchenPurpose]
                ) : <NotSpecified />}
                </p>
            </div>
            </div>
    
            {/* Timeline and Budget */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
                <h3 className="font-semibold mb-2">Timeline</h3>
                <p className="text-gray-700">
                {parsedDetails.timeline ? (
                    parsedDetails.timeline === 'future' 
                    ? `Planning for the future: ${parsedDetails.futureTimelineDetails || <NotSpecified />}`
                    : {
                        'exploring': 'Just exploring options',
                        'immediate': 'Ready to start now'
                        }[parsedDetails.timeline]
                ) : <NotSpecified />}
                </p>
            </div>
            
            <div>
                <h3 className="font-semibold mb-2">Budget Range</h3>
                <p className="text-gray-700">
                {parsedDetails.budget ? {
                    'under30k': 'Under $30,000',
                    '30k-50k': '$30,000 - $50,000',
                    '50k-75k': '$50,000 - $75,000',
                    '75k-100k': '$75,000 - $100,000',
                    'above100k': 'Above $100,000',
                    'notsure': 'Exploring options'
                }[parsedDetails.budget] : <NotSpecified />}
                </p>
            </div>
            </div>
    
            {/* Budget Inclusions */}
            <div>
            <h3 className="font-semibold mb-2">Budget Includes</h3>
            {parsedDetails.budgetIncludes?.length > 0 ? (
                <div className="grid grid-cols-2 gap-2">
                {parsedDetails.budgetIncludes.map((item) => (
                    <div key={item} className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-green-500" />
                    <span className="text-gray-700">{item}</span>
                    </div>
                ))}
                </div>
            ) : <NotSpecified />}
            </div>
    
            {/* Cooking Style */}
            <div>
            <h3 className="font-semibold mb-2">Kitchen Usage</h3>
            <div className="space-y-4">
                <div>
                <p className="text-gray-600 font-medium">Number of Cooks:</p>
                <p className="text-gray-700">
                    {parsedDetails.numberOfCooks ? {
                    'solo': 'Usually one person',
                    'duo': 'Two people regularly',
                    'family': 'Multiple family members',
                    'team': 'Multiple cooks during events'
                    }[parsedDetails.numberOfCooks] : <NotSpecified />}
                </p>
                </div>
                
                <div>
                <p className="text-gray-600 font-medium">Cooking Activities:</p>
                {parsedDetails.cookingStyle?.length > 0 ? (
                    <div className="grid grid-cols-2 gap-2 mt-2">
                    {parsedDetails.cookingStyle.map((style) => (
                        <div key={style} className="flex items-center gap-2">
                        <Check className="h-4 w-4 text-green-500" />
                        <span className="text-gray-700">{style}</span>
                        </div>
                    ))}
                    </div>
                ) : <NotSpecified />}
                </div>
            </div>
            </div>
    
            {/* Previous Kitchen Experience */}
            <div className="space-y-4">
            <h3 className="font-semibold mb-2">Kitchen Experience</h3>
            <div>
                <p className="text-gray-600 font-medium">Likes from Previous Kitchen:</p>
                <p className="text-gray-700">
                {parsedDetails.previousKitchenLikes || <NotSpecified />}
                </p>
            </div>
            <div>
                <p className="text-gray-600 font-medium">Desired Improvements:</p>
                <p className="text-gray-700">
                {parsedDetails.previousKitchenDislikes || <NotSpecified />}
                </p>
            </div>
            </div>
    
            {/* Additional Notes */}
            <div>
            <h3 className="font-semibold mb-2">Additional Notes</h3>
            <p className="text-gray-700">
                {parsedDetails.additionalNotes || <NotSpecified />}
            </p>
            </div>
        </div>
        );
    };

    const FileLinkSummary = ({ fileItems, linkItems }) => {
        if (!fileItems?.length && !linkItems?.length) {
            return <p className="text-gray-500">No files or links added</p>
    }

    const handleDownload = async (file) => {
        try {
        const downloadedFile = await ProjectService.downloadFileFromS3(file.s3Key)
        const url = window.URL.createObjectURL(downloadedFile)
        const a = document.createElement('a')
        a.href = url
        a.download = file.name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        } catch (error) {
        console.error('Error downloading file:', error)
        }
    }

    return (
        <div className="space-y-6">
        {fileItems?.length > 0 && (
            <div>
            <h3 className="font-semibold mb-3">Uploaded Files</h3>
            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-4">
                {fileItems.map((file) => (
                <Card key={file.id} className="p-3">
                    <div className="aspect-square mb-2 bg-gray-100 rounded-lg overflow-hidden">
                    {file.thumbnail ? (
                        <img
                        src={file.thumbnail}
                        alt={file.name}
                        className="w-full h-full object-cover"
                        />
                    ) : (
                        <div className="w-full h-full flex items-center justify-center text-gray-400">
                        No preview
                        </div>
                    )}
                    </div>
                    <div className="text-sm">
                    <p className="font-medium truncate">{file.name}</p>
                    {file.notes && (
                        <p className="text-gray-600 mt-1 line-clamp-2">{file.notes}</p>
                    )}
                    <Button 
                        variant="outline" 
                        size="sm" 
                        className="mt-2 w-full"
                        onClick={() => handleDownload(file)}
                    >
                        <Download className="w-4 h-4 mr-2" />
                        Download
                    </Button>
                    </div>
                </Card>
                ))}
            </div>
            </div>
        )}

        {linkItems?.length > 0 && (
            <div>
            <h3 className="font-semibold mb-3">Saved Links</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {linkItems.map((link) => (
                <Card key={link.id} className="p-3">
                    <div className="flex justify-between items-start gap-2">
                    <div className="flex-1">
                        <a 
                        href={link.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 hover:underline text-sm break-all"
                        >
                        {link.url}
                        </a>
                        {link.notes && (
                        <p className="text-sm text-gray-600 mt-1 line-clamp-2">{link.notes}</p>
                        )}
                    </div>
                    <Button variant="outline" size="icon" asChild>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                        <ExternalLink className="w-4 h-4" />
                        </a>
                    </Button>
                    </div>
                </Card>
                ))}
            </div>
            </div>
        )}
        </div>
    )
    };

    const renderSummarySection = (title, content, editPath) => (
        <Card className="mb-4">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-xl font-bold">{title}</CardTitle>
                {projectData?.status === 'in_progress' && (
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleEditSection(editPath)}
                    >
                        Edit
                    </Button>
                )}
            </CardHeader>
            <CardContent>{content}</CardContent>
        </Card>
    );

    return (
    <div className="min-h-screen bg-gray-100 pb-24">
        <ProjectHeader showBackButton={true} />
      <main className="pt-20 px-4 max-w-4xl mx-auto">
        <div className="mb-8">

          
          <h1 className="text-3xl font-bold mb-2">Project Summary</h1>
          <p className="text-gray-600">
            {projectData?.status === 'submitted' 
              ? 'Your project has been submitted. Here\'s a summary of your requirements.'
              : 'Review your project details before submission. You can still make changes if needed.'}
              </p>
        </div>

        {!projectData?.status && (
          <Alert className="mb-6">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Please review all details carefully before submitting. You can edit any section by clicking the Edit button.
            </AlertDescription>
          </Alert>
        )}

        {renderSummarySection(
          "Style & Inspiration",
          <div className="space-y-2">
            <p><strong>Selected Style:</strong> {projectData?.selectedStyle}</p>
            <p><strong>Color Scheme:</strong> {projectData?.selectedMoodboard}</p>
          </div>,
          "style"
        )}

        {renderSummarySection(
        "Appliances",
        <ApplianceSummary appliances={projectData?.appliances} />,
        "appliances"
        )}

        {renderSummarySection(
            "Layout & Dimensions",
            <div className="space-y-4">
                <div className="space-y-2">
                </div>
                {projectData?.floorPlan && (
                <KitchenLayoutViewer 
                    floorPlanData={projectData.floorPlan}
                    kitchenLayout={projectData.kitchenLayout || []}
                />
                )}
            </div>,
            "layout"
        )}

        {renderSummarySection(
        "Additional Information",
        <AdditionalInfoSummary details={projectData?.kitchenDetails} />,
        "needs"
        )}

        {renderSummarySection(
        "Files & Links",
        <FileLinkSummary 
            fileItems={projectData?.fileItems ? JSON.parse(projectData.fileItems) : []}
            linkItems={projectData?.linkItems ? JSON.parse(projectData.linkItems) : []}
        />,
        "upload"
        )}

        {projectData?.status == 'in_progress' && (
          <div className="mt-8 flex justify-end">
            <Button
              size="lg"
              onClick={handleSubmitProject}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              <Check className="mr-2 h-5 w-5" /> Request design and quote
            </Button>
          </div>
        )}

        <AlertDialog open={showConfirmation} onOpenChange={setShowConfirmation}>
          <AlertDialogContent className="max-w-md">
            <AlertDialogHeader>
              <AlertDialogTitle className="text-2xl font-bold text-center mb-2">
                Congratulations! 🎉
              </AlertDialogTitle>
              <AlertDialogDescription className="text-center space-y-4">
                <p>Thank you for submitting your kitchen project!</p>
                <p>Our team will carefully review your files and requirements to provide you with a custom design and detailed quote.</p>
                <p className="font-medium">Please check your email for updates on your project.</p>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex justify-center">
              <AlertDialogAction 
                onClick={handleDone}
                className="bg-blue-600 hover:bg-blue-700 text-white"
              >
                Done
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </main>
    </div>
  );
};

export default ProjectSummary;