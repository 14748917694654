import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { AuthProvider, useAuthState } from '@/hooks/AuthContext';

import CallbackPage from './CallbackPage';
import Banner from './Pages/Banner';
import Welcome from './Pages/Welcome';
import Projects from './Pages/projects';
import NewKitchenProject from './Pages/NewKitchenProject';
import UserInspirationScreen from './Pages/UserInspirationScreen';
import User3DViewerScreen from './Pages/User3DViewerScreen';
import UserMoodboardScreen from './Pages/UserMoodboardScreen';
import FloorPlanScreen from './Pages/FloorPlan';
import ApplianceSelector from './Pages/appliances';
import ProjectInitiationScreen from './Pages/ProjectInitiationScreen';
import FileUploadScreen from './Pages/UploadFiles';
import AdditionalInfo from './Pages/AdditionalInfo';
import ProjectSummary from './Pages/ProjectSummaryPage';
import Soon from './Pages/Soon';

const hostname = window.location.hostname;
const getBaseUrl = () => {
    switch (true) {
        case hostname === 'localhost' || hostname === '127.0.0.1':
            return 'http://localhost:3000';
        case hostname.includes('amplifyapp.com'):
            return 'https://main.d35u21yvpilhs2.amplifyapp.com';
        case hostname.includes('gnibox.com'):
            return 'https://gnibox.com';
        case hostname.includes('dev.gnibox.com'):
            return 'https://dev.gnibox.com';
        default:
            console.warn('Unknown environment, using localhost');
            return 'http://localhost:3000';
    }
};
const itsProd = hostname.includes('gnibox.com') && !hostname.includes('dev.gnibox.com');

console.log(itsProd);
const baseUrl = getBaseUrl();
// const KINDE_CONFIG = {
//     clientId: process.env.REACT_APP_KINDE_CLIENT_ID,
//     domain: process.env.REACT_APP_KINDE_DOMAIN,
//     logoutUri: `${baseUrl}/welcome`,
//     redirectUri: `${baseUrl}/callback`,
// };
const KINDE_CONFIG = {
    clientId: 'bd2ddbb664934f3185a4723d92e53f14',
    domain: 'https://gni1.kinde.com',
    logoutUri: `${baseUrl}/welcome`,
    redirectUri: `${baseUrl}/callback`,
};

const AuthenticatedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuthState();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return             <Navigate to={process.env.PUBLIC_URL + '/commingsoon.html'} replace/>;
    }

    return children;
};

const App = () => {
    return (
        <KindeProvider {...KINDE_CONFIG}>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={itsProd ? <Soon /> : <Banner />} />
                        <Route path="/callback" element={itsProd ? <Soon /> : <CallbackPage />} />
                        <Route path="/welcome" element={itsProd ? <Soon /> : <Welcome />} />
                        <Route
                            path="/projects"
                            element={
                                <AuthenticatedRoute>
                                    <Projects />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-project"
                            element={
                                <AuthenticatedRoute>
                                    <ProjectInitiationScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId"
                            element={
                                <AuthenticatedRoute>
                                    <NewKitchenProject />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/style"
                            element={
                                <AuthenticatedRoute>
                                    <UserInspirationScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/moodboard"
                            element={
                                <AuthenticatedRoute>
                                    <UserMoodboardScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/3D"
                            element={
                                <AuthenticatedRoute>
                                    <User3DViewerScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/layout"
                            element={
                                <AuthenticatedRoute>
                                    <FloorPlanScreen flowId="layout" />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/needs"
                            element={
                                <AuthenticatedRoute>
                                    <AdditionalInfo flowId="needs" />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/upload"
                            element={
                                <AuthenticatedRoute>
                                    <FileUploadScreen flowId="upload" />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/appliances"
                            element={
                                <AuthenticatedRoute>
                                    <ApplianceSelector flowId="appliances" />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/new-kitchen-project/:projectId/summary"
                            element={
                                <AuthenticatedRoute>
                                    <ProjectSummary />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route path="*" element={itsProd ? <Soon /> : <Navigate to="/welcome" replace />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </KindeProvider>
    );
};
// };

export default App;
