import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';

const AdditionalInfo = ({ flowId }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const SAVE_DELAY = 10000; // 10 seconds

  // Consolidated form state
  const [formData, setFormData] = useState({
    projectType: '',
    kitchenPurpose: '',
    otherPurpose: '',
    timeline: '',
    futureTimelineDetails: '',
    budget: '',
    budgetIncludes: [],
    cookingStyle: [],
    numberOfCooks: '',
    previousKitchenLikes: '',
    previousKitchenDislikes: '',
    additionalNotes: ''
  });

  // Load saved data
  useEffect(() => {
    const loadProjectData = async () => {
      try {
        setIsLoading(true);
        const project = await ProjectService.getProjectById(projectId);
        if (project.kitchenDetails) {
          const savedData = project.kitchenDetails;
          setFormData(savedData);
        }
      } catch (error) {
        console.error('Error loading project data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProjectData();
  }, [projectId]);

  // Warning when leaving page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasChanges]);

  // Auto-save functionality with throttling
  const saveFormData = useCallback(async (data) => {
    if (!isSaving) { // Prevent multiple simultaneous saves
      console.log("data:", data, "data.type:", data.type)
      try {
        setIsSaving(true);
        await ProjectService.updateProject(projectId, {
          kitchenDetails: data
        });
        setLastSaved(new Date());
        setHasChanges(false);
      } catch (error) {
        console.error('Error saving form data:', error);
      } finally {
        setIsSaving(false);
      }
    }
  }, [projectId, isSaving]);

  // Create a single debounced save instance
  const debouncedSave = useMemo(
    () => debounce((data) => {
      if (!isSaving) {
        saveFormData(data);
      }
    }, SAVE_DELAY, { maxWait: SAVE_DELAY * 2 }), // maxWait ensures a save happens at least every 10 seconds
    [saveFormData]
  );

  // Handle form changes
  const updateFormData = useCallback((field, value) => {
    setFormData(prev => {
      const newData = { ...prev, [field]: value };
      setHasChanges(true);
      
      // Only trigger save if not already saving
      if (!isSaving) {
        debouncedSave(newData);
      }
      
      return newData;
    });
  }, [debouncedSave, isSaving]);

  // Cleanup debounced save on unmount and handle unsaved changes
  useEffect(() => {
    return () => {
      debouncedSave.cancel(); // Cancel any pending debounced saves
      
      // If there are unsaved changes, save immediately
      if (hasChanges && !isSaving) {
        saveFormData(formData);
      }
    };
  }, [debouncedSave, hasChanges, formData, saveFormData, isSaving]);

  // Handle complete button
  const handleComplete = async () => {
    try {
      if (hasChanges) {
        await saveFormData(formData);
      }
      await ProjectService.addCompletedFlow(projectId, flowId);
      navigate(`/new-kitchen-project/${projectId}`);
    } catch (error) {
      console.error('Error completing section:', error);
    }
  };

  // Warning when leaving page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasChanges]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
          <p className="text-gray-600">Loading your project details...</p>
        </div>
      </div>
    );
  }

  const renderSaveStatus = () => {
    if (isSaving) {
      return <span className="text-gray-600">Saving...</span>;
    }
    if (lastSaved) {
      return <span className="text-gray-600">Last saved: {new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(lastSaved)}</span>;
    }
    return null;
  };

  const handleCheckboxGroup = (field, item, checked) => {
    updateFormData(field, 
      checked 
        ? [...formData[field], item]
        : formData[field].filter(i => i !== item)
    );
  };

  const renderContent = () => (
    <Card>
      <CardHeader className="text-center">
        <CardTitle className="text-3xl">Just a Few More Details...</CardTitle>
        <p className="text-gray-600 mt-2">
          Help us understand your project better to ensure we create the perfect space for you
        </p>
        <div className="text-sm mt-2">
          {renderSaveStatus()}
        </div>
      </CardHeader>

      <CardContent className="space-y-8">
        {/* Project Type */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold">About Your Project</h3>
          </div>
          <RadioGroup 
            value={formData.projectType}
            onValueChange={(value) => updateFormData('projectType', value)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="new" id="new" />
              <Label htmlFor="new">New Construction</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="remodel" id="remodel" />
              <Label htmlFor="remodel">Renovation/Remodeling</Label>
            </div>
          </RadioGroup>
          
          {formData.projectType === 'remodel' && (
            <div className="ml-6 space-y-2 bg-blue-50 p-4 rounded-lg">
              <Label className="text-blue-800">Share photos of your current kitchen</Label>
              <Input type="file" multiple accept="image/*,video/*" className="bg-white" />
              <p className="text-sm text-blue-600 italic">
                💡 Photos help us better understand your current space and needs
              </p>
            </div>
          )}
        </div>

        {/* Kitchen Purpose */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Kitchen Purpose</h3>
          <RadioGroup 
            value={formData.kitchenPurpose}
            onValueChange={(value) => updateFormData('kitchenPurpose', value)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="daily" id="daily" />
              <Label htmlFor="daily">Daily Family Use</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="secondary" id="secondary" />
              <Label htmlFor="secondary">Secondary Kitchen</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="rental" id="rental" />
              <Label htmlFor="rental">Rental Property</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="holiday" id="holiday" />
              <Label htmlFor="holiday">Holiday/Passover Kitchen</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="forever" id="forever" />
              <Label htmlFor="forever">Forever Home Kitchen</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="other" id="other" />
              <Label htmlFor="other">Other</Label>
            </div>
          </RadioGroup>
          {formData.kitchenPurpose === 'other' && (
            <Input 
              placeholder="Please specify your kitchen's purpose"
              value={formData.otherPurpose}
              onChange={(e) => updateFormData('otherPurpose', e.target.value)}
            />
          )}
        </div>

        {/* Timeline */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Project Timeline</h3>
          <Select
            value={formData.timeline}
            onValueChange={(value) => updateFormData('timeline', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="When would you like to start?" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="exploring">Just exploring options</SelectItem>
              <SelectItem value="immediate">Ready to start now</SelectItem>
              <SelectItem value="future">Planning for the future</SelectItem>
            </SelectContent>
          </Select>
          {formData.timeline === 'future' && (
            <div className="ml-6">
              <Label>When are you planning to start?</Label>
              <Input
                placeholder="e.g., Spring 2024, Next summer, etc."
                value={formData.futureTimelineDetails}
                onChange={(e) => updateFormData('futureTimelineDetails', e.target.value)}
              />
            </div>
          )}
        </div>

        {/* Budget Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Investment Planning</h3>
          <div className="space-y-4">
            <RadioGroup 
              value={formData.budget}
              onValueChange={(value) => updateFormData('budget', value)}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="under30k" id="under30k" />
                <Label htmlFor="under30k">Under $30,000</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="30k-50k" id="30k-50k" />
                <Label htmlFor="30k-50k">$30,000 - $50,000</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="50k-75k" id="50k-75k" />
                <Label htmlFor="50k-75k">$50,000 - $75,000</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="75k-100k" id="75k-100k" />
                <Label htmlFor="75k-100k">$75,000 - $100,000</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="above100k" id="above100k" />
                <Label htmlFor="above100k">Above $100,000</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="notsure" id="notsure" />
                <Label htmlFor="notsure">I'd like to explore options</Label>
              </div>
            </RadioGroup>

            <div className="mt-4 space-y-2">
              <Label>Would you like your budget to include:</Label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  'Cabinets & Hardware',
                  'Countertops',
                  'Appliances',
                  'Installation',
                  'Flooring',
                  'Lighting',
                  'Plumbing fixtures',
                  'Backsplash',
                  'Additional construction'
                ].map((item) => (
                  <div key={item} className="flex items-center space-x-2">
                    <Checkbox 
                      id={item}
                      checked={formData.budgetIncludes.includes(item)}
                      onCheckedChange={(checked) => handleCheckboxGroup('budgetIncludes', item, checked)}
                    />
                    <Label htmlFor={item}>{item}</Label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Cooking Habits */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Cooking Style & Habits</h3>
          <div className="space-y-4">
            <div>
              <Label>How many people typically cook at once?</Label>
              <Select
                value={formData.numberOfCooks}
                onValueChange={(value) => updateFormData('numberOfCooks', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select number of cooks" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="solo">Usually one person</SelectItem>
                  <SelectItem value="duo">Two people regularly</SelectItem>
                  <SelectItem value="family">Multiple family members</SelectItem>
                  <SelectItem value="team">Multiple cooks during events</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Common cooking activities (select all that apply):</Label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  'Quick meals',
                  'Family cooking',
                  'Holiday meals',
                  'Gourmet cooking',
                  'Baking',
                  'Kosher cooking',
                  'Meal prep',
                  'Entertaining'
                ].map((style) => (
                  <div key={style} className="flex items-center space-x-2">
                    <Checkbox 
                      id={style}
                      checked={formData.cookingStyle.includes(style)}
                      onCheckedChange={(checked) => handleCheckboxGroup('cookingStyle', style, checked)}
                    />
                    <Label htmlFor={style}>{style}</Label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Previous Kitchen Experience */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Previous Kitchen Experience</h3>
          <div className="space-y-4">
            <div>
              <Label>What do/did you like about your current/previous kitchen?</Label>
              <Textarea 
                placeholder="Tell us what works well..."
                value={formData.previousKitchenLikes}
                onChange={(e) => updateFormData('previousKitchenLikes', e.target.value)}
              />
            </div>
            <div>
              <Label>What would you like to improve?</Label>
              <Textarea 
                placeholder="Tell us what you'd like to change..."
                value={formData.previousKitchenDislikes}
                onChange={(e) => updateFormData('previousKitchenDislikes', e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Additional Notes */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Anything Else?</h3>
          <p className="text-gray-600">
            Share any other details that would help us understand your needs better
          </p>
          <Textarea 
            placeholder="Additional requirements, unique ideas, or special considerations..."
            value={formData.additionalNotes}
            onChange={(e) => updateFormData('additionalNotes', e.target.value)}
            className="min-h-[100px]"
          />
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="min-h-screen bg-gray-100 pb-24">
      <ProjectHeader showBackButton={true} />
      <main className="pt-20 px-4">
        {renderContent()}
      </main>

      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-center items-center space-x-4 shadow-lg">
        <Button 
          onClick={handleComplete}
          className="min-w-[160px]"
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Complete Section'}
        </Button>
      </div>
    </div>
  );
};


export default AdditionalInfo;