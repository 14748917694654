import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent } from "@/components/ui/card";
import { ProjectService } from '@/services/ProjectService';
import ProjectHeader from '@/components/header';
import { GripVertical, X, ChevronDown } from 'lucide-react';
import { Button } from "@/components/ui/button";

const baseUrl = 'https://gni-public-bucket.s3.amazonaws.com/moodboard-material/gni/';

const UserMoodboardScreen = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [groupedData, setGroupedData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [loading, setLoading] = useState(true);
  const [categoryFilters, setCategoryFilters] = useState({});
  const [availableCategories, setAvailableCategories] = useState({});
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [draggedLayer, setDraggedLayer] = useState(null);
  const [dragOverInfo, setDragOverInfo] = useState(null); // { id: string, position: 'before' | 'after' }
  const [isLayerControlsOpen, setIsLayerControlsOpen] = useState(false);
  const canvasWidth = 800;
  const canvasHeight = 600;
  const thumbnailSize = 200;
  const canvasOffset = 50;

  // Canvas-related state
  const [layers, setLayers] = useState([]);
  const [draggingLayer, setDraggingLayer] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const layerImagesRef = useRef({});
  // Add new state for resize handling
  const [isResizing, setIsResizing] = useState(false);
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });
  const [initialMouse, setInitialMouse] = useState({ x: 0, y: 0 });
  const [resizeStartScale, setResizeStartScale] = useState(1);

  useEffect(() => {
    async function initializeData() {
      // Load project data
      const currentProject = await ProjectService.getProjectById(projectId);
      
      // Load materials data
      try {
        const materialsData = await import('./gniMaterials.json');
        const data = materialsData.default;
        
        // Process materials data
        const { grouped, categories, initialSelected, initialDropdownStates, initialCategoryFilters } = 
          processInitialData(data);
        
        // Update all related states at once
        setGroupedData(grouped);
        setSelectedItems(initialSelected);
        setIsDropdownOpen(initialDropdownStates);
        setCategoryFilters(initialCategoryFilters);
        setAvailableCategories(categories);
        
        // Initialize layers with the selected items
        const initialLayers = Object.entries(initialSelected)
          .filter(([_, item]) => item)
          .map(([type, item], index) => ({
            id: type,
            type,
            item,
            x: canvasOffset + Math.floor(Math.random() * (canvasWidth - thumbnailSize - canvasOffset)),
            y: canvasOffset + Math.floor(Math.random() * (canvasHeight - thumbnailSize - canvasOffset)),
            zIndex: index,
            scale: null
          }));
        
        setLayers(initialLayers);
        setLoading(false);
      } catch (error) {
        console.error('Error loading materials data:', error);
        setLoading(false);
      }
    }

    initializeData();
  }, []); // Run once on mount

  useEffect(() => {
    // Load images for selected items and update canvas
    const loadImagesAndDraw = async () => {
      const imageLoadPromises = Object.entries(selectedItems)
        .filter(([_, item]) => item)
        .map(([type, item]) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = getImageUrl(item);
            img.onload = () => {
              layerImagesRef.current[type] = img;
              resolve();
            };
            img.onerror = resolve; // Continue even if image fails to load
          });
        });

      await Promise.all(imageLoadPromises);
      drawCanvas();
    };

    loadImagesAndDraw();
  }, [selectedItems, layers, selectedLayerId]); // Dependencies combined from multiple effects

  useEffect(() => {
    setLayers(prev => {
      const updatedLayers = prev.map(layer => {
        const selectedItem = selectedItems[layer.type];
        return selectedItem ? { ...layer, item: selectedItem } : layer;
      });

      // Add new layers for any newly selected items
      Object.entries(selectedItems).forEach(([type, item]) => {
        if (item && !updatedLayers.find(layer => layer.type === type)) {
          updatedLayers.push({
            id: type,
            type,
            item,
            x: canvasOffset + Math.floor(Math.random() * (canvasWidth - thumbnailSize - canvasOffset)),
            y: canvasOffset + Math.floor(Math.random() * (canvasHeight - thumbnailSize - canvasOffset)),
            zIndex: updatedLayers.length,
            scale: null
          });
        }
      });

      return updatedLayers;
    });
  }, [selectedItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.querySelectorAll('.material-dropdown');
      const clickedOutside = ![...dropdowns].some(dropdown => 
        dropdown.contains(event.target)
      );

      if (clickedOutside) {
        setIsDropdownOpen(prev => 
          Object.keys(prev).reduce((acc, key) => ({
            ...acc,
            [key]: false
          }), {})
        );
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []); // Run once on mount

  const processInitialData = (data) => {
    const grouped = {};
    const categories = {};
    
    data.forEach(item => {
      // Group items by type
      if (!grouped[item.Type]) {
        grouped[item.Type] = [];
      }
      grouped[item.Type].push(item);

      // Process categories
      if (!categories[item.Type]) {
        categories[item.Type] = new Set();
      }
      
      const itemCategories = Array.isArray(item.Categories) 
        ? item.Categories 
        : [item.Categories];
      
      itemCategories.forEach(category => {
        categories[item.Type].add(category);
      });
    });

    // Process categories into final format
    const finalCategories = Object.entries(categories).reduce((acc, [type, categorySet]) => {
      if (categorySet.size > 1) {
        acc[type] = Array.from(categorySet).sort();
      }
      return acc;
    }, {});

    // Initialize selected items and dropdown states
    const initialSelected = Object.keys(grouped).reduce((acc, type) => ({
      ...acc,
      [type]: grouped[type][0]
    }), {});

    const initialDropdownStates = Object.keys(grouped).reduce((acc, type) => ({
      ...acc,
      [type]: false
    }), {});

    const initialCategoryFilters = Object.keys(grouped).reduce((acc, type) => ({
      ...acc,
      [type]: ''
    }), {});

    return {
      grouped,
      categories: finalCategories,
      initialSelected,
      initialDropdownStates,
      initialCategoryFilters
    };
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Sort layers by z-index before drawing
    const sortedLayers = [...layers].sort((a, b) => a.zIndex - b.zIndex);

    sortedLayers.forEach(layer => {
      const img = layerImagesRef.current[layer.type];
      if (img) {
        // Calculate base scale based on thumbnail size
        const baseScale = Math.min(thumbnailSize / img.width, thumbnailSize / img.height);
        // Use layer's custom scale if it exists, otherwise use base scale
        const currentScale = layer.scale || baseScale;
        const width = img.width * currentScale;
        const height = img.height * currentScale;
        
        // Draw the image
        ctx.drawImage(img, layer.x, layer.y, width, height);
        
        // Draw selection outline if this layer is selected
        if (layer.id === selectedLayerId) {
          ctx.strokeStyle = '#3b82f6';
          ctx.lineWidth = 2;
          ctx.setLineDash([5, 3]);
          ctx.strokeRect(layer.x - 2, layer.y - 2, width + 4, height + 4);
          ctx.setLineDash([]);

          // Draw resize handle
          const handleSize = 20; // Slightly larger to accommodate the circle
          const arrowSize = 15
          const handleX = layer.x + width - handleSize/2;
          const handleY = layer.y + height - handleSize/2;
          
          // Draw white circle with black outline
          ctx.beginPath();
          ctx.arc(handleX + handleSize/2, handleY + handleSize/2, handleSize/2, 0, Math.PI * 2);
          ctx.fillStyle = 'white';
          ctx.fill();
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1;
          ctx.stroke();


          // Draw arrow
          ctx.beginPath();
          ctx.moveTo(handleX, handleY);
          ctx.lineTo(handleX + arrowSize, handleY + arrowSize );
          ctx.moveTo(handleX + arrowSize - 8, handleY + arrowSize);
          ctx.lineTo(handleX + arrowSize, handleY + arrowSize);
          ctx.lineTo(handleX + arrowSize, handleY + arrowSize - 8);
          ctx.lineWidth = 2;
          ctx.stroke();
          
          ctx.stroke();
        }
      }
    });
  };
  
  const getCanvasScale = (canvas) => {
    const rect = canvas.getBoundingClientRect();
    return {
      scaleX: canvas.width / rect.width,
      scaleY: canvas.height / rect.height
    };
  };

  const handleCanvasMouseDown = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const { scaleX, scaleY } = getCanvasScale(canvas);

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    
    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;

    // Check if clicking resize handle of selected layer
    if (selectedLayerId) {
      const selectedLayer = layers.find(l => l.id === selectedLayerId);
      if (selectedLayer) {
        const img = layerImagesRef.current[selectedLayer.type];
        if (img) {
          const baseScale = Math.min(thumbnailSize / img.width, thumbnailSize / img.height);
          const currentScale = selectedLayer.scale || baseScale;
          const width = img.width * currentScale;
          const height = img.height * currentScale;
          
          // Check if click is within resize handle area
          const handleSize = 16;
          const handleX = selectedLayer.x + width - handleSize/2;
          const handleY = selectedLayer.y + height - handleSize/2;
          
          if (x >= handleX && x <= handleX + handleSize &&
              y >= handleY && y <= handleY + handleSize) {
            setIsResizing(true);
            setInitialSize({ width, height });
            setInitialMouse({ x, y });
            setResizeStartScale(currentScale);
            return;
          }
        }
      }
    }

    // Existing layer selection logic
    const clickedLayer = [...layers]
      .sort((a, b) => b.zIndex - a.zIndex)
      .find(layer => {
        const img = layerImagesRef.current[layer.type];
        if (!img) return false;
        
        const baseScale = Math.min(thumbnailSize / img.width, thumbnailSize / img.height);
        const currentScale = layer.scale || baseScale;
        const width = img.width * currentScale;
        const height = img.height * currentScale;

        return (
          x >= layer.x && x <= layer.x + width &&
          y >= layer.y && y <= layer.y + height
        );
      });

    if (clickedLayer) {
      setSelectedLayerId(clickedLayer.id);
      setDraggingLayer(clickedLayer);
      setDragOffset({
        x: x - clickedLayer.x,
        y: y - clickedLayer.y
      });
    } else {
      setSelectedLayerId(null);
    }
  };

  const handleCanvasMouseMove = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const { scaleX, scaleY } = getCanvasScale(canvas);

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    const x = (clientX - rect.left) * scaleX;
    const y = (clientY - rect.top) * scaleY;

    if (isResizing && selectedLayerId) {
      const deltaX = x - initialMouse.x;
      const deltaY = y - initialMouse.y;
      const aspectRatio = initialSize.width / initialSize.height;
      
      // Use the larger delta to maintain aspect ratio
      const scaleDelta = Math.max(deltaX / initialSize.width, deltaY / initialSize.height);
      
      setLayers(prev => prev.map(layer => {
        if (layer.id === selectedLayerId) {
          const img = layerImagesRef.current[layer.type];
          if (!img) return layer;
          
          const baseScale = Math.min(thumbnailSize / img.width, thumbnailSize / img.height);
          const newScale = resizeStartScale * (1 + scaleDelta);
          
          // Limit minimum and maximum scale
          const limitedScale = Math.max(baseScale * 0.5, Math.min(baseScale * 2, newScale));
          
          return {
            ...layer,
            scale: limitedScale
          };
        }
        return layer;
      }));
      return;
    }

    if (draggingLayer) {
      setLayers(prev => prev.map(layer => 
        layer.id === draggingLayer.id
          ? { ...layer, x: x - dragOffset.x, y: y - dragOffset.y }
          : layer
      ));
    }
  };

  const handleCanvasMouseUp = () => {
    setDraggingLayer(null);
    setIsResizing(false);
  };

  const removeLayer = (layerId) => {
    setLayers(prev => {
      const filtered = prev.filter(l => l.id !== layerId);
      // Normalize z-indices after removal
      return filtered.map((layer, index) => ({
        ...layer,
        zIndex: index
      }));
    });
  };

  const getImageUrl = (item) => {
    if (!item || !item.path) return '/api/placeholder/400/320';
    let path = `${baseUrl}${item.Type}/${item.FileName}`.replaceAll(' ', '+');
    return path;
  };

  const toggleDropdown = (type) => {
    setIsDropdownOpen(prev => {
      const newState = { ...prev };
      Object.keys(newState).forEach(key => {
        newState[key] = key === type ? !prev[key] : false;
      });
      return newState;
    });
  };
   
  const handleItemSelect = (type, item) => {
  setSelectedItems(prev => ({
    ...prev,
    [type]: item
  }));

  // Update the layer while preserving position and scale
  setLayers(prev => prev.map(layer => {
    if (layer.type === type) {
      return {
        ...layer,
        item,
        // Preserve existing position and scale
        x: layer.x,
        y: layer.y,
        scale: layer.scale
      };
    }
    return layer;
  }));

  setIsDropdownOpen(prev => ({
    ...prev,
    [type]: false
  }));
};

  const handleCategoryFilter = (type, category) => {
    setCategoryFilters(prev => ({
      ...prev,
      [type]: category
    }));
  };

  const getFilteredItems = (type, items) => {
    const selectedCategory = categoryFilters[type];
    if (!selectedCategory) return items;

    return items.filter(item => {
      const itemCategories = Array.isArray(item.Categories) 
        ? item.Categories 
        : [item.Categories];
      return itemCategories.includes(selectedCategory);
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen">
        <ProjectHeader showBackButton={true}/>
        <main className="pt-20 px-4">
          <div className="flex justify-center items-center h-64">
            <div className="text-lg">Loading materials...</div>
          </div>
        </main>
      </div>
    );
  }

  const handleBack = () => {
    navigate(`/new-kitchen-project/${projectId}/style`);
  };

  const handleComplete = () => {
    async function fetchCompletedFlow() {
      await ProjectService.addCompletedFlow(projectId, 'colors')
      navigate(`/new-kitchen-project/${projectId}`);
    }
    fetchCompletedFlow();
  };

  const handleLayerReorder = (draggedId, droppedId, dropPosition) => {
    if (draggedId === droppedId) return;

    setLayers(prev => {
      // Create a copy of the layers array
      const currentLayers = [...prev];
      
      // Find the dragged layer
      const draggedLayer = currentLayers.find(l => l.id === draggedId);
      if (!draggedLayer) return prev;

      // Find the target layer
      const droppedLayer = currentLayers.find(l => l.id === droppedId);
      if (!droppedLayer) return prev;

      // Remove dragged layer from current position
      const withoutDragged = currentLayers.filter(l => l.id !== draggedId);

      // Calculate new position
      const targetIndex = withoutDragged.findIndex(l => l.id === droppedId);
      const newPosition = dropPosition === 'after' ? targetIndex + 1 : targetIndex;

      // Insert dragged layer at new position
      withoutDragged.splice(newPosition, 0, draggedLayer);

      // Reassign z-indices based on new order (highest number = top layer)
      return withoutDragged.map((layer, index) => ({
        ...layer,
        zIndex: withoutDragged.length - index - 1
      }));
    });
  };

  const renderLayerControls = () => (
    <div className="bg-gray-50 p-4 rounded-lg">
      <button
        onClick={() => setIsLayerControlsOpen(!isLayerControlsOpen)}
        className="w-full flex items-center justify-between mb-2"
      >
        <h3 className="text-lg font-semibold">Layers order</h3>
        <ChevronDown 
          className={`transform transition-transform ${isLayerControlsOpen ? 'rotate-180' : ''}`}
          size={20}
        />
      </button>
      
      {isLayerControlsOpen && (
        <div className="space-y-1">
          {[...layers]
            .sort((a, b) => b.zIndex - a.zIndex)
            .map((layer) => (
              <div 
                key={layer.id}
                className="relative"
              >
                {/* Drop zone above layer */}
                {draggedLayer && draggedLayer.id !== layer.id && (
                  <div 
                    className="absolute w-full h-3 -top-1.5 z-10"
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragOverInfo({ id: layer.id, position: 'before' });
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragOverInfo(null);
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (draggedLayer) {
                        handleLayerReorder(draggedLayer.id, layer.id, 'before');
                      }
                      setDragOverInfo(null);
                      setDraggedLayer(null);
                    }}
                  >
                    {dragOverInfo?.id === layer.id && dragOverInfo?.position === 'before' && (
                      <div className="h-0.5 w-full bg-blue-500" />
                    )}
                  </div>
                )}

                {/* Layer item */}
                <div 
                  className={`flex items-center bg-white rounded shadow-sm
                    ${layer.id === selectedLayerId ? 'ring-2 ring-blue-500' : ''}
                    ${draggedLayer?.id === layer.id ? 'opacity-50' : ''}
                    transition-colors duration-200`}
                  onClick={() => setSelectedLayerId(layer.id)}
                >
                  {/* Drag Handle */}
                  <div
                    className="p-2 cursor-grab hover:bg-gray-100 rounded-l flex items-center"
                    draggable="true"
                    onDragStart={(e) => {
                      e.stopPropagation();
                      setDraggedLayer(layer);
                    }}
                    onDragEnd={(e) => {
                      e.stopPropagation();
                      setDraggedLayer(null);
                      setDragOverInfo(null);
                    }}
                  >
                    <GripVertical className="text-gray-400 hover:text-gray-600" size={16} />
                  </div>

                  {/* Layer Content */}
                  <div className="flex-1 flex items-center justify-between p-2">
                    <span className="text-sm truncate flex-1">
                      <span className="font-bold">{layer.type}</span>: {layer.item?.Item_name?.replace(/_/g, ' ')}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        removeLayer(layer.id);
                        if (selectedLayerId === layer.id) {
                          setSelectedLayerId(null);
                        }
                      }}
                      className="p-1 hover:bg-gray-100 rounded text-red-500"
                    >
                      <X size={16} />
                    </button>
                  </div>
                </div>

                {/* Drop zone below layer */}
                {draggedLayer && draggedLayer.id !== layer.id && (
                  <div 
                    className="absolute w-full h-3 -bottom-1.5 z-10"
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragOverInfo({ id: layer.id, position: 'after' });
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragOverInfo(null);
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (draggedLayer) {
                        handleLayerReorder(draggedLayer.id, layer.id, 'after');
                      }
                      setDragOverInfo(null);
                      setDraggedLayer(null);
                    }}
                  >
                    {dragOverInfo?.id === layer.id && dragOverInfo?.position === 'after' && (
                      <div className="h-0.5 w-full bg-blue-500" />
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen">
      <ProjectHeader showBackButton={true}/>
      <main className="pt-20 px-4 pb-24">
        <div className="flex flex-col lg:flex-row gap-4">
          <Card className="flex-1">
            <CardContent className="p-6">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {Object.entries(groupedData).map(([type, items]) => (
                  <div key={type} className="relative material-dropdown">
                    <div 
                      className="cursor-pointer bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                      onClick={() => toggleDropdown(type)}
                    >
                      <div className="flex items-center justify-between p-2 bg-gray-100 rounded-t-lg">
                        <h3 className="text-lg font-semibold truncate pr-2">{type}</h3>
                        <ChevronDown 
                          className={`transform transition-transform flex-shrink-0 ${isDropdownOpen[type] ? 'rotate-180' : ''}`}
                        />
                      </div>
                      
                      <div>
                        <div className="relative w-full pt-[100%]">
                          <img
                            src={getImageUrl(selectedItems[type])}
                            alt={selectedItems[type]?.Item_name}
                            className="absolute inset-0 w-full h-full object-contain p-4"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/api/placeholder/400/320';
                            }}
                          />
                        </div>
                        <p className="mt-2 text-sm text-center truncate px-2 pb-2">
                          {selectedItems[type]?.Item_name.replace(/_/g, ' ')}
                        </p>
                      </div>
                    </div>
                    
                    {isDropdownOpen[type] && (
                      <div className="absolute left-0 right-0 top-full mt-2 z-50 bg-white rounded-lg shadow-lg overflow-hidden">
                        {/* Category Filter */}
                        {availableCategories[type] && availableCategories[type].length > 0 && (
                          <div className="p-2 border-b">
                            <select
                              className="w-full p-1 text-sm border rounded"
                              value={categoryFilters[type]}
                              onChange={(e) => handleCategoryFilter(type, e.target.value)}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <option value="">All Categories</option>
                              {availableCategories[type].map(category => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        
                        {/* Items List */}
                        <div className="max-h-96 overflow-y-auto">
                          {getFilteredItems(type, items).map((item) => (
                            <div
                              key={item.Item_name}
                              className={`cursor-pointer hover:bg-gray-50 ${
                                selectedItems[type]?.Item_name === item.Item_name ? 'bg-blue-50' : ''
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleItemSelect(type, item);
                              }}
                            >
                              <div className="flex items-center p-2 gap-4">
                                <div className="relative w-16 h-16 flex-shrink-0">
                                  <img
                                    src={getImageUrl(item)}
                                    alt={item.Item_name}
                                    className="absolute inset-0 w-full h-full object-contain"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = '/api/placeholder/400/320';
                                    }}
                                  />
                                </div>
                                <p className="flex-1 text-sm">
                                  {item.Item_name.replace(/_/g, ' ')}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="flex-1">
            <CardContent className="p-6">
              <div className="flex flex-col gap-4">
                {/* Canvas area */}
                <div className="relative bg-gray-50 rounded-lg">
                  <canvas
                    ref={canvasRef}
                    width={canvasWidth}
                    height={canvasHeight}
                    className="w-full border rounded-lg"
                    onMouseDown={handleCanvasMouseDown}
                    onMouseMove={handleCanvasMouseMove}
                    onMouseUp={handleCanvasMouseUp}
                    onMouseLeave={handleCanvasMouseUp}
                    onTouchStart={handleCanvasMouseDown}
                    onTouchMove={handleCanvasMouseMove}
                    onTouchEnd={handleCanvasMouseUp}
                    onTouchCancel={handleCanvasMouseUp}
                  />
                </div>
                {renderLayerControls()}
              </div>
            </CardContent>
          </Card>
        </div>
      </main>

      {/* Fixed footer */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-between items-center shadow-lg">
        <Button variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button onClick={handleComplete}>
          Complete This Section
        </Button>
      </div>
    </div>
  );
};

export default UserMoodboardScreen;