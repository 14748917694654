import { ReactComponent as Dishwasher } from '@/components/icons/dishwasher-svgrepo-com.svg';
import { ReactComponent as Cooktop } from '@/components/icons/cooktop.svg';
import { ReactComponent as Range } from '@/components/icons/range.svg';
import { ReactComponent as BuiltInOven } from '@/components/icons/builtin oven.svg';
import { ReactComponent as Hood } from '@/components/icons/hood.svg';
import { ReactComponent as Refrigerator } from '@/components/icons/refrigerator.svg';
import { ReactComponent as Microwave } from '@/components/icons/microwave.svg';
import { ReactComponent as Washer } from '@/components/icons/washing-machine.svg';
import { ReactComponent as Dryer } from '@/components/icons/tumble-dryer.svg';
import { ReactComponent as Tv } from '@/components/icons/tv.svg';
import { FaPlusCircle } from 'react-icons/fa';

export const applianceData = [
  { id: 'fridge', name: 'Refrigerator', icon: Refrigerator },
  { id: 'range', name: 'Range', icon: Range },
  { id: 'hood', name: 'Hood', icon: Hood },
  { id: 'microwave', name: 'Microwave', icon: Microwave },
  { id: 'dishwasher', name: 'Dishwasher', icon: Dishwasher },
  { id: 'cooktop', name: 'Cooktop', icon: Cooktop },
  { id: 'oven', name: 'Built-in Oven', icon: BuiltInOven },
  { id: 'washer', name: 'Washing machine', icon: Washer },
  { id: 'dryer', name: 'Tumble Dryer', icon: Dryer },
  { id: 'tv', name: 'TV', icon: Tv },
  { id: 'other', name: 'Other', icon: FaPlusCircle },
];

export const standardSizes = {
  fridge: [
    { width: 30, depth: 32, height: 67 },
    { width: 33, depth: 33, height: 70 },
    { width: 36, depth: 34, height: 72 }
  ],
  cooktop: [
    { width: 30, depth: 21, height: 4 },
    { width: 36, depth: 21, height: 4 }
  ],
  oven: [
    { width: 24, depth: 24, height: 23 },
    { width: 27, depth: 24, height: 29 },
    { width: 30, depth: 24, height: 29 }
  ],
  range: [
    { width: 30, depth: 28, height: 36 },
    { width: 36, depth: 28, height: 36 }
  ],
  microwave: [
    { width: 24, depth: 19, height: 13 },
    { width: 30, depth: 19, height: 17 }
  ],
  hood: [
    { width: 30, depth: 20, height: 10 },
    { width: 36, depth: 20, height: 10 }
  ],
  dishwasher: [
    { width: 24, depth: 24, height: 34 }
  ],
  washer: [
    { width: 27, depth: 30, height: 34 },
    { width: 24, depth: 30, height: 34 }
  ],
  dryer: [
    { width: 27, depth: 30, height: 34 },
    { width: 24, depth: 30, height: 34 }
  ],
  tv: [
    { width: 21.3, depth: 3, height: 12.7 },
    { width: 28.5, depth: 3.2, height: 16.7 },
    { width: 38.2, depth: 3.4, height: 22.4 }
  ],
  other: []
};

// Utility functions for dimension handling
export const formatDimensions = ({ width, depth, height }) => {
  return `W:${width}" D:${depth}" H:${height}"`;
};

export const parseDimensions = (dimensionString) => {
  if (!dimensionString) return null;
  
  // Handle numeric dimensions object
  if (typeof dimensionString === 'object' && 'width' in dimensionString) {
    return dimensionString;
  }

  // Parse dimension string
  const dimensions = {};
  const parts = dimensionString.split(' ');
  
  parts.forEach(part => {
    if (part.startsWith('W:')) {
      dimensions.width = parseFloat(part.slice(2).replace('"', ''));
    } else if (part.startsWith('D:')) {
      dimensions.depth = parseFloat(part.slice(2).replace('"', ''));
    } else if (part.startsWith('H:')) {
      dimensions.height = parseFloat(part.slice(2).replace('"', ''));
    }
  });

  return dimensions;
};

// Function to handle search result dimensions
export const parseSearchResultDimensions = (result) => {
  return {
    width: parseFloat(result.W),
    depth: parseFloat(result.D),
    height: parseFloat(result.H),
    productId: result['product #']
  };
};